import { keepPreviousData, useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { StudyPeriodBarnResponse, StudyPeriodGrowthInterval } from '@/types';
import ApiClient from '@/plugins/store/actions/api/ApiClient';

const DEFAULT_OPTIONS = {
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
};

export const queryKeys = {
    getBarnStudyPeriodsRoot: ['study-periods'],
    getBarnStudyPeriods: (barnIds: Ref<number[]>, selectedPeriod: Ref<string>) => [...queryKeys.getBarnStudyPeriodsRoot, { barnIds, selectedPeriod }],
    getGrowthIntervals: () => ['growth-intervals'],
};

export function useBarnStudyPeriods({ barnIds, selectedPeriod }: { barnIds: Ref<number[]>; selectedPeriod: Ref<string> }, options: UseQueryOptions<StudyPeriodBarnResponse> = {}) {
    return useQuery<StudyPeriodBarnResponse>({
        queryKey: queryKeys.getBarnStudyPeriods(barnIds, selectedPeriod),
        queryFn: () =>
            store.dispatch(ActionType.GetStudyPeriods, {
                barnIds: barnIds.value,
            }),
        placeholderData: keepPreviousData,
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useStudyPeriodGrowthIntervals(options: UseQueryOptions<StudyPeriodGrowthInterval[]> = {}) {
    return useQuery<StudyPeriodGrowthInterval[]>({
        queryKey: queryKeys.getGrowthIntervals(),
        queryFn: () => ApiClient.send('get', 'monitor/study-periods/growth-intervals', {}),
        staleTime: 1000 * 60 * 15, // 15 minutes
        gcTime: 1000 * 60 * 15, // 15 minutes
        ...options,
    });
}
