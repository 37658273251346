import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_2 = {
  key: 0,
  class: "text-lg font-medium my-auto flex-1"
}
const _hoisted_3 = {
  key: 1,
  class: "text-right w-80"
}
const _hoisted_4 = { class: "overflow-x-auto" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_HelpWidgetButton = _resolveComponent("HelpWidgetButton")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.tableData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center space-x-3 pt-4 min-h-14", _ctx.narrow ? 'mx-4' : 'mx-6'])
          }, [
            (_ctx.tableData.title)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.tableData.title), 1))
              : _createCommentVNode("", true),
            (_ctx.tableData.searchbar)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _createVNode(_component_SearchInput, {
                    value: _ctx.search,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    label: _ctx.tableData.searchbar.label
                  }, null, 8, ["value", "label"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.helpWidget)
              ? (_openBlock(), _createBlock(_component_HelpWidgetButton, {
                  key: 2,
                  item: _ctx.helpWidget,
                  "icon-size-class": "size-6",
                  class: "flex-shrink-0"
                }, null, 8, ["item"]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_CustomTable, {
              headers: _ctx.headers,
              items: _ctx.filteredItems,
              narrow: _ctx.narrow
            }, _createSlots({ _: 2 }, [
              (_ctx.tableData.sub_headers && _ctx.tableData.sub_headers.length)
                ? {
                    name: "prepend-rows",
                    fn: _withCtx(({ getWidth, getItemProperty, getHeaderClass, clickableItems }) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.sub_headers, (subHeader, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: subHeader.value,
                          class: _normalizeClass(["flex py-4 gap-x-3 text-[0.9rem] odd:bg-dark-gray-200", { 'px-4': _ctx.narrow, 'px-6': !_ctx.narrow, 'border-t border-black': index === 0, 'border-b border-black': index === _ctx.tableData.sub_headers.length - 1 }])
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.headers, (header) => {
                            return (_openBlock(), _createElementBlock("td", {
                              key: `subheader_${index}_${header.value}`,
                              class: _normalizeClass(`${getHeaderClass(header, clickableItems)} ${header.cellClass ? header.cellClass : ''} my-auto break-words min-h-6.5`),
                              style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                            }, _toDisplayString(getItemProperty(header, subHeader, { numberFormat: header.numberFormat })), 7))
                          }), 128))
                        ], 2))
                      }), 128))
                    ]),
                    key: "0"
                  }
                : undefined,
              (_ctx.tableData.footers && _ctx.tableData.footers.length)
                ? {
                    name: "append-rows",
                    fn: _withCtx(({ getWidth, getHeaderClass }) => [
                      _createElementVNode("tr", {
                        class: _normalizeClass(["border-t border-black flex py-4 gap-x-3 text-[0.9rem] odd:bg-dark-gray-200", _ctx.narrow ? 'px-4' : 'px-6'])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.headers, (header) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: header.value,
                            class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words min-h-6.5 font-medium`),
                            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                          }, [
                            _createElementVNode("span", {
                              innerHTML: _ctx.styleFooterCell(header, _ctx.tableData.footers)
                            }, null, 8, _hoisted_5)
                          ], 6))
                        }), 128))
                      ], 2)
                    ]),
                    key: "1"
                  }
                : undefined
            ]), 1032, ["headers", "items", "narrow"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["text-center w-full font-semibold py-4", _ctx.narrow ? 'px-4' : 'px-6'])
        }, _toDisplayString(_ctx.$t('global.tables.emptyTableMessage')), 3))
  ]))
}