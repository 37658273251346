import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "py-14 text-center px-4 text-gray-500 text-lg" }
const _hoisted_3 = {
  key: 2,
  class: "py-14 text-center px-4 text-gray-500 text-lg"
}

import { computed, Ref, ref, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import { useAnalysisDashboard, AnalysisDashboardScope } from '@/plugins/store/actions/queries/analysis/dashboards';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { ActionType } from '@/plugins/store/actions';
import { Dashboard, DashboardItem } from '@/types';

interface Props {
    selectedAnalysisType?: string;
    selectedLocationIds: number[];
    isWeaners?: boolean;
    period: string;
    includeCompleteBatch: boolean;
    fetchIndex: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BarnTab',
  props: {
    selectedAnalysisType: {},
    selectedLocationIds: {},
    isWeaners: { type: Boolean },
    period: {},
    includeCompleteBatch: { type: Boolean },
    fetchIndex: {}
  },
  setup(__props: any) {

const props = __props;

const typeId = computed(() => props.selectedAnalysisType);
const scope = ref(AnalysisDashboardScope.Barn);
const isWeaners = computed(() => props.isWeaners || null);
const locationIds = computed(() => props.selectedLocationIds);
const period = computed(() => props.period);
const includeCompleteBatch = computed(() => props.includeCompleteBatch);
const fetchIndex = computed(() => props.fetchIndex);
const isQueryEnabled = computed(() => Boolean(props.selectedAnalysisType) && props.selectedLocationIds.length > 0);

const { data, isFetching, isError } = useAnalysisDashboard(
    {
        typeId: typeId as Ref<string>,
        isWeaners,
        scope,
        locationIds,
        period,
        includeCompleteBatch,
        fetchIndex,
    },
    {
        enabled: isQueryEnabled,
    }
);

const dashboard = ref<Dashboard | undefined>();

watch(
    data,
    (newData) => {
        dashboard.value = cloneDeep(newData);
    },
    { immediate: true }
);

const fetchParams = computed(() => ({
    typeId: typeId.value,
    scope: scope.value,
    isWeaners: isWeaners.value,
    locationIds: locationIds.value,
}));

function onUpdateItem({ item }: { item: DashboardItem }) {
    const itemIndex = dashboard.value!.items.findIndex((i) => i.item_no === item.item_no);

    if (itemIndex !== -1) {
        dashboard.value!.items[itemIndex] = item;
    }
}

function onUpdateSectionCollapse({ sectionIndex, isCollapsed }: { sectionIndex: number; isCollapsed: boolean }) {
    if (dashboard.value?.sections?.[sectionIndex]) {
        dashboard.value.sections[sectionIndex].is_collapsed = isCollapsed;
    }
}

return (_ctx: any,_cache: any) => {
  return (_unref(isError))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('views.analysis.errors.couldNotLoadData')), 1)
      ]))
    : (dashboard.value || _unref(isFetching))
      ? (_openBlock(), _createBlock(GenericDashboard, {
          key: 1,
          data: dashboard.value,
          loading: _unref(isFetching),
          "fetch-params": fetchParams.value,
          "fetch-action-type": _unref(ActionType).GetAnalysisDashboard,
          "persistent-loading": "",
          "no-data-text": _ctx.$t('views.analysis.texts.noData'),
          "onUpdate:item": onUpdateItem,
          "onUpdate:sectionCollapse": onUpdateSectionCollapse
        }, null, 8, ["data", "loading", "fetch-params", "fetch-action-type", "no-data-text"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('views.analysis.texts.generateToViewInsight')), 1))
}
}

})