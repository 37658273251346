import { RouteRecordRaw } from 'vue-router';
import Overview from '@/views/dashboard/overview/Index.vue';
import Dashboard from '@/views/dashboard/Index.vue';
import Auth from '@/views/auth/Index.vue';
import PigsParentView from '@/views/dashboard/pigs/Index.vue';
import PigsStatus from '@/views/dashboard/pigs/pigsStatus/Index.vue';
import PigsDeaths from '@/views/dashboard/pigs/pigsDeaths/Index.vue';
import PigsBatches from '@/views/dashboard/pigs/pigsBatches/Index.vue';
import Production from '@/views/dashboard/production/Index.vue';
import Debug from '@/views/dashboard/debug/Index.vue';
import EconomyParentView from '@/views/dashboard/economy/Index.vue';
import EconomyMain from '@/views/dashboard/economy/economyMain/Index.vue';
import EconomyPostPayments from '@/views/dashboard/economy/economyPostPayments/Index.vue';
import EconomyListings from '@/views/dashboard/economy/economyListings/Index.vue';
import EconomyDeaths from '@/views/dashboard/economy/economyDeaths/Index.vue';
import EconomyStatus from '@/views/dashboard/economy/economyStatus/Index.vue';
import EconomyBudget from '@/views/dashboard/economy/economyBudget/Index.vue';
import Reports from '@/views/dashboard/reports/Index.vue';
import Slaughters from '@/views/dashboard/slaughters/Index.vue';
import FeedParentView from '@/views/dashboard/feed/Index.vue';
import FeedMixes from '@/views/dashboard/feed/feedMixes/Index.vue';
import FeedConsumptionData from '@/views/dashboard/feed/feedConsumptionData/Index.vue';
import FeedRawMaterials from '@/views/dashboard/feed/feedRawMaterials/Index.vue';
import FeedDeliveries from '@/views/dashboard/feed/feedDeliveries/Index.vue';
import FeedConsumption from '@/views/dashboard/feed/feedConsumption/Index.vue';
import HealthParentView from '@/views/dashboard/health/Index.vue';
import HealthOverview from '@/views/dashboard/health/healthOverview/Index.vue';
import HealthInstructions from '@/views/dashboard/health/instructions/Index.vue';
import SettingsParentView from '@/views/dashboard/settings/Index.vue';
import KPIs from '@/views/dashboard/settings/kpis/Index.vue';
import Cookies from '@/views/dashboard/settings/cookies/Index.vue';
import Locations from '@/views/dashboard/settings/locations/Index.vue';
import Users from '@/views/dashboard/settings/users/Index.vue';
import Directories from '@/views/dashboard/settings/directories/Index.vue';
import Setup from '@/views/dashboard/settings/setup/Index.vue';
import PasswordReset from '@/views/auth/passwordReset/Index.vue';
import ForgotPassword from '@/views/auth/forgotPassword/Index.vue';
import Login from '@/views/auth/logIn/Index.vue';
import NotificationsParentView from '@/views/dashboard/notifications/Index.vue';
import NotificationsIndex from '@/views/dashboard/notifications/notificationsIndex/Index.vue';
import Notes from '@/views/dashboard/notes/Index.vue';
import Assignments from '@/views/dashboard/assignments/Index.vue';
import GenericActivityView from '@/views/dashboard/generics/ActivityView.vue';
import MonitoringView from '@/views/dashboard/monitoring/Index.vue';
import MonitoringMain from '@/views/dashboard/monitoring/monitoring/Index.vue';
import AlarmLog from '@/views/dashboard/monitoring/log/Index.vue';
import { ActivityTableRecordType, Module, IconSource } from '@/types';
import OverviewParentView from '@/views/dashboard/overview/ParentView.vue';
import DataQuality from '@/views/dashboard/overview/data-quality/Index.vue';
import Analysis from '@/views/dashboard/analysis/Index.vue';
import TeamOverview from '@/views/dashboard/monitoring/team-overview/Index.vue';
import TeamComparison from '@/views/dashboard/monitoring/team-comparison/Index.vue';

export const routes: RouteRecordRaw[] = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        children: [
            {
                path: '/auth/login',
                name: 'Login',
                component: Login,
            },
            {
                path: '/auth/forgot',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: '/auth/reset',
                name: 'PasswordReset',
                component: PasswordReset,
            },
        ],
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        redirect: '/overview',
        meta: { appearsInSidebar: false },
        children: [
            {
                path: '/settings',
                name: 'Settings',
                component: SettingsParentView,
                meta: { appearsInSidebar: false },
                children: [
                    {
                        path: '/settings/kpis',
                        name: 'Kpis',
                        component: KPIs,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/settings/cookies',
                        name: 'Cookies',
                        component: Cookies,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/settings/locations',
                        name: 'Locations',
                        component: Locations,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/settings/users',
                        name: 'Users',
                        component: Users,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/settings/companies',
                        name: 'Directories',
                        component: Directories,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/settings/setup',
                        name: 'Setup',
                        component: Setup,
                        meta: { appearsInSidebar: false },
                    },
                ],
            },
            {
                path: '/notifications',
                name: 'Notifications',
                component: NotificationsParentView,
                meta: { appearsInSidebar: false },
                children: [
                    {
                        path: '/notifications',
                        name: 'NotificationsIndex',
                        component: NotificationsIndex,
                        meta: { appearsInSidebar: false },
                    },
                ],
            },
            {
                path: '/monitoring',
                name: 'Monitoring',
                component: MonitoringView,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 1,
                    sidebarText: 'layouts.dashboard.sideBar.routes.monitoring.main',
                    sidebarActiveIcon: IconSource.NavMonitoringActive,
                    sidebarInactiveIcon: IconSource.NavMonitoringInactive,
                    requiresModule: Module.RealTime,
                },
                children: [
                    {
                        path: '',
                        name: 'MonitoringMain',
                        component: MonitoringMain,
                        meta: { appearsInSidebar: false, requiresModule: Module.RealTime },
                    },
                    {
                        path: '/monitoring/team-overview',
                        name: 'TeamOverview',
                        component: TeamOverview,
                        meta: { appearsInSidebar: true, sidebarText: 'views.monitoring.teamOverview.headings.title', requiresModule: Module.RealTime },
                    },
                    {
                        path: '/monitoring/team-comparison',
                        name: 'TeamComparison',
                        component: TeamComparison,
                        meta: { appearsInSidebar: true, sidebarText: 'views.monitoring.teamComparison.headings.title', requiresModule: Module.RealTime },
                    },
                    {
                        path: '/monitoring/log',
                        name: 'AlarmLog',
                        component: AlarmLog,
                        meta: {
                            appearsInSidebar: true,
                            sidebarText: 'layouts.dashboard.sideBar.routes.monitoring.alarmLog',
                            requiresModule: Module.RealTime,
                        },
                    },
                ],
            },
            {
                path: '/notes',
                name: 'Notes',
                component: Notes,
                meta: { appearsInSidebar: false, requiresModule: Module.RealTime },
            },
            {
                path: '/assignments',
                name: 'Assignments',
                component: Assignments,
                meta: { appearsInSidebar: false, requiresModule: Module.Assignments },
            },
            {
                path: '/analysis',
                name: 'Analysis',
                component: Analysis,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 1,
                    sidebarText: 'layouts.dashboard.sideBar.routes.analysis',
                    sidebarActiveIcon: IconSource.NavAnalysisActive,
                    sidebarInactiveIcon: IconSource.NavAnalysisInactive,
                    requiresModule: Module.Analysis,
                },
            },
            {
                path: '/overview',
                name: 'OverviewView',
                component: OverviewParentView,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.overview',
                    sidebarActiveIcon: IconSource.NavStatusActive,
                    sidebarInactiveIcon: IconSource.NavStatusInactive,
                },
                children: [
                    {
                        path: '',
                        name: 'Overview',
                        component: Overview,
                        meta: {
                            sidebarText: 'layouts.dashboard.sideBar.routes.overview',
                        },
                    },
                    {
                        path: '/overview/data-quality',
                        name: 'DataQuality',
                        component: DataQuality,
                        meta: {
                            appearsInSidebar: true,
                            sidebarText: 'layouts.dashboard.sideBar.routes.dataQuality',
                            requiresModule: Module.DataQualityAssessment,
                        },
                    },
                ],
            },
            {
                path: '/activity',
                name: 'Activity',
                component: GenericActivityView,
                props: { recordTypeIds: Object.values(ActivityTableRecordType).filter((currentKey) => !Number.isNaN(Number(currentKey))), heading: 'views.activity.header.heading' },
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.activity',
                    sidebarActiveIcon: IconSource.NavActivityActive,
                    sidebarInactiveIcon: IconSource.NavActivityInactive,
                },
            },
            {
                path: '/production',
                name: 'Production',
                component: Production,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.production',
                    sidebarActiveIcon: IconSource.NavProductionDataActive,
                    sidebarInactiveIcon: IconSource.NavProductionDataInactive,
                },
            },
            {
                path: '/pigs',
                name: 'Pigs',
                component: PigsParentView,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.pigs.main',
                    sidebarActiveIcon: IconSource.NavPigsActive,
                    sidebarInactiveIcon: IconSource.NavPigsInactive,
                },
                children: [
                    {
                        path: '',
                        name: 'PigsStatus',
                        component: PigsStatus,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/pigs/activities',
                        name: 'Pigs',
                        component: GenericActivityView,
                        props: {
                            recordTypeIds: [
                                ActivityTableRecordType.Insertion,
                                ActivityTableRecordType.Slaughter,
                                ActivityTableRecordType.Dead,
                                ActivityTableRecordType.Status,
                                ActivityTableRecordType.Departure,
                                ActivityTableRecordType.Transfer,
                                ActivityTableRecordType.PreSlaughter,
                            ],
                            heading: 'views.pigs.activities.header.heading',
                        },
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.pigs.activities' },
                    },
                    {
                        path: '/pigs/deaths',
                        name: 'PigsDeaths',
                        component: PigsDeaths,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.pigs.deaths' },
                    },
                    {
                        path: '/pigs/batches',
                        name: 'PigsBatches',
                        component: PigsBatches,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.pigs.batches', hideIfNotGetter: 'directoryHasBatchBarns' },
                    },
                ],
            },
            {
                path: '/feed',
                name: 'Feed',
                component: FeedParentView,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.feed.main',
                    sidebarActiveIcon: IconSource.NavFoodActive,
                    sidebarInactiveIcon: IconSource.NavFoodInactive,
                },
                children: [
                    {
                        path: '',
                        name: 'FeedConsumptionData',
                        component: FeedConsumptionData,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/feed/raw-materials',
                        name: 'FeedRawMaterials',
                        component: FeedRawMaterials,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.feed.rawMaterials' },
                    },
                    {
                        path: '/feed/mixes',
                        name: 'FeedMixes',
                        component: FeedMixes,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.feed.mix' },
                    },
                    {
                        path: '/feed/deliveries',
                        name: 'FeedDeliveries',
                        component: FeedDeliveries,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.feed.deliveries' },
                    },
                    {
                        path: '/feed/consumption',
                        name: 'FeedConsumption',
                        component: FeedConsumption,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.feed.consumption' },
                    },
                ],
            },
            {
                path: '/health',
                name: 'Health',
                component: HealthParentView,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.health.main',
                    sidebarActiveIcon: IconSource.MedicineActive,
                    sidebarInactiveIcon: IconSource.MedicineInactive,
                    requiresModule: Module.Medicine,
                },
                children: [
                    {
                        path: '',
                        name: 'HealthOverview',
                        component: HealthOverview,
                        meta: { appearsInSidebar: false, requiresModule: Module.Medicine },
                    },
                    {
                        path: '/health/instructions',
                        name: 'HealthInstructions',
                        component: HealthInstructions,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.health.instructions', requiresModule: Module.Medicine },
                    },
                ],
            },
            {
                path: '/economy',
                name: 'Economy',
                component: EconomyParentView,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.economy.main',
                    sidebarActiveIcon: IconSource.NavEconomyActive,
                    sidebarInactiveIcon: IconSource.NavEconomyInactive,
                },
                children: [
                    {
                        path: '',
                        name: 'EconomyMain',
                        component: EconomyMain,
                        meta: { appearsInSidebar: false },
                    },
                    {
                        path: '/economy/budget',
                        name: 'EconomyBudget',
                        component: EconomyBudget,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.economy.budget' },
                    },
                    {
                        path: '/economy/status',
                        name: 'EconomyStatus',
                        component: EconomyStatus,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.economy.status', requiresModule: Module.StatusEntries },
                    },
                    {
                        path: '/economy/bonus-payments',
                        name: 'EconomyPostPayments',
                        component: EconomyPostPayments,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.economy.postPayments', requiresModule: Module.BonusPayments },
                    },
                    {
                        path: '/economy/listings',
                        name: 'EconomyListings',
                        component: EconomyListings,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.economy.listings', requiresModule: Module.PriceListings },
                    },
                    {
                        path: '/economy/deaths',
                        name: 'EconomyDeaths',
                        component: EconomyDeaths,
                        meta: { appearsInSidebar: true, sidebarText: 'layouts.dashboard.sideBar.routes.economy.deaths' },
                    },
                ],
            },
            {
                path: '/slaughters',
                name: 'Slaughters',
                component: Slaughters,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.slaughters',
                    sidebarActiveIcon: IconSource.NavSlaughtersActive,
                    sidebarInactiveIcon: IconSource.NavSlaughtersInactive,
                },
            },
            {
                path: '/makeable',
                name: 'Debug',
                component: Debug,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.debug',
                    sidebarActiveIcon: IconSource.NavProductionDataActive,
                    sidebarInactiveIcon: IconSource.NavProductionDataInactive,
                    requiresModule: Module.Debug,
                },
            },
            {
                path: '/reports',
                name: 'Reports',
                component: Reports,
                meta: {
                    appearsInSidebar: true,
                    sidebarGroup: 3,
                    sidebarText: 'layouts.dashboard.sideBar.routes.reports',
                    sidebarActiveIcon: IconSource.NavReportsActive,
                    sidebarInactiveIcon: IconSource.NavReportsInactive,
                },
            },
        ],
    },
];
