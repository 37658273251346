import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DialogContent, type DialogContentEmits, type DialogContentProps, DialogOverlay, DialogPortal, useForwardPropsEmits } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'DialogContent',
  props: {
    forceMount: { type: Boolean },
    trapFocus: { type: Boolean },
    disableOutsidePointerEvents: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  emits: ["escapeKeyDown", "pointerDownOutside", "focusOutside", "interactOutside", "openAutoFocus", "closeAutoFocus"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DialogPortal), null, {
    default: _withCtx(() => [
      _createVNode(_unref(DialogOverlay), { class: "fixed inset-0 z-[2000] grid place-items-center overflow-y-auto bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 py-6" }, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogContent), _mergeProps({
            class: 
                    _unref(cn)(
                        'sm:max-w-[480px] relative z-50 grid w-full max-w-lg gap-y-6 gap-x-4 border border-gray-200 bg-white p-8 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg md:w-full',
                        props.class
                    )
                
          }, _unref(forwarded)), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 16, ["class"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})