<template>
    <div v-if="isError">
        <div class="py-14 text-center px-4 text-gray-500 text-lg">{{ $t('views.monitoring.teamComparison.errors.couldNotLoadData') }}</div>
    </div>
    <GenericDashboard
        v-else-if="dashboard || isFetching"
        :data="dashboard"
        :loading="isFetching"
        :fetch-params="fetchParams"
        :fetch-action-type="ActionType.GetAnalysisDashboard"
        persistent-loading
        :no-data-text="$t('views.monitoring.teamComparison.texts.noData')"
        @update:item="onUpdateItem"
        @update:section-collapse="onUpdateSectionCollapse"
    />
    <div v-else class="py-14 text-center px-4 text-gray-500 text-lg">{{ $t('views.monitoring.teamComparison.texts.generateToViewInsight') }}</div>
</template>

<script setup lang="ts">
import { computed, Ref, ref, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { ActionType } from '@/plugins/store/actions';
import { Dashboard, DashboardItem } from '@/types';
import { useStudyPeriodDashboard, StudyPeriodDashboardScope } from '@/plugins/store/actions/queries/study-periods/dashboards';

interface Props {
    selectedStudyPeriodEvaluationCategoryId?: string;
    selectedLocationIds: number[];
    selectedFavoriteStudyPeriodIds: number[];
    fetchIndex: number;
}

const props = defineProps<Props>();

const evaluationCategoryId = computed(() => props.selectedStudyPeriodEvaluationCategoryId);
const scope = ref(StudyPeriodDashboardScope.Table);
const locationIds = computed(() => props.selectedLocationIds);
const favoriteStudyPeriodIds = computed(() => props.selectedFavoriteStudyPeriodIds);
const fetchIndex = computed(() => props.fetchIndex);
const isQueryEnabled = computed(() => Boolean(props.selectedStudyPeriodEvaluationCategoryId) && props.selectedLocationIds.length > 0);

const { data, isFetching, isError } = useStudyPeriodDashboard(
    {
        evaluationCategoryId: evaluationCategoryId as Ref<string>,
        studyPeriodIds: locationIds,
        favoriteStudyPeriodIds,
        fetchIndex,
        scope,
    },
    { enabled: isQueryEnabled }
);

const dashboard = ref<Dashboard | undefined>();

watch(
    data,
    (newData) => {
        dashboard.value = cloneDeep(newData);
    },
    { immediate: true }
);

const fetchParams = computed(() => ({
    evaluationCategoryId: evaluationCategoryId.value,
    studyPeriodIds: locationIds.value,
    scope: scope.value,
}));

function onUpdateItem({ item }: { item: DashboardItem }) {
    const itemIndex = dashboard.value!.items.findIndex((i) => i.item_no === item.item_no);

    if (itemIndex !== -1) {
        dashboard.value!.items[itemIndex] = item;
    }
}

function onUpdateSectionCollapse({ sectionIndex, isCollapsed }: { sectionIndex: number; isCollapsed: boolean }) {
    if (dashboard.value?.sections?.[sectionIndex]) {
        dashboard.value.sections[sectionIndex].is_collapsed = isCollapsed;
    }
}
</script>
