<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';

const props = defineProps<{
    class?: HTMLAttributes['class'];
}>();
</script>

<template>
    <div class="relative max-w-full overflow-x-auto">
        <table :class="cn('w-full min-w-full caption-bottom text-normal', props.class)">
            <slot />
        </table>
    </div>
</template>
