<template>
    <div class="economy-main-tabs default-tabs">
        <Tabs teleport="#economy-type-tabs-target" active-tab-class="--active">
            <nav class="space-x-8 flex border-b border-black border-opacity-10">
                <div v-for="tab of tabs" :key="tab.name" class="flex pr-4">
                    <Tab :name="tab.name" :default="tab.isDefault">
                        <MainViewCard>
                            <div class="space-y-4 pt-4">
                                <div class="flex mx-6 space-x-6">
                                    <h4 class="text-lg font-medium my-auto flex-1">{{ tab.name }}</h4>
                                    <button type="button" class="bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 flex" @click="fetchPostings">
                                        <span class="m-auto pr-2">
                                            <Icon :src="IconSource.RefreshActivities"></Icon>
                                        </span>
                                        <span class="font-medium">{{ $t('views.economy.main.fetchActivitiesButtonLabel') }}</span>
                                    </button>
                                    <TableCalendarModal :events="tab.postings" :categories="economyPostingCategories"></TableCalendarModal>
                                    <FilterModal
                                        v-model:selected-ids="selectedEconomyCategories"
                                        :header="$t('views.economy.main.filterModal.header')"
                                        :sub-header="$t('views.economy.main.filterModal.subheader')"
                                        :types="economyPostingCategories"
                                    >
                                        <template #default="{ scope }">
                                            <button
                                                type="button"
                                                class="flex items-center bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3"
                                                @click="scope.open"
                                            >
                                                <span class="m-auto pr-2">
                                                    <Icon :src="IconSource.Filter"></Icon>
                                                </span>
                                                <span class="font-medium">{{ $t('views.economy.main.filterButtonLabel') }}</span>
                                                <span
                                                    v-if="economyPostingCategories.length && selectedEconomyCategories.length !== economyPostingCategories.length"
                                                    class="bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2"
                                                >
                                                    {{ selectedEconomyCategories.length }}
                                                </span>
                                            </button>
                                        </template>
                                    </FilterModal>
                                </div>
                                <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader" @closing="onActionModalClosing">
                                    <template #default="{ open }">
                                        <CustomTable :items="tab.postings" :headers="headers" clickable-items @item-clicked="(item) => onEconomyPostingClicked(item, open)">
                                            <template #header_icon_url>
                                                <th class="w-16"></th>
                                            </template>

                                            <template #header_actions>
                                                <th class="w-6"></th>
                                            </template>

                                            <template #date="{ item, getHeaderClass, getWidth, header }">
                                                <td class="flex-1" :class="`${getHeaderClass(header, true)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                                                    {{ $d(item.date, 'short') }}
                                                </td>
                                            </template>

                                            <template #icon_url="{ item }">
                                                <td class="w-16 my-auto">
                                                    <Icon :src="item.icon_id" class="w-10 h-10"></Icon>
                                                </td>
                                            </template>

                                            <template #actions>
                                                <td class="w-6 my-auto">
                                                    <span class="flex justify-end">
                                                        <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                                    </span>
                                                </td>
                                            </template>
                                        </CustomTable>
                                    </template>
                                </ActionModal>
                            </div>
                        </MainViewCard>
                    </Tab>
                </div>
            </nav>
        </Tabs>

        <div id="economy-type-tabs-target" class="mt-10" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { EconomyPosting, EconomyPostingCategory, MultiStepFormState, RecordForm, TableHeader, IconSource } from '@/types';
import MainViewCard from '@/components/common/MainViewCard.vue';
import FilterModal from '@/components/common/FilterModal.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import TableCalendarModal from '@/components/common/TableCalendarModal.vue';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';

export default defineComponent({
    components: { Tab, Tabs, MainViewCard, FilterModal, ActionModal, CustomTable, TableCalendarModal },
    setup() {
        store.dispatch(ActionType.GetEconomyPostingCategories);
        useRefetchDataOnDashboardStateChanged(ActionType.GetEconomyPostings, { watchSites: true, watchFarmsInsteadOfSites: false, watchFormSubmissions: true });
    },
    data() {
        return {
            selectedEconomyCategories: [] as number[],
            actionModalHeader: null as null | { icon_url: string | number; text: string },
            actionModalInitialState: undefined as undefined | MultiStepFormState,
            actionModalStepConfig: [{}, { deleteButton: true, editable: true }, { submitActionType: ActionType.PutRecordForm }, {}],
        };
    },
    computed: {
        tabs(): any[] {
            return [
                { isDefault: true, name: this.$t('views.economy.main.tabs.expenses'), postings: this.economyPostings.filter((currentEconomyPosting) => !currentEconomyPosting.is_income) },
                { isDefault: false, name: this.$t('views.economy.main.tabs.income'), postings: this.economyPostings.filter((currentEconomyPosting) => currentEconomyPosting.is_income) },
            ];
        },
        economyPostingCategories() {
            return store.state.economyPostingCategories;
        },
        economyPostings(): (EconomyPosting & { icon_id?: number; icon_url?: string; category: string; description: string })[] {
            return (
                store.state.economyPostingsResponse?.data
                    .filter((currentEconomyPosting) => this.selectedEconomyCategories.includes(currentEconomyPosting.category_id))
                    .map((currentEconomyPosting) => {
                        const category = this.economyPostingCategories.find((currentCategory) => currentCategory.id === currentEconomyPosting.category_id);
                        return {
                            ...currentEconomyPosting,
                            icon_id: category?.icon_id,
                            icon_url: category && store.state.icons ? store.state.icons[category.icon_id] : undefined,
                            category: category?.name || '',
                            description: `${this.$d(new Date(currentEconomyPosting.date), 'short')} (${this.$n(currentEconomyPosting.price, 'price')} DKK)`,
                        };
                    }) || []
            );
        },
        headers(): TableHeader[] {
            return [
                {
                    text: '',
                    value: 'icon_url',
                    align: 'left',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: this.$t('views.economy.main.table.headings.date'),
                    value: 'date',
                    align: 'left',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: this.$t('views.economy.main.table.headings.location'),
                    value: 'location_name',
                    align: 'left',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: this.$t('views.economy.main.table.headings.category'),
                    value: 'category',
                    align: 'left',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: this.$t('views.economy.main.table.headings.remark'),
                    value: 'remark',
                    align: 'left',
                    sortable: true,
                    searchable: false,
                },
                {
                    text: store.getters.priceUnitDisplayText
                        ? this.$t('views.economy.main.table.headings.priceUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                        : this.$t('views.economy.main.table.headings.price'),
                    value: 'price',
                    align: 'left',
                    sortable: true,
                    searchable: false,
                    numberFormat: 'price',
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'right',
                    sortable: false,
                    searchable: false,
                },
            ];
        },
    },
    watch: {
        economyPostingCategories: {
            immediate: true,
            handler(newValue: EconomyPostingCategory[]) {
                this.selectedEconomyCategories = [...newValue.map((currentEconomyRecord) => currentEconomyRecord.id)];
            },
        },
    },
    methods: {
        fetchPostings() {
            store.dispatch(ActionType.GetEconomyPostings, { options: { ignoreCache: true } });
        },
        async onEconomyPostingClicked(economyPosting: EconomyPosting, open: CallableFunction) {
            const recordForm: RecordForm = await store.dispatch(ActionType.GetRecordForm, { form: { record_id: economyPosting.id } });
            this.actionModalInitialState = { location: null, form: recordForm.form, confirmed: false, farm: null, event: null, record_id: economyPosting.id, notification: null };
            this.actionModalStepConfig[1].deleteButton = recordForm.deletable;
            this.actionModalStepConfig[1].editable = recordForm.editable;
            this.actionModalHeader = { icon_url: IconSource.Economy, text: this.$t('views.economy.main.editModal.header') };
            open();
        },
        onActionModalClosing(isFinalStep: boolean) {
            if (isFinalStep) {
                this.fetchPostings();
            }
        },
    },
});
</script>
