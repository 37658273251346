<template>
    <div class="space-y-4">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.economy.budget.editBudgetEntryModal.header') }}</h2>
        <div>
            <table class="w-120 table-headers-medium">
                <thead>
                    <tr class="px-6 flex mt-2 rounded text-dark-gray-800 text-opacity-50">
                        <th class="flex flex-1">
                            <span class="mx-auto">{{ priceHeader }}</span>
                        </th>
                        <th class="flex flex-1">
                            <span class="mx-auto">{{ $t('views.economy.budget.editBudgetEntryModal.table.headers.validFrom') }}</span>
                        </th>
                        <th class="flex w-12"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="rounded flex py-4 px-6 my-4 bg-feed-600 bg-opacity-60 border-2 border-opacity-10 rounded-lg">
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">
                                <SimpleInput v-model:value="priceToAdd.price" class="w-20" type="number" :placeholder="$t('views.economy.budget.editBudgetEntryModal.table.newPricePlaceholder')"></SimpleInput>
                            </span>
                        </td>
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto w-32">
                                <FeedDatePicker
                                    v-model:value="priceToAdd.valid_from"
                                    :placeholder="$t('views.economy.budget.editBudgetEntryModal.table.newValidFromPlaceholder')"
                                    :disabled="false"
                                ></FeedDatePicker>
                            </span>
                        </td>
                        <td class="flex w-12 my-auto">
                            <button
                                type="button"
                                :class="{ 'opacity-25 cursor-default': !validPriceToAdd, 'bg-primary-800 text-white rounded font-medium': validPriceToAdd }"
                                class="mx-auto px-2 py-1"
                                @click="onAddPriceClicked"
                            >
                                {{ $t('views.economy.budget.editBudgetEntryModal.table.addPriceButtonLabel') }}
                            </button>
                        </td>
                    </tr>
                    <tr v-for="(price, index) of prices" :key="index" class="rounded flex py-4 px-6 my-4 odd:bg-dark-gray-100 border-2 border-opacity-10 rounded-lg">
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">{{ $n(price.price, 'price') }}</span>
                        </td>
                        <td class="flex flex-1 my-auto break-words">
                            <span class="mx-auto">{{ $d(price.valid_from, 'short') }}</span>
                        </td>
                        <td class="flex w-12 my-auto">
                            <button v-if="entry.prices.length > 1" type="button" class="mx-auto" @click="onDeletePriceClicked(price)">
                                <Icon class="w-6" :src="IconSource.Delete"></Icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex space-x-4">
            <DeleteBudgetEntryConfirmationModal :entry="entry" @submit="$emit('submit')">
                <template #default="scope">
                    <DeleteButton :disabled="isLoading" @click="scope.open">
                        {{ $t('views.economy.budget.deleteBudgetEntryModal.deleteButtonLabel') }}
                    </DeleteButton>
                </template>
            </DeleteBudgetEntryConfirmationModal>
            <ActionModal :header="editBudgetEntryModal?.header" :initial-step-index="1" :initial-state="editBudgetEntryModal?.initialState" @closing="onEditBudgetEntryModalClosing">
                <template #default="scope">
                    <AddButton :loading="isLoading" @click="onEditEntryClicked(scope.open)">
                        {{ $t('views.economy.budget.editBudgetEntryModal.editFormButtonLabel') }}
                    </AddButton>
                </template>
            </ActionModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import { Price, EconomyBudgetEntry, MultiStepFormState, Form as FormType, EconomyEventType, EconomyEvent } from '@/types';
import FeedDatePicker from '@/components/ui/FeedDatePicker.vue';
import AddButton from '@/components/ui/AddButton.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import SimpleInput from '@/components/ui/SimpleInput.vue';
import DeleteBudgetEntryConfirmationModal from '@/views/dashboard/economy/economyBudget/editBudgetEntryModal/DeleteBudgetEntryConfirmationModal.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';

export default defineComponent({
    components: { CustomButton, FeedDatePicker, AddButton, DeleteButton, SimpleInput, DeleteBudgetEntryConfirmationModal, ActionModal },
    emits: ['submit', 'update:entry'],
    props: {
        entry: {
            type: Object as () => Form<EconomyBudgetEntry> & EconomyBudgetEntry,
            required: true,
        },
    },
    data() {
        return {
            priceToAdd: {
                price: '' as number | string,
                valid_from: '',
            },
            editBudgetEntryModal: null as null | { header: { icon_url: string | null; text: string | null }; initialState: MultiStepFormState },
            isLoading: false,
        };
    },
    computed: {
        actions() {
            return store.state.actions;
        },
        computedValue: {
            get(): Form<EconomyBudgetEntry> & EconomyBudgetEntry {
                return this.$props.entry;
            },
            set(newValue: any) {
                this.$emit('update:entry', newValue);
            },
        },
        validPriceToAdd(): boolean {
            return Boolean(typeof this.priceToAdd.price === 'number' && typeof this.priceToAdd.valid_from === 'string' && this.priceToAdd.valid_from.length);
        },
        prices(): Price[] {
            return [...this.$props.entry.prices].sort((a, b) => Date.parse(a.valid_from) - Date.parse(b.valid_from));
        },
        priceHeader() {
            if (store.getters.priceCurrencyPerWeightUnitDisplayText) {
                return this.$t('views.economy.budget.editBudgetEntryModal.table.headers.priceUnit').replace('%VALUE%', store.getters.priceCurrencyPerWeightUnitDisplayText);
            }

            return this.$t('views.economy.budget.editBudgetEntryModal.table.headers.price');
        },
    },
    created() {
        store.dispatch(ActionType.GetActions);
    },
    methods: {
        async onEditEntryClicked(open: CallableFunction) {
            try {
                if (this.isLoading) return;

                this.isLoading = true;

                const event = store.state.economyEvents.find((currentEvent) => currentEvent.type_id === EconomyEventType.BudgetEdit) as EconomyEvent;
                const action = this.actions.find((currentAction) => currentAction.id === event.action_id);
                const form: FormType = await store.dispatch(ActionType.GetForm, { form: { form_id: action!.form_id, resource_id: this.$props.entry.id } });
                this.editBudgetEntryModal = {
                    header: {
                        icon_url: event && store.state.icons ? store.state.icons[event.icon_id] : null,
                        text: event?.name || null,
                    },
                    initialState: {
                        location: null,
                        form,
                        confirmed: false,
                        event,
                    } as MultiStepFormState,
                };
                open();
            } finally {
                this.isLoading = false;
            }
        },
        async onAddPriceClicked() {
            if (!this.validPriceToAdd) {
                return;
            }

            const form = new Form({ entry: this.entry as EconomyBudgetEntry, price: this.priceToAdd }) as Form<{ price: Price; entry: EconomyBudgetEntry }> & { price: Price; entry: EconomyBudgetEntry };
            await store.dispatch(ActionType.CreateBudgetEntryPrice, { form });

            if (!form.errors.hasErrors()) {
                this.priceToAdd.price = '';
                this.priceToAdd.valid_from = '';
                store.dispatch(ActionType.GetEconomyBudgetEntries, { options: { ignoreCache: true } });
            }
        },
        async onDeletePriceClicked(price: Price) {
            await store.dispatch(ActionType.DeleteBudgetEntryPrice, { form: { entry: this.entry, price } });
            store.dispatch(ActionType.GetEconomyBudgetEntries, { options: { ignoreCache: true } });
        },
        onEditBudgetEntryModalClosing(isFinalStep: boolean) {
            if (isFinalStep) {
                store.dispatch(ActionType.GetEconomyBudgetEntries, { options: { ignoreCache: true } });
            }
        },
    },
});
</script>
