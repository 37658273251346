import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

import { DialogRoot, DialogPortal } from 'radix-vue';
import { X } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import { DialogTrigger, DialogContent, DialogClose } from '.';
import { Button } from '@/components/ui/button';

interface Props {
    modelValue?: boolean;
    disabled?: boolean;
    closable?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  ...{
    inheritAttrs: false,
},
  __name: 'Dialog',
  props: {
    modelValue: { type: Boolean, default: undefined },
    disabled: { type: Boolean },
    closable: { type: Boolean, default: true }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const internalModelValue = ref(false);
const computedModelValue = computed(() => props.modelValue ?? internalModelValue.value);

function handleOpen(value: boolean) {
    if (props.disabled) {
        return;
    }

    if (props.modelValue === undefined) {
        internalModelValue.value = value;
    }

    emit('update:modelValue', value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DialogRoot), {
    open: computedModelValue.value,
    "onUpdate:open": handleOpen
  }, {
    default: _withCtx(() => [
      (_ctx.$slots.trigger)
        ? (_openBlock(), _createBlock(_unref(DialogTrigger), {
            key: 0,
            "as-child": ""
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "trigger")
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(DialogPortal), null, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogContent), _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default"),
              (_ctx.closable)
                ? (_openBlock(), _createBlock(_unref(DialogClose), {
                    key: 0,
                    "as-child": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        disabled: props.disabled,
                        variant: "ghost",
                        size: "icon",
                        class: "h-8 w-8 absolute top-3 right-3"
                      }, {
                        default: _withCtx(() => [
                          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sr-only" }, "Close", -1)),
                          _createVNode(_unref(X), {
                            class: "size-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 16)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["open"]))
}
}

})