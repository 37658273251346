<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.feed.rawMaterials.table.header') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.feed.rawMaterials.table.searchFieldLabel')"></SearchInput>
            </span>
            <NewRawMaterialModal>
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.feed.rawMaterials.table.addButtonLabel') }}
                    </AddButton>
                </template>
            </NewRawMaterialModal>
        </div>
        <CustomTable :items="filteredItems" :headers="headers">
            <template #header_actions>
                <th class="w-6"></th>
            </template>

            <template #name="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <div class="flex space-x-2">
                        <span v-if="item.customized" class="flex min-w-5">
                            <Icon class="h-5 w-5 my-auto" :src="IconSource.FarmFilterDark"></Icon>
                        </span>
                        <span class="break-words inline-block">{{ getItemProperty(header, item) }}</span>
                    </div>
                </td>
            </template>

            <template #prices="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <span>{{ getStyledPriceForRawMaterial(item) }}</span>
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-6 my-auto">
                    <EditRawMaterialModal :raw-material="item">
                        <template #default="{ open }">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="open">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </template>
                    </EditRawMaterialModal>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { FeedUnitType, RawMaterial, TableHeader } from '@/types';
import SearchInput from '@/components/ui/SearchInput.vue';
import NewRawMaterialModal from '@/views/dashboard/feed/feedRawMaterials/newRawMaterialModal/NewRawMaterialModal.vue';
import EditRawMaterialModal from '@/views/dashboard/feed/feedRawMaterials/editRawMaterialModal/EditRawMaterialModal.vue';
import useGetItemProperty from '@/components/common/composables/useGetItemProperty';
import AddButton from '@/components/ui/AddButton.vue';
import useGetPrice from '@/components/common/composables/useGetPrice';
import { i18n } from '@/plugins/internationalization/i18n';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, SearchInput, NewRawMaterialModal, EditRawMaterialModal, AddButton },
    setup() {
        store.dispatch(ActionType.GetRawMaterials);

        const energyUnitHeader = computed(() => {
            const energyDisplayText = store.getters.energyPerWeightUnitDisplayText;

            if (energyDisplayText) {
                return i18n.global.t('views.feed.rawMaterials.table.headings.energyUnit').replace('%VALUE%', energyDisplayText);
            }

            return store.state.user?.energy_unit === FeedUnitType.Mj ? i18n.global.t('views.feed.rawMaterials.table.headings.mj') : i18n.global.t('views.feed.rawMaterials.table.headings.fu');
        });
        const priceUnitHeader = computed(() => {
            const priceCurrencyDisplayText = store.getters.priceCurrencyPerWeightUnitDisplayText;

            if (priceCurrencyDisplayText) {
                return i18n.global.t('views.feed.rawMaterials.table.headings.priceUnit').replace('%VALUE%', priceCurrencyDisplayText);
            }

            return i18n.global.t('views.feed.rawMaterials.table.headings.price');
        });

        const headers = computed<TableHeader[]>(() => [
            {
                text: i18n.global.t('views.feed.rawMaterials.table.headings.name'),
                value: 'name',
                align: 'left',
                sortable: true,
                searchable: true,
                headerClass: 'min-w-40',
            },
            {
                text: i18n.global.t('views.feed.rawMaterials.table.headings.dry_matter'),
                value: 'dry_matter',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: energyUnitHeader.value,
                value: 'fu',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.feed.rawMaterials.table.headings.crude_protein'),
                value: 'crude_protein',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.feed.rawMaterials.table.headings.phosphorus'),
                value: 'phosphorus',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: priceUnitHeader.value,
                value: 'prices',
                align: 'left',
                sortable: true,
                sortComparison: (a: RawMaterial, b: RawMaterial, orderMultiplier: number) => {
                    const aPrice = useGetPrice(a);
                    const bPrice = useGetPrice(b);
                    if (!aPrice) {
                        return -1 * orderMultiplier;
                    }
                    if (!bPrice) {
                        return 1 * orderMultiplier;
                    }
                    return (aPrice.price - bPrice.price) * orderMultiplier;
                },
                searchable: true,
                searchComparison: (item, search) => getStyledPriceForRawMaterial(item).includes(search.toLowerCase().trim()),
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                searchable: false,
            },
        ]);
        const items = computed(() => store.state.rawMaterials);
        const { search, filteredItems } = useFilterableTable(items, headers);

        function getStyledPriceForRawMaterial(rawMaterial: RawMaterial) {
            const priceObject = useGetPrice(rawMaterial);
            return priceObject === null ? '-' : i18n.global.n(priceObject.price, 'price');
        }

        return {
            headers,
            getItemProperty: useGetItemProperty,
            getStyledPriceForRawMaterial,
            search,
            filteredItems,
        };
    },
    created() {
        store.dispatch(ActionType.GetRawMaterials);
    },
});
</script>
