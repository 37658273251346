<template>
    <header class="flex flex-wrap gap-x-3 gap-y-2 w-full">
        <div class="flex-grow">
            <h1 class="text-left font-semibold text-3xl pb-0 whitespace-nowrap">{{ heading }}</h1>
            <div v-if="subheading" class="text-sm text-gray-500">{{ subheading }}</div>
        </div>
        <div class="flex space-x-4" :class="{ 'items-center': slimPeriodPicker }">
            <div v-if="hasPigTypePicker">
                <PigTypePicker />
            </div>
            <div v-if="hasPeriodPicker">
                <PeriodPicker :slim="slimPeriodPicker" />
            </div>
            <slot name="top-right"></slot>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PeriodPicker from '@/components/ui/PeriodPicker.vue';
import PigTypePicker from '@/components/ui/PigTypePicker.vue';

export default defineComponent({
    components: { PeriodPicker, PigTypePicker },
    props: {
        heading: {
            type: String,
            default: '',
        },
        subheading: {
            type: String,
            default: '',
        },
        hasPigTypePicker: {
            type: Boolean,
            default: false,
        },
        hasPeriodPicker: {
            type: Boolean,
            default: false,
        },
        slimPeriodPicker: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
