import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex mx-6 space-x-6" }
const _hoisted_3 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_4 = { class: "m-auto pr-2" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "m-auto pr-2" }
const _hoisted_8 = { class: "font-medium" }
const _hoisted_9 = {
  key: 0,
  class: "bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2"
}
const _hoisted_10 = { class: "w-16 my-auto" }
const _hoisted_11 = { class: "w-6 my-auto" }
const _hoisted_12 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TableCalendarModal = _resolveComponent("TableCalendarModal")!
  const _component_FilterModal = _resolveComponent("FilterModal")!
  const _component_CustomTable = _resolveComponent("CustomTable")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.title)), 1),
      _createElementVNode("button", {
        type: "button",
        class: "bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3 flex",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fetchRecords && _ctx.fetchRecords(...args)))
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_Icon, {
            src: _ctx.IconSource.RefreshActivities
          }, null, 8, ["src"])
        ]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('views.activityTable.fetchActivitiesButtonLabel')), 1)
      ]),
      _createVNode(_component_TableCalendarModal, {
        events: _ctx.records,
        categories: _ctx.recordTypes
      }, null, 8, ["events", "categories"]),
      _createVNode(_component_FilterModal, {
        "selected-ids": _ctx.computedSelectedRecordTypeIds,
        "onUpdate:selectedIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedSelectedRecordTypeIds) = $event)),
        header: _ctx.$t('views.activityTable.filterModal.header'),
        "sub-header": _ctx.$t('views.activityTable.filterModal.subheader'),
        types: _ctx.recordTypes
      }, {
        default: _withCtx(({ scope }) => [
          _createElementVNode("button", {
            type: "button",
            class: "flex items-center bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors rounded-lg py-2 px-3",
            onClick: scope.open
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_Icon, {
                src: _ctx.IconSource.Filter
              }, null, 8, ["src"])
            ]),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('views.activityTable.filterButtonLabel')), 1),
            (_ctx.recordTypes.length && _ctx.computedSelectedRecordTypeIds.length !== _ctx.recordTypes.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.computedSelectedRecordTypeIds.length), 1))
              : _createCommentVNode("", true)
          ], 8, _hoisted_6)
        ]),
        _: 1
      }, 8, ["selected-ids", "header", "sub-header", "types"]),
      _renderSlot(_ctx.$slots, "top-right")
    ]),
    _createVNode(_component_ActionModal, {
      "initial-step-index": 1,
      "initial-state": _ctx.actionModalInitialState,
      "step-config": _ctx.actionModalStepConfig,
      header: _ctx.actionModalHeader,
      onClosing: _ctx.onActionModalClosing
    }, {
      default: _withCtx(({ open }) => [
        _createVNode(_component_CustomTable, {
          items: _ctx.records,
          headers: _ctx.headers,
          "clickable-items": "",
          onItemClicked: (item) => _ctx.onRecordClicked(item, open)
        }, {
          header_icon_url: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("th", { class: "w-16" }, null, -1)
          ])),
          header_actions: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("th", { class: "w-6" }, null, -1)
          ])),
          icon_url: _withCtx(({ item }) => [
            _createElementVNode("td", _hoisted_10, [
              _createVNode(_component_Icon, {
                class: "w-10 h-10",
                src: item.icon_url
              }, null, 8, ["src"])
            ])
          ]),
          date: _withCtx(({ item, getHeaderClass, getWidth, header }) => [
            _createElementVNode("td", {
              class: _normalizeClass(["flex-1", `${getHeaderClass(header, true)} my-auto`]),
              style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
            }, _toDisplayString(_ctx.$d(item.date, 'short')), 7)
          ]),
          actions: _withCtx(() => [
            _createElementVNode("td", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_Icon, {
                  class: "h-3 w-3 cursor-pointer",
                  src: _ctx.IconSource.Right
                }, null, 8, ["src"])
              ])
            ])
          ]),
          _: 2
        }, 1032, ["items", "headers", "onItemClicked"])
      ]),
      _: 1
    }, 8, ["initial-state", "step-config", "header", "onClosing"])
  ]))
}