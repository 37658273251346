<template>
    <Dialog v-model="showModal" :disabled="isLoading">
        <DialogHeader>
            <DialogTitle>
                {{ teamIds.length > 1 ? $t('views.monitoring.teamOverview.modals.deleteTeam.title.multiple') : $t('views.monitoring.teamOverview.modals.deleteTeam.title.single') }}
            </DialogTitle>
            <DialogDescription class="pt-4">
                {{ teamIds.length > 1 ? $t('views.monitoring.teamOverview.modals.deleteTeam.description.multiple') : $t('views.monitoring.teamOverview.modals.deleteTeam.description.single') }}
            </DialogDescription>
        </DialogHeader>
        <DialogFooter>
            <DialogClose as-child>
                <CustomButton :disabled="isLoading" color-preset="white">{{ $t('views.monitoring.teamOverview.modals.deleteTeam.buttons.cancel') }}</CustomButton>
            </DialogClose>
            <CustomButton :loading="isLoading" color-preset="warning" @click="handleDelete">{{ $t('views.monitoring.teamOverview.modals.deleteTeam.buttons.delete') }}</CustomButton>
        </DialogFooter>
    </Dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { i18n } from '@/plugins/internationalization/i18n';

const props = defineProps<{
    teamIds: number[];
}>();
const emit = defineEmits<{
    deleted: [];
}>();

const queryClient = useQueryClient();
const toast = useToast();

const showModal = defineModel<boolean>('modelValue');

const isLoading = ref(false);

async function handleDelete() {
    try {
        if (isLoading.value) {
            return;
        }

        isLoading.value = true;

        const requests = props.teamIds.map((teamId) => ApiClient.send('delete', `/monitor/study-periods/${teamId}`, { skipHandleError: true }));

        await Promise.all(requests);

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        emit('deleted');

        showModal.value = false;

        const successMessage =
            props.teamIds.length > 1
                ? i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.successMessages.teamsWereDeleted')
                : i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.successMessages.teamWasDeleted');

        toast.success(successMessage);
    } catch (err) {
        const errorMessage =
            props.teamIds.length > 1
                ? i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.errors.couldNotDeleteTeams')
                : i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.errors.couldNotDeleteTeam');

        toast.error(errorMessage);
    } finally {
        isLoading.value = false;
    }
}
</script>
