<template>
    <div class="space-y-4">
        <EconomyDeathsGlobalPrices v-if="canManageSolution" :deaths="items"></EconomyDeathsGlobalPrices>
        <MainViewCard>
            <div class="flex mx-6 space-x-6 pt-4">
                <div class="flex-1 font-medium">
                    <h4 class="text-lg my-auto">{{ $t('views.economy.deaths.table.header') }}</h4>
                </div>
                <CreateOrEditPriceChargeModal :step-config="priceChargeStepConfig">
                    <template #default="{ open }">
                        <AddButton text-class="font-medium" @click="open">
                            {{ $t('views.economy.deaths.modals.createOrEditPriceChargeModal.buttonLabel') }}
                        </AddButton>
                    </template>
                </CreateOrEditPriceChargeModal>
            </div>
            <CustomTable :headers="headers" :items="items"></CustomTable>
        </MainViewCard>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MultiStepFormState, TableHeader, Event, EconomyDeadPayment } from '@/types';
import { store } from '@/plugins/store';
import CustomTable from '@/components/ui/CustomTable.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import AddButton from '@/components/ui/AddButton.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import EconomyDeathsGlobalPrices from '@/views/dashboard/economy/economyDeaths/EconomyDeathsGlobalPrices.vue';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import CreateOrEditPriceChargeModal from '@/components/common/economy/priceCharges/CreateOrEditPriceChargeModal.vue';

export default defineComponent({
    components: { CustomTable, MainViewCard, ActionModal, AddButton, EconomyDeathsGlobalPrices, CreateOrEditPriceChargeModal },
    setup() {
        useRefetchDataOnDashboardStateChanged(ActionType.GetEconomyDeadPayments, { watchSites: false, watchFarmsInsteadOfSites: false, watchFormSubmissions: false });
    },
    computed: {
        canManageSolution(): boolean {
            return store.getters.loggedInUserCanManageSolution;
        },
        priceChargeStepConfig(): any {
            return [
                {
                    text: this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.header'),
                    numberFields: [
                        {
                            value: 'less_than30_adjustment',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.lessThanThirtyFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.lessThanThirtyFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'greater_than30_adjustment',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.greaterThanThirtyFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.greaterThanThirtyFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                    ],
                    chargeKey: 'payment',
                    fetchActionType: ActionType.GetEconomyDeadPayments,
                    createActionType: ActionType.CreateDeadPaymentCharge,
                    updateActionType: ActionType.EditDeadPaymentCharge,
                    confirmButtonLabel: this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.periodFieldLabel'),
                    deleteButtonLabel: this.$t('views.economy.deaths.modals.deletePriceChargeModal.buttonLabel'),
                    items: this.items,
                    deleteModal: {
                        header: this.$t('views.economy.deaths.modals.deletePriceChargeModal.header'),
                        message: this.$t('views.economy.deaths.modals.deletePriceChargeModal.message'),
                        cancelButtonLabel: this.$t('views.economy.deaths.modals.deletePriceChargeModal.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('views.economy.deaths.modals.deletePriceChargeModal.confirmButtonLabel'),
                        chargeKey: 'payment',
                        deleteActionType: ActionType.DeleteDeadPaymentCharge,
                        fetchActionType: ActionType.GetEconomyDeadPayments,
                    },
                },
                { text: this.$t('views.economy.deaths.modals.createOrEditPriceChargeModal.successMessage') },
                { text: this.$t('views.economy.deaths.modals.deletePriceChargeModal.successMessage') },
            ];
        },
        items(): EconomyDeadPayment[] {
            return store.state.economyDeadPayments.map((currentItem) => ({
                ...currentItem,
                period: this.$d(new Date(currentItem.valid_from), 'short'),
                tableProperties: {
                    less_than30: `${this.$n(currentItem.less_than30, 'price')}${
                        currentItem.payment?.less_than30_adjustment
                            ? ` (${(currentItem.payment?.less_than30_adjustment || 0) >= 0 ? '+' : ''}${this.$n(currentItem.payment?.less_than30_adjustment || 0, 'price')})`
                            : ''
                    }`,
                    greater_than30: `${this.$n(currentItem.greater_than30, 'price')}${
                        currentItem.payment?.greater_than30_adjustment
                            ? ` (${(currentItem.payment?.greater_than30_adjustment || 0) >= 0 ? '+' : ''}${this.$n(currentItem.payment?.greater_than30_adjustment || 0, 'price')})`
                            : ''
                    }`,
                },
            }));
        },
        headers(): TableHeader[] {
            return [
                {
                    text: this.$t('views.economy.deaths.table.headings.period'),
                    value: 'period',
                    align: 'left',
                    sortable: true,
                    sortComparison: (a: EconomyDeadPayment, b: EconomyDeadPayment, orderMultiplier: number) => (new Date(a.valid_from).getTime() - new Date(b.valid_from).getTime()) * orderMultiplier,
                },
                {
                    text: store.getters.priceUnitDisplayText
                        ? this.$t('views.economy.deaths.table.headings.lessThanThirtyUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                        : this.$t('views.economy.deaths.table.headings.lessThanThirty'),
                    value: 'tableProperties.less_than30',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: store.getters.priceUnitDisplayText
                        ? this.$t('views.economy.deaths.table.headings.greaterThanThirtyUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                        : this.$t('views.economy.deaths.table.headings.greaterThanThirty'),
                    value: 'tableProperties.greater_than30',
                    align: 'left',
                    sortable: false,
                },
            ];
        },
    },
});
</script>
