<script setup lang="ts" generic="TData, TValue">
import { ColumnDef, SortingState, PaginationState, Row, FlexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useVueTable } from '@tanstack/vue-table';
import { ArrowUp, ArrowDown, ChevronsUpDown, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-vue-next';
import { computed, watch, ref } from 'vue';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import Spinner from '@/components/ui/Spinner.vue';

const props = withDefaults(
    defineProps<{
        columns: ColumnDef<TData, TValue>[];
        data: TData[];
        totalRows?: number;
        loading?: boolean;
        serverSide?: boolean;
        pageSize: number;
        pageSizeOptions: number[];
        page: number;
        totalPages?: number;
        selectable?: boolean;
        // eslint-disable-next-line
        rowId?: (row: TData) => string;
        // eslint-disable-next-line
        rowClass?: (row: TData) => string;
        // eslint-disable-next-line
        globalFilterFunction?: (row: Row<TData>, columnId: string, filterValue: string) => boolean;
        sorting?: SortingState;
        rowSelection?: Record<string, boolean>;
        filter?: string;
        enablePagination?: boolean;
    }>(),
    {
        loading: false,
        totalRows: undefined,
        serverSide: false,
        selectable: false,
        totalPages: undefined,
        rowClass: undefined,
        sorting: undefined,
        rowSelection: undefined,
        filter: undefined,
        enablePagination: true,
    }
);
const emit = defineEmits<{
    'update:filter': [value: string];
    'update:sorting': [value: SortingState];
    'update:pageSize': [value: number];
    'update:page': [value: number];
    'update:selectedRows': [value: string[]];
    'update:rowSelection': [value: Record<string, boolean>];
    'row-click': [row: TData];
}>();

const internalSorting = ref<SortingState>([]);
const internalRowSelection = ref<Record<string, boolean>>({});

const pagination = computed<PaginationState>({
    get: () => ({
        pageSize: props.pageSize,
        pageIndex: props.page - 1,
    }),
    set: ({ pageSize, pageIndex }: PaginationState) => {
        emit('update:pageSize', pageSize);
        emit('update:page', pageIndex + 1);
    },
});
const sorting = computed({
    get: () => props.sorting ?? internalSorting.value,
    set: (value) => {
        if (props.sorting !== undefined) {
            emit('update:sorting', value);
        } else {
            internalSorting.value = value;
        }
    },
});
const rowSelection = computed({
    get: () => props.rowSelection ?? internalRowSelection.value,
    set: (value) => {
        emit('update:rowSelection', value);

        if (props.rowSelection === undefined) {
            internalRowSelection.value = value;
        }
    },
});
const filter = computed({
    get: () => props.filter,
    set: (value) => emit('update:filter', value || ''),
});

const columns = computed(() =>
    props.columns.map((column) => ({
        ...column,
        enableGlobalFilter: props.globalFilterFunction ? true : column.enableGlobalFilter ?? false,
        enableSorting: column.enableSorting ?? false,
    }))
);

const table = useVueTable({
    initialState: {
        sorting: sorting.value,
        globalFilter: filter.value,
        pagination: pagination.value,
    },
    get data() {
        return props.data;
    },
    get columns() {
        return columns.value;
    },
    get pageCount() {
        return props.serverSide ? props.totalPages : undefined;
    },
    get rowCount() {
        return props.serverSide ? props.totalRows : undefined;
    },
    state: {
        get sorting() {
            return sorting.value;
        },
        get pagination() {
            return pagination.value;
        },
        get rowSelection() {
            return rowSelection.value;
        },
        get globalFilter() {
            return filter.value;
        },
    },
    onSortingChange: (updater) => {
        sorting.value = typeof updater === 'function' ? updater(sorting.value) : updater;
    },
    onPaginationChange: (updater) => {
        pagination.value = typeof updater === 'function' ? updater(pagination.value) : updater;

        rowSelection.value = {};
    },
    onRowSelectionChange: (updater) => {
        rowSelection.value = typeof updater === 'function' ? updater(rowSelection.value) : updater;
    },
    onGlobalFilterChange: (updater) => {
        filter.value = typeof updater === 'function' ? updater(filter.value) : updater;
    },
    globalFilterFn: props.globalFilterFunction,
    manualSorting: props.serverSide,
    manualPagination: props.serverSide,
    manualFiltering: props.serverSide,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: props.rowId ?? ((row: any) => row.id?.toString() ?? ''),
    autoResetPageIndex: false,
});

function getAlignClass(align: string | undefined): string {
    switch (align) {
        case 'left':
            return 'text-left';
        case 'right':
            return 'text-right';
        case 'center':
            return 'text-center';
        default:
            return 'text-left';
    }
}

const rows = computed(() => (props.serverSide ? table.getFilteredRowModel().rows : table.getRowModel().rows) || []);
const rowsCount = computed(() => props.totalRows ?? table.getFilteredRowModel().rows.length);

const start = computed(() => {
    const pageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    return pageIndex * pageSize + 1;
});
const end = computed(() => {
    const itemEnd = (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize;

    return rowsCount.value > itemEnd ? itemEnd : rowsCount.value;
});

watch(
    () => rows.value,
    (newValue) => {
        if (Object.keys(rowSelection.value).every((k) => newValue.find((r) => r.id === k)) === false) {
            rowSelection.value = {};
        }

        if (props.loading) {
            return;
        }

        const pageCount = props.totalPages ?? table.getPageCount();

        // Set the pagination to the first page if the data changes and the current page is out of bounds
        if (table.getState().pagination.pageIndex > pageCount - 1) {
            table.setPageIndex(0);
            table.initialState.pagination.pageIndex = 0;
        }
    },
    { immediate: true }
);

watch(
    () => filter.value,
    () => {
        table.setPageIndex(0);
    }
);
</script>

<template>
    <div class="relative">
        <Table>
            <TableHeader>
                <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id" class="hover:bg-inherit">
                    <TableHead
                        v-for="header in headerGroup.headers"
                        :key="header.id"
                        :class="[getAlignClass((header.column.columnDef.meta as any)?.align), (header.column.columnDef.meta as any)?.headerClass || '']"
                    >
                        <slot :name="`header[${(header.column.columnDef as any).accessorKey}]`">
                            <DropdownMenu v-if="header.column.getCanSort() && !header.isPlaceholder" :modal="false">
                                <DropdownMenuTrigger as-child>
                                    <Button
                                        v-if="header.column.getCanSort() && !header.isPlaceholder"
                                        :aria-label="
                                            header.column.getIsSorted()
                                                ? header.column.getIsSorted() === 'asc'
                                                    ? $t('global.uiElements.dataTable.buttons.sortedAscendingClickToSort')
                                                    : $t('global.uiElements.dataTable.buttons.sortedDescendingClickToSort')
                                                : $t('global.uiElements.dataTable.buttons.clickToSort')
                                        "
                                        variant="ghost"
                                        class="px-3 -ml-3 gap-x-1.5 data-[state=open]:bg-gray-100"
                                        :class="{ '-ml-3': (header.column.columnDef.meta as any)?.align === 'left', 'ml-3': (header.column.columnDef.meta as any)?.align === 'right' }"
                                    >
                                        <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
                                        <template v-if="header.column.getCanSort()">
                                            <FadeTransition mode="out-in">
                                                <ArrowUp v-if="header.column.getIsSorted() && sorting?.[0]?.desc === false" class="h-4 w-4 -mr-0.5" aria-hidden="true" />
                                                <ArrowDown v-else-if="header.column.getIsSorted()" class="h-4 w-4 -mr-0.5" aria-hidden="true" />
                                                <ChevronsUpDown v-else class="h-4 w-4 transition-transform -mr-0.5" aria-hidden="true" />
                                            </FadeTransition>
                                        </template>
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="start" class="space-y-0.5">
                                    <DropdownMenuItem
                                        :aria-label="$t('global.uiElements.dataTable.buttons.sortAscending')"
                                        :class="{ 'bg-gray-100': header.column.getIsSorted() === 'asc' }"
                                        @select="header.column.toggleSorting(false)"
                                    >
                                        <ArrowUp class="mr-2 h-4 w-4" />
                                        <span>{{ $t('global.uiElements.dataTable.buttons.ascending') }}</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                        :aria-label="$t('global.uiElements.dataTable.buttons.sortDescending')"
                                        :class="{ 'bg-gray-100': header.column.getIsSorted() === 'desc' }"
                                        @select="header.column.toggleSorting(true)"
                                    >
                                        <ArrowDown class="mr-2 h-4 w-4" />
                                        <span>{{ $t('global.uiElements.dataTable.buttons.descending') }}</span>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <FlexRender v-else-if="!header.isPlaceholder" :render="header.column.columnDef.header" :props="header.getContext()" />
                        </slot>
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody class="relative">
                <template v-if="rows.length">
                    <TableRow v-for="row in rows" :key="row.id" :data-state="row.getIsSelected() ? 'selected' : undefined" :class="rowClass?.(row.original as TData)">
                        <TableCell
                            v-for="cell in row.getVisibleCells()"
                            :key="cell.id"
                            :class="[getAlignClass((cell.column.columnDef.meta as any)?.align), (cell.column.columnDef.meta as any)?.cellClass || '']"
                        >
                            <slot :name="`item[${(cell.column.columnDef as any).accessorKey}]`" :item="row.original" :value="cell.getValue()">
                                <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                            </slot>
                        </TableCell>
                    </TableRow>
                </template>
                <tr v-else>
                    <td :colspan="table.getFlatHeaders().length" class="h-24 text-center text-gray-500">
                        {{ loading ? '' : $t('global.uiElements.dataTable.texts.noResults') }}
                    </td>
                </tr>
            </TableBody>
        </Table>
        <div v-if="enablePagination" class="flex w-full flex-col-reverse items-center justify-between gap-4 overflow-auto sm:flex-row sm:gap-8 px-4 py-3 border-t border-gray-200">
            <div class="flex-1 whitespace-nowrap text-normal text-gray-500">
                {{
                    selectable && Object.keys(rowSelection).length
                        ? $t('global.uiElements.dataTable.texts.selectedRows')
                              .replace('%SELECTED%', table.getFilteredSelectedRowModel().rows.length.toString())
                              .replace('%TOTAL%', table.getFilteredRowModel().rows.length.toString())
                        : $t('global.uiElements.dataTable.texts.showingRange')
                              .replace('%START%', rows.length ? start.toString() : '0')
                              .replace('%END%', end.toString())
                              .replace('%TOTAL%', rowsCount.toString())
                }}
            </div>
            <div class="flex items-center space-x-2">
                <div class="whitespace-nowrap text-normal font-medium">{{ $t('global.uiElements.dataTable.texts.pageSize') }}</div>
                <Select :model-value="String(table.getState().pagination.pageSize)" @update:model-value="table.setPageSize(Number($event))">
                    <SelectTrigger class="w-24">
                        <SelectValue :placeholder="String(table.getState().pagination.pageSize)" />
                        <template #icon>
                            <ChevronsUpDown class="size-4 opacity-50 flex-shrink-0" aria-hidden="true" />
                        </template>
                    </SelectTrigger>
                    <SelectContent side="top">
                        <SelectItem v-for="option in pageSizeOptions || [10, 20, 30, 40, 50]" :key="option" :value="String(option)" class="text-normal">{{ option }}</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div class="flex items-center gap-x-4">
                <div className="flex items-center justify-center text-normal font-medium">
                    {{
                        $t('global.uiElements.dataTable.texts.page')
                            .replace('%CURRENT_PAGE%', (table.getState().pagination.pageIndex + 1).toString())
                            .replace('%TOTAL%', (table.getPageCount() || 1).toString())
                    }}
                </div>
                <div class="flex items-center space-x-1">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger as-child>
                                <Button variant="outline" size="icon" :disabled="!table.getCanPreviousPage() || loading" @click="table.setPageIndex(0)">
                                    <span class="sr-only">{{ $t('global.uiElements.dataTable.buttons.firstPage') }}</span>
                                    <ChevronsLeft class="size-5" aria-hidden="true" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{{ $t('global.uiElements.dataTable.buttons.firstPage') }}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger as-child>
                                <Button variant="outline" size="icon" :disabled="!table.getCanPreviousPage() || loading" @click="table.previousPage()">
                                    <span class="sr-only">{{ $t('global.uiElements.dataTable.buttons.previousPage') }}</span>
                                    <ChevronLeft class="size-5" aria-hidden="true" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{{ $t('global.uiElements.dataTable.buttons.previousPage') }}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger as-child>
                                <Button variant="outline" size="icon" :disabled="!table.getCanNextPage() || loading" @click="table.nextPage()">
                                    <span class="sr-only">{{ $t('global.uiElements.dataTable.buttons.nextPage') }}</span>
                                    <ChevronRight class="size-5" aria-hidden="true" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{{ $t('global.uiElements.dataTable.buttons.nextPage') }}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger as-child>
                                <Button variant="outline" size="icon" :disabled="!table.getCanNextPage() || loading" @click="table.setPageIndex(table.getPageCount() - 1)">
                                    <span class="sr-only">{{ $t('global.uiElements.dataTable.buttons.lastPage') }}</span>
                                    <ChevronsRight class="size-5" aria-hidden="true" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{{ $t('global.uiElements.dataTable.buttons.lastPage') }}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>
        </div>
        <Transition
            enter-active-class="duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-if="loading" class="absolute inset-0 flex items-center justify-center bg-white/80">
                <div class="flex justify-center items-center gap-2">
                    <div class="font-medium">{{ $t('global.uiElements.dataTable.texts.loading') }}</div>
                    <Spinner class="w-5 h-5 animate-spin" />
                </div>
            </div>
        </Transition>
    </div>
</template>
