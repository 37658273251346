import { ActionContext, ActionTree } from 'vuex';
import { Form } from '@makeabledk/vue-ui/support/http';
import { subMonths } from 'date-fns';
import {
    Form as FormType,
    KpiPreference,
    Kpi,
    Farm,
    Action,
    User,
    Language,
    Directory,
    Barn,
    RecordResponse,
    RecordType,
    RawMaterial,
    Mix,
    MixType,
    KpiType,
    Event,
    BatchEvent,
    SettingEvent,
    RecordForm,
    RawMaterialRegisterItem,
    Notification,
    Price,
    MixRegisterItem,
    Chart,
    GraphOptions,
    DirectoryFarm,
    DirectoryRole,
    DirectoryPermission,
    DirectoryUser,
    DirectoryUserSettingsForm,
    DirectoryBarn,
    Section,
    EconomyPostingsResponse,
    DirectoryBatch,
    GenericTableOptions,
    EconomyPostingCategory,
    ReportBatch,
    ReportFarm,
    EconomyStatusEntry,
    EconomyListing,
    EconomyPostPayment,
    EconomyBudgetEntry,
    EconomyEvent,
    EconomyDeadPayment,
    ConfigEvent,
    PigType,
    FeedConsumptionData,
    RequestOptions,
    Module,
    DirectorySection,
    NotificationResponse,
    AssignmentResponse,
    AssignmentType,
    AssignmentForm,
    HealthDrugs,
    TreatmentProcedure,
    Treatment,
    AlarmType,
    AlarmCause,
    Note,
    DataQualityAssessment,
    DataQualityCategory,
    DataQualityAssessmentMethod,
    Dashboard,
    AnalysisType,
    ChartPeriod,
    StudyPeriodBarnResponse,
    StudyPeriodEvaluationCategory,
} from '@/types';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import ApiRequest from '@/plugins/store/actions/api/ApiRequest';
import Authentication from '@/plugins/authentication';
import { State } from '@/plugins/store/state';
import { Mutations, MutationType } from '@/plugins/store/mutations';
import { MiscEvent } from '@/types/MiscEvent';
import { AlarmResponse } from '@/types/AlarmResponse';
import { HelpWidgetData } from '@/types/HelpWidget';

/* eslint-disable-next-line no-shadow */
export enum ActionType {
    InitDashboard = 'INIT_DASHBOARD',
    GetIcons = 'GET_ICONS',
    GetEvents = 'GET_EVENTS',
    GetBatchEvents = 'GET_BATCH_EVENTS',
    GetSettingEvents = 'GET_SETTING_EVENTS',
    GetEconomyEvents = 'GET_ECONOMY_EVENTS',
    GetConfigEvents = 'GET_CONFIG_EVENTS',
    GetMiscEvents = 'GET_MISC_EVENTS',
    GetFarms = 'GET_FARMS',
    GetDirectoryFarms = 'GET_DIRECTORY_FARMS',
    GetDirectoryBarns = 'GET_DIRECTORY_BARNS',
    GetDirectorySections = 'GET_DIRECTORY_SECTIONS',
    GetSections = 'GET_SECTIONS',
    GetDirectoryRoles = 'GET_DIRECTORY_ROLES',
    GetDirectoryPermissions = 'GET_DIRECTORY_PERMISSIONS',
    GetDirectoryUsers = 'GET_DIRECTORY_USERS',
    GetBarns = 'GET_BARNS',
    GetBatches = 'GET_BATCHES',
    GetActions = 'GET_ACTIONS',
    GetNotes = 'GET_NOTES',
    GetAssignments = 'GET_ASSIGNMENTS',
    GetAssignmentTypes = 'GET_ASSIGNMENT_TYPES',
    GetKpiTypes = 'GET_KPI_TYPES',
    GetKpiPreferences = 'GET_KPI_PREFERENCES',
    GetKpis = 'GET_KPIS',
    GetRecords = 'GET_RECORDS',
    GetReportRecords = 'GET_REPORT_RECORDS',
    GetEconomyPostings = 'GET_ECONOMY_POSTINGS',
    GetEconomyPostingCategories = 'GET_ECONOMY_POSTING_CATEGORIES',
    GetEconomyStatusEntries = 'GET_ECONOMY_STATUS_ENTRIES',
    GetEconomyListings = 'GET_ECONOMY_LISTINGS',
    GetEconomyPostPayments = 'GET_ECONOMY_POST_PAYMENTS',
    GetEconomyBudgetEntries = 'GET_ECONOMY_BUDGET_ENTRIES',
    GetEconomyDeadPayments = 'GET_ECONOMY_DEAD_PAYMENTS',
    GetHealthDrugs = 'GET_HEALTH_DRUGS',
    GetChart = 'GET_CHART',
    GetDebugChart = 'GET_DEBUG_CHART',
    GetTable = 'GET_TABLE',
    GetRecordTypes = 'GET_RECORD_TYPES',
    GetFeedConsumptionData = 'GET_FEED_CONSUMPTION_DATA',
    GetRawMaterials = 'GET_RAW_MATERIALS',
    GetRawMaterialRegisterItems = 'GET_RAW_MATERIAL_REGISTER_ITEMS',
    GetMixes = 'GET_MIXES',
    GetMixTypes = 'GET_MIX_TYPES',
    GetMixRegisterItems = 'GET_MIX_REGISTER_ITEMS',
    GetDirectories = 'GET_DIRECTORIES',
    GetUser = 'GET_USER',
    GetLanguages = 'GET_LANGUAGES',
    GetForm = 'GET_FORM',
    GetDynamicFormData = 'GET_DYNAMIC_FORM_DATA',
    GetReportBatches = 'GET_REPORT_BATCHES',
    GetReportFarms = 'GET_REPORT_FARMS',
    GetReportDates = 'GET_REPORT_DATES',
    GetReportData = 'GET_REPORT_DATA',
    GetRecordForm = 'GET_RECORD_FORM',
    GetAssignmentForm = 'GET_ASSIGNMENT_FORM',
    GetAlarmForm = 'GET_ALARM_FORM',
    GetMonitorOverviewCharts = 'GET_MONITOR_OVERVIEW_CHARTS',
    GetMonitorChartData = 'GET_MONITOR_CHART_DATA',
    GetNoteForm = 'GET_NOTE_FORM',
    GetEControlPDF = 'GET_E_CONTROL_PDF',
    GetFooter = 'GET_FOOTER',
    PutForm = 'PUT_FORM',
    PutRecordForm = 'PUT_RECORD_FORM',
    PutAssignmentForm = 'PUT_ASSIGNMENT_FORM',
    PutAssignmentTemplateForm = 'PUT_ASSIGNMENT_TEMPLATE_FORM',
    PutAlarmForm = 'PUT_ALARM_FORM',
    PutNoteForm = 'PUT_NOTE_FORM',
    GetAssignmentTemplateForm = 'GET_ASSIGNMENT_TEMPLATE_FORM',
    PutKpiPreferences = 'PUT_KPI_PREFERENCES',
    PutDirectoryUserSettings = 'PUT_DIRECTORY_USER_SETTINGS',
    EditFarm = 'EDIT_FARM',
    EditBarn = 'EDIT_BARN',
    EditUser = 'EDIT_USER',
    EditProfile = 'EDIT_PROFILE',
    EditNotification = 'EDIT_NOTIFICATION',
    EditGlobalStatusEntry = 'EDIT_GLOBAL_STATUS_ENTRY',
    EditStatusEntryCharge = 'EDIT_STATUS_ENTRY_CHARGE',
    EditGlobalPostPayment = 'EDIT_GLOBAL_POST_PAYMENT',
    EditPostPaymentCharge = 'EDIT_POST_PAYMENT_CHARGE',
    EditGlobalDeadPayment = 'EDIT_GLOBAL_DEAD_PAYMENT',
    EditDeadPaymentCharge = 'EDIT_DEAD_PAYMENT_CHARGE',
    CreateFarm = 'CREATE_FARM',
    CreateBarn = 'CREATE_BARN',
    CreateUser = 'CREATE_USER',
    CreateFeedMix = 'CREATE_FEED_MIX',
    CreateRawMaterial = 'CREATE_RAW_MATERIAL',
    CreateRawMaterialPrice = 'CREATE_RAW_MATERIAL_PRICE',
    CreateMixPrice = 'CREATE_MIX_PRICE',
    CreateMixFarm = 'CREATE_MIX_FARM',
    CreateBudgetEntryPrice = 'CREATE_BUDGET_ENTRY_PRICE',
    CreateGlobalStatusEntry = 'CREATE_GLOBAL_STATUS_ENTRY',
    CreateStatusEntryCharge = 'CREATE_STATUS_ENTRY_CHARGE',
    CreateGlobalPostPayment = 'CREATE_GLOBAL_POST_PAYMENT',
    CreatePostPaymentCharge = 'CREATE_POST_PAYMENT_CHARGE',
    CreateGlobalDeadPaymentPrice = 'CREATE_GLOBAL_DEAD_PAYMENT_PRICE',
    CreateDeadPaymentCharge = 'CREATE_DEAD_PAYMENT_CHARGE',
    DeleteFarm = 'DELETE_FARM',
    DeleteBarn = 'DELETE_BARN',
    DeleteDirectoryUser = 'DELETE_DIRECTORY_USER',
    DeleteRawMaterialPrice = 'DELETE_RAW_MATERIAL_PRICE',
    DeleteRawMaterial = 'DELETE_RAW_MATERIAL',
    DeleteMixPrice = 'DELETE_MIX_PRICE',
    DeleteMixFarm = 'DELETE_MIX_FARM',
    DeleteMix = 'DELETE_MIX',
    DeleteBudgetEntryPrice = 'DELETE_BUDGET_ENTRY_PRICE',
    DeleteBudgetEntry = 'DELETE_BUDGET_ENTRY',
    DeleteRecord = 'DELETE_RECORD',
    DeleteAssignment = 'DELETE_ASSIGNMENT',
    DeleteAssignmentTemplate = 'DELETE_ASSIGNMENT_TEMPLATE',
    DeleteNote = 'DELETE_NOTE',
    DeleteGlobalStatusEntry = 'DELETE_GLOBAL_STATUS_ENTRY',
    DeleteStatusEntryCharge = 'DELETE_STATUS_ENTRY_CHARGE',
    DeleteGlobalPostPayment = 'DELETE_GLOBAL_POST_PAYMENT',
    DeletePostPaymentCharge = 'DELETE_POST_PAYMENT_CHARGE',
    DeleteGlobalDeadPayment = 'DELETE_GLOBAL_DEAD_PAYMENT',
    DeleteDeadPaymentCharge = 'DELETE_DEAD_PAYMENT_CHARGE',
    ResetPassword = 'RESET_PASSWORD',
    ActivateDirectory = 'ACTIVATE_DIRECTORY',
    PollMeta = 'POLL_META',
    GetMeta = 'GET_META',
    ReadAllNotifications = 'READ_ALL_NOTIFICATIONS',
    UpdateKeyFigures = 'UPDATE_KEY_FIGURES',
    GetTreatmentProcedures = 'GET_TREATMENT_PROCEDURES',
    GetTreatments = 'GET_TREATMENTS',
    GetTreatmentHistory = 'GET_TREATMENT_HISTORY',
    GetAssignmentTemplates = 'GET_ASSIGNMENT_TEMPLATES',
    GetImages = 'GET_IMAGES',
    GetMonitoringData = 'GET_MONITORING_DATA',
    GetAlarms = 'GET_ALARMS',
    GetAlarmTypes = 'GET_ALARM_TYPES',
    GetAlarmCauses = 'GET_ALARM_CAUSES',
    GetAssignmentImages = 'GET_ASSIGNMENT_IMAGES',
    EditAssignment = 'EDIT_ASSIGNMENT',
    EditSubassignment = 'EDIT_SUBASSIGNMENT',
    GetDataQualityAssessment = 'GET_DATA_QUALITY_ASSESSMENT',
    GetDataQualityCategories = 'GET_DATA_QUALITY_CATEGORIES',
    GetDataQualityAssessmentMethods = 'GET_DATA_QUALITY_ASSESSMENT_METHODS',
    GetDashboardOverview = 'GET_DASHBOARD',
    GetDebugDashboardOverview = 'GET_DEBUG_DASHBOARD_OVERVIEW',
    GetAnalysisTypes = 'GET_ANALYSIS_TYPES',
    GetAnalysisDashboard = 'GET_ANALYSIS_DASHBOARD',
    GetHelpWidget = 'GET_HELP_WIDGET',
    BulkMarkReadStatusNotifications = 'BULK_MARK_READ_STATUS_NOTIFICATIONS',
    GetStudyPeriods = 'GET_STUDY_PERIODS',
    GetStudyPeriodEvaluationCategories = 'GET_STUDY_PERIOD_EVALUATION_CATEGORIES',
    GetStudyPeriodsDashboard = 'GET_STUDY_PERIODS_DASHBOARD',
}

export type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
    [ActionType.InitDashboard](context: ActionAugments, args: {}): void;
    [ActionType.GetIcons](context: ActionAugments, args: { options?: RequestOptions }): Promise<{ [key: string]: string }>;
    [ActionType.GetBarns](context: ActionAugments, args: { options?: RequestOptions }): Promise<Barn[]>;
    [ActionType.GetBatches](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectoryBatch[]>;
    [ActionType.GetEvents](context: ActionAugments, args: { options?: RequestOptions }): Promise<Event[]>;
    [ActionType.GetBatchEvents](context: ActionAugments, args: { options?: RequestOptions }): Promise<BatchEvent[]>;
    [ActionType.GetSettingEvents](context: ActionAugments, args: { options?: RequestOptions }): Promise<SettingEvent[]>;
    [ActionType.GetEconomyEvents](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyEvent[]>;
    [ActionType.GetConfigEvents](context: ActionAugments, args: { options?: RequestOptions }): Promise<ConfigEvent[]>;
    [ActionType.GetMiscEvents](context: ActionAugments, args: { options?: RequestOptions }): Promise<MiscEvent[]>;
    [ActionType.GetFarms](context: ActionAugments, args: { options?: RequestOptions }): Promise<Farm[]>;
    [ActionType.GetDirectoryFarms](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectoryFarm[]>;
    [ActionType.GetDirectoryBarns](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectoryBarn[]>;
    [ActionType.GetDirectorySections](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectorySection[]>;
    [ActionType.GetSections](context: ActionAugments, args: { options?: RequestOptions }): Promise<Section[]>;
    [ActionType.GetDirectoryRoles](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectoryRole[]>;
    [ActionType.GetDirectoryPermissions](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectoryPermission[]>;
    [ActionType.GetDirectoryUsers](context: ActionAugments, args: { options?: RequestOptions }): Promise<DirectoryUser[]>;
    [ActionType.GetActions](context: ActionAugments, args: { options?: RequestOptions }): Promise<Action[]>;
    [ActionType.GetNotes](context: ActionAugments, args: { options?: RequestOptions }): Promise<{ data: Note[]; next_cursor: number | null }>;
    [ActionType.GetAssignments](context: ActionAugments, args: { options?: RequestOptions }): Promise<AssignmentResponse>;
    [ActionType.GetAssignmentTypes](context: ActionAugments, args: { options?: RequestOptions }): Promise<AssignmentType[]>;
    [ActionType.GetKpiTypes](context: ActionAugments, args: { options?: RequestOptions }): Promise<KpiType[]>;
    [ActionType.GetKpiPreferences](context: ActionAugments, args: { options?: RequestOptions }): Promise<{ is_weaner: boolean; directory_default: boolean; kpis: KpiPreference }[]>;
    [ActionType.GetKpis](context: ActionAugments, args: { options?: RequestOptions }): Promise<Kpi[]>;
    [ActionType.GetRecords](context: ActionAugments, args: { options?: RequestOptions }): Promise<RecordResponse>;
    [ActionType.GetReportRecords](context: ActionAugments, args: { options?: RequestOptions; form: { siteIds: number[]; period: string } }): Promise<RecordResponse>;
    [ActionType.GetEconomyPostings](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyPostingsResponse>;
    [ActionType.GetEconomyPostingCategories](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyPostingCategory[]>;
    [ActionType.GetEconomyStatusEntries](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyStatusEntry[]>;
    [ActionType.GetEconomyListings](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyListing[]>;
    [ActionType.GetEconomyPostPayments](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyPostPayment[]>;
    [ActionType.GetEconomyBudgetEntries](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyBudgetEntry[]>;
    [ActionType.GetEconomyDeadPayments](context: ActionAugments, args: { options?: RequestOptions }): Promise<EconomyDeadPayment[]>;
    [ActionType.GetHealthDrugs](context: ActionAugments, args: { options?: RequestOptions }): Promise<HealthDrugs[]>;
    [ActionType.GetChart](context: ActionAugments, args: { options?: RequestOptions; form: { chartType: Chart; addParameters?: string; usePigtypeFilter?: boolean } }): Promise<GraphOptions>;
    [ActionType.GetDebugChart](context: ActionAugments, args: { options?: RequestOptions; form: { chartType: Chart } }): Promise<GraphOptions>;
    [ActionType.GetTable](context: ActionAugments, args: { options?: RequestOptions; form: { url: string } }): Promise<GenericTableOptions>;
    [ActionType.GetRecordTypes](context: ActionAugments, args: { options?: RequestOptions }): Promise<RecordType[]>;
    [ActionType.GetFeedConsumptionData](context: ActionAugments, args: { options?: RequestOptions }): Promise<FeedConsumptionData>;
    [ActionType.GetRawMaterials](context: ActionAugments, args: { options?: RequestOptions }): Promise<RawMaterial[]>;
    [ActionType.GetRawMaterialRegisterItems](context: ActionAugments, args: { options?: RequestOptions }): Promise<RawMaterialRegisterItem[]>;
    [ActionType.GetMixes](context: ActionAugments, args: { options?: RequestOptions }): Promise<Mix[]>;
    [ActionType.GetMixTypes](context: ActionAugments, args: { options?: RequestOptions }): Promise<MixType[]>;
    [ActionType.GetMixRegisterItems](context: ActionAugments, args: { options?: RequestOptions }): Promise<MixRegisterItem[]>;
    [ActionType.GetDirectories](context: ActionAugments, args: { options?: RequestOptions }): Promise<Directory[]>;
    [ActionType.GetUser](context: ActionAugments, args: { options?: RequestOptions }): Promise<User>;
    [ActionType.GetLanguages](context: ActionAugments, args: { options?: RequestOptions }): Promise<Language[]>;
    [ActionType.GetForm](
        context: ActionAugments,
        args: {
            options?: RequestOptions;
            form: { form_id: number; nref?: string | number; barn_id?: number; section_id?: number; farm_id?: number; batch_id?: number; directory_id?: string; resource_id?: string | number };
        }
    ): Promise<FormType>;
    [ActionType.GetDynamicFormData](
        context: ActionAugments,
        args: { options?: RequestOptions; form: { route: string; data: { context: any; values: { id: string; value: any }[] } } }
    ): Promise<{ messages: { id: string; value: string }[]; values: { id: string; value: any }[] }>;
    [ActionType.GetReportBatches](context: ActionAugments, args: { options?: RequestOptions; form: {} }): Promise<ReportBatch[]>;
    [ActionType.GetReportFarms](context: ActionAugments, args: { options?: RequestOptions; form: {} }): Promise<ReportFarm[]>;
    [ActionType.GetReportDates](context: ActionAugments, args: { options?: RequestOptions; form: { site_ids: (number | string)[]; fetchBatchDates: boolean } }): Promise<string[]>;
    [ActionType.GetReportData](context: ActionAugments, args: { options?: RequestOptions; form: { site_ids: (number | string)[]; dates?: string[] } }): Promise<any>;
    [ActionType.GetRecordForm](context: ActionAugments, args: { options?: RequestOptions; form: { record_id: number } }): Promise<RecordForm>;
    [ActionType.GetAssignmentForm](context: ActionAugments, args: { options?: RequestOptions; form: { assignment_id: number } }): Promise<AssignmentForm>;
    [ActionType.GetNoteForm](context: ActionAugments, args: { options?: RequestOptions; form: { note_id: number } }): Promise<RecordForm>;
    [ActionType.GetAssignmentTemplateForm](context: ActionAugments, args: { options?: RequestOptions; form: { assignment_template_id: number } }): Promise<RecordForm>;
    [ActionType.GetEControlPDF](context: ActionAugments, args: { options?: RequestOptions; form: { site_ids: (number | string)[]; dates?: string[] } }): Promise<string>;
    [ActionType.GetAlarmForm](context: ActionAugments, args: { options?: RequestOptions; form: { alarm_id: number } }): Promise<RecordForm>;
    [ActionType.GetFooter](context: ActionAugments, args: { options?: RequestOptions; form: {} }): Promise<String>;
    [ActionType.GetTreatmentProcedures](context: ActionAugments, args: { options?: RequestOptions }): Promise<TreatmentProcedure[]>;
    [ActionType.GetTreatments](context: ActionAugments, args: { options?: RequestOptions }): Promise<Treatment[]>;
    [ActionType.GetMonitorOverviewCharts](context: ActionAugments, args: { options?: RequestOptions; form: { location_id: number; hourly: boolean; period?: ChartPeriod } }): Promise<any>;
    [ActionType.GetMonitorChartData](context: ActionAugments, args: { options?: RequestOptions; form: { url: string; location_id: number; hourly: boolean; period?: ChartPeriod } }): Promise<any>;
    [ActionType.CreateFarm](context: ActionAugments, args: { form: any; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateBarn](context: ActionAugments, args: { form: any; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateUser](context: ActionAugments, args: { form: { email: string }; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateFeedMix](context: ActionAugments, args: { form: any; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateRawMaterial](context: ActionAugments, args: { form: any; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateRawMaterialPrice](context: ActionAugments, args: { form: { price: Price; rawMaterial: RawMaterial }; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateMixPrice](context: ActionAugments, args: { form: { price: Price; mix: Mix }; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateMixFarm](context: ActionAugments, args: { form: { farm: { id: number; name: string }; mix: Mix }; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateBudgetEntryPrice](context: ActionAugments, args: { form: { price: Price; entry: EconomyBudgetEntry }; options?: RequestOptions }): Promise<any>;
    [ActionType.CreateGlobalStatusEntry](
        context: ActionAugments,
        args: { form: { period: string; slpig_dkk_pr_kg: number; slpig_dkk: number; piglet_dkk_pr_kg: number; piglet_dkk: number }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.CreateStatusEntryCharge](
        context: ActionAugments,
        args: { form: { id: number; update: { site_id: number; slpig_dkk_pr_kg: number; slpig_dkk: number; piglet_dkk_pr_kg: number; piglet_dkk: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.CreateGlobalPostPayment](
        context: ActionAugments,
        args: { form: { from: string; to: string; sl_pig_price: number; res_org_price: number; res_free_range_price: number }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.CreatePostPaymentCharge](
        context: ActionAugments,
        args: { form: { id: number; update: { sl_pig_price: number; res_org_price: number; res_free_range_price: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.CreateGlobalDeadPaymentPrice](
        context: ActionAugments,
        args: { form: { from: string; to: string; sl_pig_price: number; res_org_price: number; res_free_range_price: number }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.CreateDeadPaymentCharge](
        context: ActionAugments,
        args: { form: { id: number; update: { less_than30_adjustment: number; greater_than30_adjustment: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.ResetPassword](context: ActionAugments, args: { form: { email: string; password: string; confirmPassword: string } }): Promise<any>;

    [ActionType.EditFarm](context: ActionAugments, args: { form: Farm; options?: RequestOptions }): Promise<any>;
    [ActionType.EditBarn](context: ActionAugments, args: { form: Barn; options?: RequestOptions }): Promise<any>;
    [ActionType.EditUser](context: ActionAugments, args: { form: User; options?: RequestOptions }): Promise<any>;
    [ActionType.EditProfile](context: ActionAugments, args: { form: { value: number; path: string; op: string }[]; options?: RequestOptions }): Promise<any>;
    [ActionType.EditNotification](context: ActionAugments, args: { form: { notification: Notification; update: { value: any; path: string; op: string }[] }; options?: RequestOptions }): Promise<any>;
    [ActionType.EditGlobalStatusEntry](
        context: ActionAugments,
        args: { form: { id: number; update: { piglet_dkk: number; piglet_dkk_pr_kg: number; slpig_dkk: number; slpig_dkk_pr_kg: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.EditStatusEntryCharge](
        context: ActionAugments,
        args: { form: { itemId: number; chargeId: number; update: { piglet_dkk: number; piglet_dkk_pr_kg: number; slpig_dkk: number; slpig_dkk_pr_kg: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.EditGlobalPostPayment](
        context: ActionAugments,
        args: { form: { id: number; update: { sl_pig_price: number; res_org_price: number; res_free_range_price: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.EditPostPaymentCharge](
        context: ActionAugments,
        args: { form: { itemId: number; chargeId: number; update: { sl_pig_price: number; res_org_price: number; res_free_range_price: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.EditGlobalDeadPayment](context: ActionAugments, args: { form: { id: number; update: { less_than30: number; greater_than30: number } }; options?: RequestOptions }): Promise<any>;
    [ActionType.EditDeadPaymentCharge](
        context: ActionAugments,
        args: { form: { itemId: number; chargeId: number; update: { less_than30: number; greater_than30: number } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.PutForm](
        context: ActionAugments,
        args: { form: Form<{ form: FormType; barn_id: number; farm_id: number; batch_id?: number; nref?: number; confirmed: boolean }>; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.PutRecordForm](context: ActionAugments, args: { form: Form<{ form: FormType; record_id?: number; confirmed: boolean }>; options?: RequestOptions }): Promise<any>;
    [ActionType.PutAssignmentForm](context: ActionAugments, args: { form: Form<{ form: FormType; assignment_id?: number; confirmed: boolean }>; options?: RequestOptions }): Promise<any>;
    [ActionType.PutNoteForm](context: ActionAugments, args: { form: Form<{ form: FormType; note_id?: number; confirmed: boolean }>; options?: RequestOptions }): Promise<any>;
    [ActionType.PutAssignmentTemplateForm](context: ActionAugments, args: { form: Form<{ form: FormType; assignment_template_id?: number; confirmed: boolean }>; options?: RequestOptions }): Promise<any>;
    [ActionType.PutAlarmForm](context: ActionAugments, args: { form: Form<{ form: FormType; alarm_id?: number; confirmed: boolean }>; options?: RequestOptions }): Promise<any>;
    [ActionType.PutKpiPreferences](
        context: ActionAugments,
        args: { form: { kpiPreferences: { is_weaner: boolean; directory_default: boolean; kpis: KpiPreference[] } }; options?: RequestOptions }
    ): Promise<any>;
    [ActionType.PutDirectoryUserSettings](context: ActionAugments, args: { directoryUser: DirectoryUser; form: DirectoryUserSettingsForm; options?: RequestOptions }): Promise<any>;
    [ActionType.ActivateDirectory](context: ActionAugments, args: { form: { directoryId: string }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteFarm](context: ActionAugments, args: { form: { farm_id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteBarn](context: ActionAugments, args: { form: { barn_id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteDirectoryUser](context: ActionAugments, args: { form: { user_id: string }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteRawMaterialPrice](context: ActionAugments, args: { form: { rawMaterial: RawMaterial; price: Price }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteRawMaterial](context: ActionAugments, args: { form: { rawMaterial: RawMaterial }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteMixPrice](context: ActionAugments, args: { form: { mix: Mix; price: Price }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteMixFarm](context: ActionAugments, args: { form: { mix: Mix; farm: { id: number; name: string } }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteMix](context: ActionAugments, args: { form: { mix: Mix }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteBudgetEntryPrice](context: ActionAugments, args: { form: { entry: EconomyBudgetEntry; price: Price }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteBudgetEntry](context: ActionAugments, args: { form: { entry: EconomyBudgetEntry }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteRecord](context: ActionAugments, args: { form: { record_id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteAssignment](context: ActionAugments, args: { form: { assignment_id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteAssignmentTemplate](context: ActionAugments, args: { form: { assignment_template_id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteNote](context: ActionAugments, args: { form: { note_id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteGlobalStatusEntry](context: ActionAugments, args: { form: { id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteStatusEntryCharge](context: ActionAugments, args: { form: { itemId: number; chargeId: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteGlobalPostPayment](context: ActionAugments, args: { form: { id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeletePostPaymentCharge](context: ActionAugments, args: { form: { itemId: number; chargeId: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteGlobalDeadPayment](context: ActionAugments, args: { form: { id: number }; options?: RequestOptions }): Promise<any>;
    [ActionType.DeleteDeadPaymentCharge](context: ActionAugments, args: { form: { itemId: number; chargeId: number }; options?: RequestOptions }): Promise<any>;

    [ActionType.PollMeta](context: ActionAugments, args: {}): any;
    [ActionType.GetMeta](context: ActionAugments, args: { options?: RequestOptions }): Promise<any>;
    [ActionType.ReadAllNotifications](context: ActionAugments, args: { options?: RequestOptions }): any;
    [ActionType.UpdateKeyFigures](context: ActionAugments, args: { options?: RequestOptions }): any;
    [ActionType.GetTreatmentHistory](context: ActionAugments, args: { options?: RequestOptions }): Promise<any>;
    [ActionType.GetAssignmentTemplates](context: ActionAugments, args: { options?: RequestOptions }): Promise<any>;
    [ActionType.GetImages](context: ActionAugments, args: { options?: RequestOptions; form: { url: string } }): Promise<any>;
    [ActionType.EditAssignment](context: ActionAugments, args: { options?: RequestOptions; form: { assignmentId: number; payload: any } }): Promise<any>;
    [ActionType.EditSubassignment](context: ActionAugments, args: { options?: RequestOptions; form: { assignmentId: number; subId: number; payload: any } }): Promise<any>;
    [ActionType.GetMonitoringData](context: ActionAugments, args: { options?: RequestOptions }): Promise<any>;
    [ActionType.GetAlarms](context: ActionAugments, args: { options?: RequestOptions }): Promise<AlarmResponse>;
    [ActionType.GetAlarmTypes](context: ActionAugments, args: { options?: RequestOptions }): Promise<AlarmType[]>;
    [ActionType.GetAlarmCauses](context: ActionAugments, args: { options?: RequestOptions }): Promise<AlarmCause[]>;
    [ActionType.GetAssignmentImages](context: ActionAugments, args: { options?: RequestOptions; form: { assignment_id: number } }): Promise<any>;
    [ActionType.EditAssignment](context: ActionAugments, args: { options?: RequestOptions; form: { assignmentId: number; payload: any } }): Promise<any>;
    [ActionType.EditSubassignment](context: ActionAugments, args: { options?: RequestOptions; form: { assignmentId: number; subId: number; payload: any } }): Promise<any>;
    [ActionType.GetDataQualityAssessment](context: ActionAugments, args: { options?: RequestOptions }): Promise<DataQualityAssessment>;
    [ActionType.GetDataQualityCategories](context: ActionAugments, args: { options?: RequestOptions }): Promise<DataQualityCategory[]>;
    [ActionType.GetDataQualityAssessmentMethods](context: ActionAugments, args: { options?: RequestOptions }): Promise<DataQualityAssessmentMethod[]>;
    [ActionType.GetDashboardOverview](context: ActionAugments, args: { options?: RequestOptions & { initialRequest?: boolean; filterItems?: string; filterOptions?: string } }): Promise<Dashboard>;
    [ActionType.GetDebugDashboardOverview](context: ActionAugments, args: { options?: RequestOptions & { initialRequest?: boolean; filterItems?: string; filterOptions?: string } }): Promise<Dashboard>;
    [ActionType.GetDashboardOverview](
        context: ActionAugments,
        args: { options?: RequestOptions & { setDataInStore?: boolean; initialRequest?: boolean; filterItems?: string; filterOptions?: string } }
    ): Promise<Dashboard>;
    [ActionType.GetDebugDashboardOverview](
        context: ActionAugments,
        args: { options?: RequestOptions & { setDataInStore?: boolean; initialRequest?: boolean; filterItems?: string; filterOptions?: string } }
    ): Promise<Dashboard>;
    [ActionType.GetAnalysisTypes](context: ActionAugments, args: { options?: RequestOptions }): Promise<AnalysisType[]>;
    [ActionType.GetAnalysisDashboard](
        context: ActionAugments,
        args: {
            options?: RequestOptions & {
                typeId: string;
                scope: number;
                locationIds: number[];
                isWeaners: boolean;
                filterItems?: string;
                filterOptions?: string;
                includeCompleteBatch?: boolean;
            };
        }
    ): Promise<Dashboard>;
    [ActionType.GetHelpWidget](context: ActionAugments, args: { options?: RequestOptions }): Promise<HelpWidgetData>;
    [ActionType.BulkMarkReadStatusNotifications](context: ActionAugments, args: { options?: RequestOptions; form: { notificationIds: number[]; readStatus: boolean } }): Promise<any>;
    [ActionType.GetStudyPeriods](context: ActionAugments, args: { options?: RequestOptions; barnIds: number[] }): Promise<StudyPeriodBarnResponse>;
    [ActionType.GetStudyPeriodEvaluationCategories](context: ActionAugments, args: { options?: RequestOptions }): Promise<StudyPeriodEvaluationCategory[]>;
    [ActionType.GetStudyPeriodsDashboard](
        context: ActionAugments,
        args: {
            options?: RequestOptions & {
                evaluationCategoryId: string;
                studyPeriodIds: number[];
                scope: string;
                filterItems?: string;
                filterOptions?: string;
            };
        }
    ): Promise<Dashboard>;
};

export const actions: ActionTree<State, State> & Actions = {
    async [ActionType.InitDashboard](store) {
        store.commit(MutationType.InitPeriodPicker, undefined);
        store.commit(MutationType.SetPigType, (localStorage.getItem('pigtype') as PigType | undefined) || PigType.SlaughterPigs);
        await Promise.all([store.dispatch(ActionType.PollMeta), store.dispatch(ActionType.GetIcons), store.dispatch(ActionType.GetFarms)]);
        store.commit(MutationType.SetDashboardLoaded, true);
    },

    async [ActionType.GetIcons](store, args) {
        const icons: { id: number; url: string }[] = await new ApiRequest(args?.options).throttle(ActionType.GetIcons).send('get', 'ui/icons');
        const iconsObject = Object.values(icons).reduce((accum, current) => {
            accum[current.id] = current.url;
            return accum;
        }, {} as { [key: string]: string });
        store.commit(MutationType.SetIcons, iconsObject);
        return iconsObject;
    },

    async [ActionType.GetEvents](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetEvents, stateKey: 'events' }).throttle(ActionType.GetEvents).send('get', 'events');
    },

    async [ActionType.GetBatchEvents](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetBatchEvents, stateKey: 'batchEvents' }).throttle(ActionType.GetBatchEvents).send('get', 'batch-events');
    },

    async [ActionType.GetSettingEvents](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetSettingEvents, stateKey: 'settingEvents' }).throttle(ActionType.GetSettingEvents).send('get', 'setting-events');
    },

    async [ActionType.GetEconomyEvents](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetEconomyEvents, stateKey: 'economyEvents' }).throttle(ActionType.GetEconomyEvents).send('get', 'economy-events');
    },

    async [ActionType.GetConfigEvents](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetConfigEvents, stateKey: 'configEvents' }).throttle(ActionType.GetConfigEvents).send('get', 'config-events');
    },

    async [ActionType.GetMiscEvents](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetMiscEvents, stateKey: 'miscEvents' }).throttle(ActionType.GetMiscEvents).send('get', 'misc-events');
    },

    async [ActionType.GetFarms](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetFarms, stateKey: 'farms' }).throttle(ActionType.GetFarms).send('get', 'farms');
    },

    async [ActionType.GetDirectoryFarms](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetDirectoryFarms, stateKey: 'directoryFarms' }).throttle(ActionType.GetDirectoryFarms).send('get', 'directories/farms');
    },

    async [ActionType.GetDirectoryBarns](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetDirectoryBarns, stateKey: 'directoryBarns' }).throttle(ActionType.GetDirectoryBarns).send('get', 'directories/barns');
    },

    async [ActionType.GetDirectorySections](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetDirectorySections, stateKey: 'directorySections' })
            .throttle(ActionType.GetDirectorySections)
            .send('get', 'directories/sections');
    },

    async [ActionType.GetSections](store, args) {
        // Do not fetch sections if the sections module is disabled
        if (!store.getters.hasModuleEnabled(Module.Sections)) {
            if (store.state.sections === null) {
                store.commit(MutationType.SetSections, []);
            }

            return;
        }

        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetSections, stateKey: 'sections' }).throttle(ActionType.GetSections).send('get', 'sections');
    },

    async [ActionType.GetDirectoryRoles](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetDirectoryRoles, stateKey: 'directoryRoles' }).throttle(ActionType.GetDirectoryRoles).send('get', 'directories/roles');
    },

    async [ActionType.GetDirectoryPermissions](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetDirectoryPermissions, stateKey: 'directoryPermissions' })
            .throttle(ActionType.GetDirectoryPermissions)
            .send('get', 'directories/permissions');
    },

    async [ActionType.GetDirectoryUsers](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetDirectoryUsers, stateKey: 'directoryUsers' }).throttle(ActionType.GetDirectoryUsers).send('get', 'directories/users');
    },

    async [ActionType.GetBarns](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetBarns, stateKey: 'barns' }).throttle(ActionType.GetBarns).send('get', 'barns');
    },

    async [ActionType.GetBatches](store, args) {
        const period = store.getters.getSelectedPeriod;
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetBatches, stateKey: 'batches' }).throttle(ActionType.GetBatches).send('get', `batches?${period}`);
    },

    async [ActionType.GetActions](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetActions, stateKey: 'actions' }).throttle(ActionType.GetActions).send('get', 'actions');
    },

    /* TODO: Properly implement pagination */
    async [ActionType.GetNotes](store, args) {
        const period = store.getters.getSelectedPeriod;
        const siteIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetNoteResponse, stateKey: 'noteResponse' })
            .throttle(ActionType.GetAssignments)
            .send('get', `notes?${period}&location_ids=${siteIds.join(',')}&limit=99999`);
    },

    /* TODO: Properly implement pagination */
    async [ActionType.GetAssignments](store, args) {
        const period = store.getters.getSelectedPeriod;
        const siteIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetAssignmentResponse, stateKey: 'assignmentResponse' })
            .throttle(ActionType.GetAssignments)
            .send('get', `assignments?${period}&location_ids=${siteIds.join(',')}&limit=99999&incl_incomplete=true`);
    },
    /* TODO: Properly implement pagination */
    async [ActionType.GetAssignmentTypes](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetAssignmentTypes, stateKey: 'assignmentTypes' })
            .throttle(ActionType.GetAssignmentTypes)
            .send('get', 'assignments/types');
    },

    async [ActionType.GetKpiTypes](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetKpiTypes, stateKey: 'kpiTypes' }).throttle(ActionType.GetKpiTypes).send('get', 'definitions/kpi-types');
    },

    async [ActionType.GetKpiPreferences](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetKpiPreferences, stateKey: 'kpiPreferences' })
            .throttle(ActionType.GetKpiPreferences)
            .send('get', 'kpi/preferences-contextual');
    },

    async [ActionType.GetKpis](store, args) {
        const farmIds = store.state.selectedFarmIds;
        const isWeaner = store.state.pigType === PigType.Weaners;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetKpis, stateKey: 'kpis' })
            .throttle(ActionType.GetKpis)
            .send('get', `kpis-contextual?farm_ids=${farmIds.join(',')}&is_weaner=${isWeaner}`);
    },

    /* TODO: Implement proper pagination */
    async [ActionType.GetRecords](store, args) {
        const period = store.getters.getSelectedPeriod;
        const siteIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetRecordResponse, stateKey: 'recordResponse' })
            .throttle(ActionType.GetRecords)
            .send('get', `records?${period}&page_number=1&page_size=1000&site_ids=${siteIds.join(',')}`);
    },

    async [ActionType.GetReportRecords](store, args) {
        const period = args.form.period;
        const siteIds: number[] = args.form?.siteIds;
        return new ApiRequest(args?.options).throttle(ActionType.GetReportRecords).send('get', `records?${period}&site_ids=${siteIds.join(',')}`);
    },

    /* TODO: Implement proper pagination */
    async [ActionType.GetEconomyPostings](store, args) {
        const period = store.getters.getSelectedPeriod;
        const siteIds: number[] = store.getters.getSelectedSiteIds;
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyPostingsResponse, stateKey: 'economyPostingsResponse' })
            .throttle(ActionType.GetEconomyPostings)
            .send('get', `economies/postings?${period}&page_number=1&page_size=1000&site_ids=${siteIds.join(',')}`);
    },

    async [ActionType.GetEconomyPostingCategories](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyPostingCategories, stateKey: 'economyPostingCategories' })
            .throttle(ActionType.GetEconomyPostingCategories)
            .send('get', `economies/categories`);
    },

    async [ActionType.GetEconomyStatusEntries](store, args) {
        const period = store.getters.getSelectedPeriod;
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyStatusEntries, stateKey: 'economyStatusEntries' })
            .throttle(ActionType.GetEconomyStatusEntries)
            .send('get', `economies/status-entries?${period}`);
    },

    async [ActionType.GetEconomyListings](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyListings, stateKey: 'economyListings' })
            .throttle(ActionType.GetEconomyListings)
            .send('get', `economies/price-listings`);
    },

    async [ActionType.GetEconomyPostPayments](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyPostPayments, stateKey: 'economyPostPayments' })
            .throttle(ActionType.GetEconomyPostPayments)
            .send('get', `economies/post-payments`);
    },

    async [ActionType.GetEconomyBudgetEntries](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyBudgetEntries, stateKey: 'economyBudgetEntries' })
            .throttle(ActionType.GetEconomyBudgetEntries)
            .send('get', `economies/budget-entries`);
    },

    async [ActionType.GetEconomyDeadPayments](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetEconomyDeadPayments, stateKey: 'economyDeadPayments' })
            .throttle(ActionType.GetEconomyDeadPayments)
            .send('get', `economies/dead-payments`);
    },

    async [ActionType.GetHealthDrugs](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetHealthDrugs, stateKey: 'healthDrugs' }).throttle(ActionType.GetHealthDrugs).send('get', `health/drugs`);
    },

    async [ActionType.GetChart](store, args) {
        const period = store.getters.getSelectedPeriod;
        const siteIds: number[] = args.form.usePigtypeFilter ? store.getters.getSelectedSiteIdsFilteredByPigType : store.getters.getSelectedSiteIds;
        const isWeaners = store.state.pigType === PigType.Weaners;
        return new ApiRequest(args?.options).send('get', `charts/${args.form.chartType}?weaners=${isWeaners}&${period}&site_ids=${siteIds.join(',')}${args.form.addParameters || ''}`);
    },

    async [ActionType.GetDebugChart](store, args) {
        const period = store.getters.getSelectedPeriod;
        const siteIds: number[] = store.getters.getSelectedSiteIds;
        const isWeaners = store.state.pigType === PigType.Weaners;

        return new ApiRequest(args?.options).send('get', `makeable/highchart?weaners=${isWeaners}&${period}&site_ids=${siteIds.join(',')}`);
    },

    async [ActionType.GetTable](store, args) {
        const period: string = store.getters.getSelectedPeriod;
        const siteIds: number[] = store.getters.getSelectedSiteIds;
        return new ApiRequest(args?.options).send('get', `${args.form.url}?site_ids=${siteIds.join(',')}&${period}`);
    },

    async [ActionType.GetRecordTypes](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetRecordTypes, stateKey: 'recordTypes' }).throttle(ActionType.GetRecordTypes).send('get', 'definitions/record-types');
    },

    async [ActionType.GetFeedConsumptionData](store, args) {
        return new ApiRequest(args?.options)
            .throttle(ActionType.GetFeedConsumptionData)
            .send('get', `feed/feed-consumption-data?site_ids=${store.getters.getSelectedSiteIds.join(',')}&${store.getters.getSelectedPeriod}`);
    },

    async [ActionType.GetRawMaterials](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetRawMaterials, stateKey: 'rawMaterials' }).throttle(ActionType.GetRawMaterials).send('get', 'feed/rawmaterials');
    },

    async [ActionType.GetRawMaterialRegisterItems](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetRawMaterialRegisterItems, stateKey: 'rawMaterialRegisterItems' })
            .throttle(ActionType.GetRawMaterialRegisterItems)
            .send('get', 'feed/rawmaterials-register');
    },

    async [ActionType.GetMixes](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetMixes, stateKey: 'mixes' }).throttle(ActionType.GetMixes).send('get', `feed/feedmixes`);
    },

    async [ActionType.GetMixTypes](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetMixTypes, stateKey: 'mixTypes' }).throttle(ActionType.GetMixTypes).send('get', 'feed/feedmixes-types');
    },

    async [ActionType.GetMixRegisterItems](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetMixRegisterItems, stateKey: 'mixRegisterItems' })
            .throttle(ActionType.GetMixRegisterItems)
            .send('get', 'feed/feedmixes-register');
    },

    async [ActionType.GetDirectories](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetDirectories, stateKey: 'directories' }).throttle(ActionType.GetDirectories).send('get', 'directories');
    },

    async [ActionType.GetUser](store, args) {
        const user: User = await new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetUser, stateKey: 'user' }).throttle(ActionType.GetUser).send('get', 'profile');
        ApiClient.setLanguage(user.language_code);
        return user;
    },

    async [ActionType.GetLanguages](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetLanguages, stateKey: 'languages' }).throttle(ActionType.GetLanguages).send('get', 'definitions/languages');
    },

    async [ActionType.GetForm](store, args) {
        const queryParams: string[] = [];
        ['nref', 'resource_id', 'directory_id', 'farm_id', 'barn_id', 'section_id', 'batch_id', 'location_ids'].forEach((key) => {
            if ((args.form as any)[key]) {
                queryParams.push(`${key}=${(args.form as any)[key]}`);
            }
        });
        const query = queryParams.join('&');
        const url = `forms/${args.form.form_id}${query.length ? `?${query}` : ''}`;
        return new ApiRequest(args?.options).throttle(ActionType.GetForm).send('get', url);
    },

    async [ActionType.GetDynamicFormData](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetDynamicFormData).send('put', args.form.route, args.form.data);
    },

    async [ActionType.GetReportBatches](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetReportBatches).send('get', 'reports/batches');
    },

    async [ActionType.GetReportFarms](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetReportBatches).send('get', 'reports/farms');
    },

    async [ActionType.GetReportDates](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetReportDates).send('get', `reports/valid-dates?site_ids=${args.form.site_ids.join(',')}`);
    },

    async [ActionType.GetReportData](store, args) {
        const datesArgument = args.form.dates ? `&dates=${args.form.dates.join(',')}` : '';
        return new ApiRequest(args?.options).throttle(ActionType.GetReportData).send('get', `reports/data?site_ids=${args.form.site_ids.join(',')}${datesArgument}`);
    },

    async [ActionType.GetRecordForm](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetRecordForm).send('get', `forms/records/${args.form.record_id}`);
    },

    async [ActionType.GetAssignmentForm](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetAssignmentForm).send('get', `forms/assignments/${args.form.assignment_id}`);
    },

    async [ActionType.GetAssignmentTemplateForm](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetAssignmentTemplateForm).send('get', `forms/assignment-templates/${args.form.assignment_template_id}`);
    },

    async [ActionType.GetNoteForm](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetNoteForm).send('get', `forms/notes/${args.form.note_id}`);
    },

    async [ActionType.GetAlarmForm](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetAlarmForm).send('get', `forms/alarms/${args.form.alarm_id}`);
    },

    async [ActionType.GetEControlPDF](store, args) {
        const datesArgument = args.form.dates && args.form.dates.length ? `&dates=${args.form.dates.join(',')}` : '';
        return new ApiRequest({ ...args?.options, responseType: 'blob' }).throttle(ActionType.GetEControlPDF).send('get', `reports/econtrolreportpdf?site_ids=${args.form.site_ids.join(',')}${datesArgument}`);
    },

    async [ActionType.GetFooter](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetFooter, stateKey: 'footer' }).throttle(ActionType.GetFooter).send('get', 'ui/footer');
    },

    async [ActionType.GetMonitorOverviewCharts](store, args) {
        const now = new Date();
        const toDate = now.toISOString();
        let fromDate: string = '';

        switch (args.form.period) {
            case ChartPeriod.THREE_MONTHS:
                fromDate = subMonths(now, 3).toISOString();
                break;
            case ChartPeriod.SIX_MONTHS:
                fromDate = subMonths(now, 6).toISOString();
                break;
            case ChartPeriod.NINE_MONTHS:
                fromDate = subMonths(now, 9).toISOString();
                break;
            case ChartPeriod.TWELVE_MONTHS:
                fromDate = subMonths(now, 12).toISOString();
                break;
            default:
                break;
        }

        return new ApiRequest(args?.options).send('get', `charts/monitor/realtime-collection?to=${toDate}&from=${fromDate}&location_id=${args.form.location_id}&hourly=${args.form.hourly}`);
    },

    async [ActionType.GetMonitorChartData](store, args) {
        const now = new Date();
        const toDate = now.toISOString();
        let fromDate: string = '';

        switch (args.form.period) {
            case ChartPeriod.THREE_MONTHS:
                fromDate = subMonths(now, 3).toISOString();
                break;
            case ChartPeriod.SIX_MONTHS:
                fromDate = subMonths(now, 6).toISOString();
                break;
            case ChartPeriod.NINE_MONTHS:
                fromDate = subMonths(now, 9).toISOString();
                break;
            case ChartPeriod.TWELVE_MONTHS:
                fromDate = subMonths(now, 12).toISOString();
                break;
            default:
                break;
        }

        return new ApiRequest(args?.options).send('get', `${args.form.url}?to=${toDate}&from=${fromDate}&location_id=${args.form.location_id}&hourly=${args.form.hourly}`);
    },

    async [ActionType.PutForm](store, args) {
        const url = `forms/${args.form.form.id}${args.form.nref ? `?nref=${args.form.nref}` : ''}`;
        delete args.form.nref;
        return new ApiRequest(args?.options).send('put', url, args.form);
    },

    async [ActionType.PutRecordForm](store, args) {
        const recordId = args.form.record_id;
        args.form.setData({ confirmed: args.form.confirmed, form: args.form.form });
        return new ApiRequest(args?.options).send('put', `forms/records/${recordId}`, args.form);
    },

    async [ActionType.PutAssignmentForm](store, args) {
        const assignmentId = args.form.assignment_id;
        args.form.setData({ confirmed: args.form.confirmed, form: args.form.form });
        return new ApiRequest(args?.options).send('put', `forms/assignments/${assignmentId}`, args.form);
    },

    async [ActionType.PutNoteForm](store, args) {
        const noteId = args.form.note_id;
        args.form.setData({ confirmed: args.form.confirmed, form: args.form.form });
        return new ApiRequest(args?.options).send('put', `forms/notes/${noteId}`, args.form);
    },

    async [ActionType.PutAssignmentTemplateForm](store, args) {
        const assignmentTemplateId = args.form.assignment_template_id;
        args.form.setData({ confirmed: args.form.confirmed, form: args.form.form });
        return new ApiRequest(args?.options).send('put', `forms/assignment-templates/${assignmentTemplateId}`, args.form);
    },

    async [ActionType.PutAlarmForm](store, args) {
        const alarmId = args.form.alarm_id;
        args.form.setData({ confirmed: args.form.confirmed, form: args.form.form });
        return new ApiRequest(args?.options).send('put', `forms/alarms/${alarmId}`, args.form);
    },

    async [ActionType.PutKpiPreferences](store, args) {
        return new ApiRequest(args?.options).send('put', 'kpi/preferences-contextual', args.form);
    },

    async [ActionType.PutDirectoryUserSettings](store, args) {
        return new ApiRequest(args?.options).send('put', `directories/users/${args.directoryUser.id}/settings`, args.form);
    },

    async [ActionType.DeleteFarm](store, args) {
        return new ApiRequest(args?.options).send('delete', `farms/${args.form.farm_id}`);
    },

    async [ActionType.DeleteBarn](store, args) {
        return new ApiRequest(args?.options).send('delete', `barns/${args.form.barn_id}`);
    },

    async [ActionType.EditFarm](store, args) {
        return new ApiRequest(args?.options).send('put', `farms/${args.form.farm_id}`, args.form);
    },

    async [ActionType.EditBarn](store, args) {
        return new ApiRequest(args?.options).send('put', `barns/${args.form.barn_id}`, args.form);
    },

    async [ActionType.EditUser](store, args) {
        return new ApiRequest(args?.options).send('put', `users/${args.form.id}`, args.form);
    },

    async [ActionType.EditProfile](store, args) {
        return new ApiRequest(args?.options).send('patch', `profile`, args.form);
    },

    async [ActionType.EditNotification](store, args) {
        return new ApiRequest(args?.options).send('patch', `notifications/${args.form.notification.id}`, args.form.update);
    },

    async [ActionType.EditGlobalStatusEntry](store, args) {
        return new ApiRequest(args?.options).send('put', `economies/status-entries/${args.form.id}`, args.form.update);
    },

    async [ActionType.EditStatusEntryCharge](store, args) {
        return new ApiRequest(args?.options).send('put', `economies/status-entries/${args.form.itemId}/charges/${args.form.chargeId}`, args.form.update);
    },

    async [ActionType.EditGlobalPostPayment](store, args) {
        return new ApiRequest(args?.options).send('put', `economies/post-payments/${args.form.id}`, args.form.update);
    },

    async [ActionType.EditPostPaymentCharge](store, args) {
        return new ApiRequest(args?.options).send('put', `economies/post-payments/${args.form.itemId}/entries/${args.form.chargeId}`, args.form.update);
    },

    async [ActionType.EditGlobalDeadPayment](store, args) {
        return new ApiRequest(args?.options).send('put', `economies/dead-payments/${args.form.id}`, args.form.update);
    },

    async [ActionType.EditDeadPaymentCharge](store, args) {
        return new ApiRequest(args?.options).send('put', `economies/dead-payments/${args.form.itemId}/payments/${args.form.chargeId}`, args.form.update);
    },

    async [ActionType.CreateFarm](store, args) {
        return new ApiRequest(args?.options).send('post', `farms`, args.form);
    },

    async [ActionType.CreateBarn](store, args) {
        return new ApiRequest(args?.options).send('post', `barns`, args.form);
    },

    async [ActionType.CreateUser](store, args) {
        return new ApiRequest(args?.options).send('post', `directories/users/invite`, args.form);
    },

    async [ActionType.CreateRawMaterial](store, args) {
        return new ApiRequest(args?.options).send('post', `feed/rawmaterials`, args.form);
    },

    async [ActionType.CreateRawMaterialPrice](store, args) {
        return new ApiRequest(args?.options).send('post', `feed/rawmaterials/${args.form.rawMaterial.id}/prices`, args.form.price);
    },

    async [ActionType.CreateMixPrice](store, args) {
        return new ApiRequest(args?.options).send('post', `feed/feedmixes/${args.form.mix.id}/prices`, args.form.price);
    },

    async [ActionType.CreateMixFarm](store, args) {
        return new ApiRequest(args?.options).send('post', `feed/feedmixes/${args.form.mix.id}/farms`, args.form.farm);
    },

    async [ActionType.CreateBudgetEntryPrice](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/budget-entries/${args.form.entry.id}/prices`, args.form.price);
    },

    async [ActionType.CreateGlobalStatusEntry](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/status-entries`, args.form);
    },

    async [ActionType.CreateStatusEntryCharge](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/status-entries/${args.form.id}/charges`, args.form.update);
    },

    async [ActionType.CreateGlobalPostPayment](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/post-payments`, args.form);
    },

    async [ActionType.CreatePostPaymentCharge](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/post-payments/${args.form.id}/entries`, args.form.update);
    },

    async [ActionType.CreateGlobalDeadPaymentPrice](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/dead-payments`, args.form);
    },

    async [ActionType.CreateDeadPaymentCharge](store, args) {
        return new ApiRequest(args?.options).send('post', `economies/dead-payments/${args.form.id}/payments`, args.form.update);
    },

    async [ActionType.CreateFeedMix](store, args) {
        return new ApiRequest(args?.options).send('post', `feed/feedmixes`, args.form);
    },

    async [ActionType.DeleteDirectoryUser](store, args) {
        return new ApiRequest(args?.options).send('delete', `directories/users/${args.form.user_id}`);
    },

    async [ActionType.DeleteRawMaterialPrice](store, args) {
        return new ApiRequest(args?.options).send('delete', `feed/rawmaterials/${args.form.rawMaterial.id}/prices/${args.form.price.id}`);
    },

    async [ActionType.DeleteMixPrice](store, args) {
        return new ApiRequest(args?.options).send('delete', `feed/feedmixes/${args.form.mix.id}/prices/${args.form.price.id}`);
    },

    async [ActionType.DeleteBudgetEntryPrice](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/budget-entries/${args.form.entry.id}/prices/${args.form.price.id}`);
    },

    async [ActionType.DeleteBudgetEntry](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/budget-entries/${args.form.entry.id}`);
    },

    async [ActionType.DeleteRecord](store, args) {
        return new ApiRequest(args?.options).send('delete', `records/${args.form.record_id}`);
    },

    async [ActionType.DeleteAssignment](store, args) {
        return new ApiRequest(args?.options).send('delete', `assignments/${args.form.assignment_id}`);
    },

    async [ActionType.DeleteAssignmentTemplate](store, args) {
        return new ApiRequest(args?.options).send('delete', `assignments/templates/${args.form.assignment_template_id}`);
    },

    async [ActionType.DeleteNote](store, args) {
        return new ApiRequest(args?.options).send('delete', `notes/${args.form.note_id}`);
    },

    async [ActionType.DeleteGlobalStatusEntry](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/status-entries/${args.form.id}`);
    },

    async [ActionType.DeleteStatusEntryCharge](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/status-entries/${args.form.itemId}/charges/${args.form.chargeId}`);
    },

    async [ActionType.DeleteGlobalPostPayment](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/post-payments/${args.form.id}`);
    },

    async [ActionType.DeletePostPaymentCharge](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/post-payments/${args.form.itemId}/entries/${args.form.chargeId}`);
    },

    async [ActionType.DeleteGlobalDeadPayment](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/dead-payments/${args.form.id}`);
    },

    async [ActionType.DeleteDeadPaymentCharge](store, args) {
        return new ApiRequest(args?.options).send('delete', `economies/dead-payments/${args.form.itemId}/payments/${args.form.chargeId}`);
    },

    async [ActionType.DeleteMixFarm](store, args) {
        return new ApiRequest(args?.options).send('delete', `feed/feedmixes/${args.form.mix.id}/farms/${args.form.farm.id}`);
    },

    async [ActionType.DeleteRawMaterial](store, args) {
        return new ApiRequest(args?.options).send('delete', `feed/rawmaterials/${args.form.rawMaterial.id}`);
    },

    async [ActionType.DeleteMix](store, args) {
        return new ApiRequest(args?.options).send('delete', `feed/feedmixes/${args.form.mix.id}`);
    },

    async [ActionType.ResetPassword](store, args) {
        return new ApiRequest().send('post', `reset-password`, args.form);
    },

    async [ActionType.ActivateDirectory](store, args) {
        const activateDirectoryResponse = await new ApiRequest(args?.options).send('put', `directories/${args.form.directoryId}`, { active: true });
        await Authentication.refreshToken(false); // JWT-tokens are tied to their directory, so we need to expire the current JWT token before fetching more data
        return activateDirectoryResponse;
    },

    async [ActionType.PollMeta](store) {
        const meta = await new ApiRequest({ skipLoadingIndicator: true, ignoreCache: true }).connect(store, { mutationType: MutationType.SetMeta, stateKey: 'meta' }).send('get', 'meta');

        if (store.state.activeDirectory && meta.selected_directory_id !== store.state.activeDirectory) {
            await Authentication.refreshToken(false);

            window.location.reload();
        }

        setTimeout(() => {
            store.dispatch(ActionType.PollMeta);
        }, 60000);
    },

    [ActionType.GetMeta](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetMeta, stateKey: 'meta' }).send('get', 'meta');
    },

    [ActionType.ReadAllNotifications](store, args) {
        return new ApiRequest(args?.options).send('put', `notifications/readall`, []);
    },

    [ActionType.UpdateKeyFigures](store, args) {
        return new ApiRequest(args?.options).send('post', `tasks/update-key-figures`);
    },

    [ActionType.GetTreatmentProcedures](store, args) {
        const period = store.getters.getSelectedPeriod;
        const isWeaners = store.state.pigType === PigType.Weaners;
        const locationIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetTreatmentProcedures, stateKey: 'treatmentProcedures' })
            .throttle(ActionType.GetTreatmentProcedures)
            .send('get', `health/treatment-procedure?${period}&weaners=${isWeaners}&location_ids=${locationIds.join(',')}`);
    },

    [ActionType.GetTreatments](store, args) {
        const period = store.getters.getSelectedPeriod;
        const isWeaners = store.state.pigType === PigType.Weaners;
        const locationIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetTreatments, stateKey: 'treatments' })
            .throttle(ActionType.GetTreatments)
            .send('get', `health/treatment?${period}&weaners=${isWeaners}&location_ids=${locationIds.join(',')}`);
    },

    [ActionType.GetTreatmentHistory](store, args) {
        const period = store.getters.getSelectedPeriod;
        const isWeaners = store.state.pigType === PigType.Weaners;
        const locationIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetTreatmentHistory, stateKey: 'treatmentHistory' })
            .throttle(ActionType.GetTreatmentHistory)
            .send('get', `health/treatment-history?${period}&weaners=${isWeaners}&location_ids=${locationIds.join(',')}`);
    },

    [ActionType.GetAssignmentTemplates](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetAssignmentTemplates, stateKey: 'assignmentTemplates' })
            .throttle(ActionType.GetAssignmentTemplates)
            .send('get', 'assignments/templates');
    },

    async [ActionType.GetImages](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetImages).send('get', args.form.url);
    },

    async [ActionType.GetMonitoringData](store, args) {
        try {
            store.commit(MutationType.SetMonitoringDataLoading, true);

            const barnIds = store.state.selectedBarnIds;
            const isWeaners = store.state.pigType === PigType.Weaners;

            const response = await new ApiRequest(args?.options)
                .connect(store, { mutationType: MutationType.SetMonitoringData, stateKey: 'monitoringData' })
                .throttle(ActionType.GetMonitoringData)
                .send('get', `monitor/dashboard-data?barn_ids=${barnIds.join(',')}&weaners=${isWeaners}`);

            return response;
        } catch (error) {
            // eslint-disable-next-line
            throw error;
        } finally {
            store.commit(MutationType.SetMonitoringDataLoading, false);
        }
    },

    [ActionType.GetAlarms](store, args) {
        const period = store.getters.getSelectedPeriod;
        const locationIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetAlarmResponse, stateKey: 'alarmResponse' })
            .throttle(ActionType.GetAlarms)
            .send('get', `alarms?${period}&location_ids=${locationIds.join(',')}&limit=99999`);
    },

    [ActionType.GetAlarmTypes](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetAlarmTypes, stateKey: 'alarmTypes' }).throttle(ActionType.GetAlarmTypes).send('get', 'alarms/types');
    },

    [ActionType.GetAlarmCauses](store, args) {
        return new ApiRequest(args?.options).connect(store, { mutationType: MutationType.SetAlarmCauses, stateKey: 'alarmCauses' }).throttle(ActionType.GetAlarmCauses).send('get', 'alarms/causes');
    },

    async [ActionType.GetAssignmentImages](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetAssignmentImages).send('get', `/assignments/${args.form.assignment_id}/images`);
    },

    async [ActionType.EditAssignment](store, args) {
        return new ApiRequest(args?.options).send('put', `assignments/${args.form.assignmentId}`, args.form.payload);
    },

    async [ActionType.EditSubassignment](store, args) {
        return new ApiRequest(args?.options).send('put', `assignments/${args.form.assignmentId}/subassignments/${args.form.subId}`, args.form.payload);
    },

    async [ActionType.GetDataQualityAssessment](store, args) {
        const now = new Date();
        const parsedDate = now.toISOString().slice(0, 10);
        const locationIds: number[] = store.getters.getSelectedSiteIds;

        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetDataQualityAssessment, stateKey: 'dataQualityAssessment' })
            .throttle(ActionType.GetDataQualityAssessment)
            .send('get', `dqa/daily-assessment?date=${parsedDate}&location_ids=${locationIds.join(',')}`);
    },

    async [ActionType.GetDataQualityCategories](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetDataQualityCategories, stateKey: 'dataQualityCategories' })
            .throttle(ActionType.GetDataQualityCategories)
            .send('get', `dqa/definitions/quality-categories`);
    },

    async [ActionType.GetDataQualityAssessmentMethods](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetDataQualityAssessmentMethods, stateKey: 'dataQualityAssessmentMethods' })
            .throttle(ActionType.GetDataQualityAssessmentMethods)
            .send('get', `dqa/definitions/assessment-methods`);
    },

    async [ActionType.GetDashboardOverview](store, args) {
        const period = store.getters.getSelectedPeriod;
        const isWeaners = store.state.pigType === PigType.Weaners;
        const locationIds: number[] = store.getters.getSelectedSiteIdsFilteredByPigType;
        const initialRequest = args.options?.initialRequest ?? true;
        const filterItems = args.options?.filterItems;
        const filterOptions = args.options?.filterOptions;

        let query = `?${period}&isweaners=${isWeaners}&location_ids=${locationIds.join(',')}&initialrequest=${initialRequest}`;

        if (filterItems) {
            query += `&filteritems=${filterItems}`;
        }

        if (filterOptions) {
            query += `&filteroptions=${filterOptions}`;
        }

        if (args.options?.initialRequest === false) {
            return new ApiRequest(args?.options).throttle(ActionType.GetDashboardOverview).send('get', `dashboards/overview${query}`);
        }

        try {
            store.commit(MutationType.SetDashboardOverviewLoading, true);

            return await new ApiRequest(args?.options)
                .connect(store, { mutationType: MutationType.SetDashboardOverview, stateKey: 'dashboardOverview' })
                .throttle(ActionType.GetDashboardOverview)
                .send('get', `dashboards/overview${query}`);
            // eslint-disable-next-line
        } catch (err) {
            // eslint-disable-next-line
            throw err;
        } finally {
            store.commit(MutationType.SetDashboardOverviewLoading, false);
        }
    },

    async [ActionType.GetDebugDashboardOverview](store, args) {
        const period = store.getters.getSelectedPeriod;
        const isWeaners = store.state.pigType === PigType.Weaners;
        const locationIds: number[] = store.getters.getSelectedSiteIds;
        const initialRequest = args.options?.initialRequest ?? true;
        const filterItems = args.options?.filterItems;
        const filterOptions = args.options?.filterOptions;

        let query = `?${period}&weaners=${isWeaners}&location_ids=${locationIds.join(',')}&initialrequest=${initialRequest}`;

        if (filterItems) {
            query += `&filteritems=${filterItems}`;
        }

        if (filterOptions) {
            query += `&filteroptions=${filterOptions}`;
        }

        if (args.options?.initialRequest === false) {
            return new ApiRequest(args?.options).throttle(ActionType.GetDebugDashboardOverview).send('get', `makeable/dashboard${query}`);
        }

        try {
            store.commit(MutationType.SetDebugDashboardOverviewLoading, true);

            return await new ApiRequest(args?.options)
                .connect(store, { mutationType: MutationType.SetDebugDashboardOverview, stateKey: 'debugDashboardOverview' })
                .throttle(ActionType.GetDebugDashboardOverview)
                .send('get', `makeable/dashboard${query}`);
            // eslint-disable-next-line
        } catch (err) {
            // eslint-disable-next-line
            throw err;
        } finally {
            store.commit(MutationType.SetDebugDashboardOverviewLoading, false);
        }
    },

    [ActionType.GetAnalysisTypes](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetAnalysisTypes, stateKey: 'analysisTypes' })
            .throttle(ActionType.GetAnalysisTypes)
            .send('get', 'definitions/analysis-types');
    },

    [ActionType.GetAnalysisDashboard](store, args) {
        const period = store.getters.getSelectedPeriod;
        const typeId = args.options?.typeId;
        const scope = args.options?.scope;
        const isWeaners = args.options?.isWeaners;
        const locationIds = args.options?.locationIds || [];
        const filterItems = args.options?.filterItems;
        const filterOptions = args.options?.filterOptions;
        const includeCompleteBatch = args.options?.includeCompleteBatch ?? false;

        let query = `?${period}&type_id=${typeId}&scope=${scope}&initialrequest=false&location_ids=${locationIds.join(',')}&include_complete_batch=${includeCompleteBatch}`;

        if (isWeaners !== undefined && isWeaners !== null) {
            query += `&isweaners=${isWeaners}`;
        }

        if (filterItems) {
            query += `&filteritems=${filterItems}`;
        }

        if (filterOptions) {
            query += `&filteroptions=${filterOptions}`;
        }

        return new ApiRequest(args?.options).throttle(ActionType.GetAnalysisDashboard).send('get', `dashboards/analysis${query}`);
    },

    [ActionType.GetHelpWidget](store, args) {
        return new ApiRequest(args?.options).throttle(ActionType.GetHelpWidget).send('get', 'ui/help-widget');
    },

    [ActionType.BulkMarkReadStatusNotifications](store, args) {
        const payload = {
            notification_ids: args.form.notificationIds || [],
            read_status: args.form.readStatus ?? true,
        };

        return new ApiRequest(args?.options).send('put', 'notifications/read-status-bulk', payload);
    },

    [ActionType.GetStudyPeriods](store, args) {
        const period = store.getters.getSelectedPeriodByDate;

        return new ApiRequest(args?.options).send('get', `/monitor/study-periods/barns?${period}&barn_ids=${args.barnIds.join(',')}`);
    },

    [ActionType.GetStudyPeriodEvaluationCategories](store, args) {
        return new ApiRequest(args?.options)
            .connect(store, { mutationType: MutationType.SetStudyPeriodEvaluationCategories, stateKey: 'studyPeriodEvaluationCategories' })
            .throttle(ActionType.GetStudyPeriodEvaluationCategories)
            .send('get', 'monitor/study-periods/evaluation-categories');
    },

    [ActionType.GetStudyPeriodsDashboard](store, args) {
        const evaluationCategoryId = args.options?.evaluationCategoryId;
        const scope = args.options?.scope;
        const studyPeriodIds = args.options?.studyPeriodIds || [];
        const filterItems = args.options?.filterItems;
        const filterOptions = args.options?.filterOptions;

        let query = `?evaluation_category_id=${evaluationCategoryId}&scope=${scope}&study_period_ids=${studyPeriodIds.join(',')}&initialrequest=false`;

        if (filterItems) {
            query += `&filteritems=${filterItems}`;
        }

        if (filterOptions) {
            query += `&filteroptions=${filterOptions}`;
        }

        return new ApiRequest(args?.options).throttle(ActionType.GetStudyPeriodsDashboard).send('get', `dashboards/study-periods/evaluation-category${query}`);
    },
};
