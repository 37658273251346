<template>
    <div class="flex space-x-4">
        <div v-for="(picker, pickerIndex) of pickers" :key="picker.id" class="period-picker relative">
            <FlatPickrWrapper
                v-model:show="picker.expandPicker"
                :value="picker.value"
                auto-width
                class="h-full"
                :config="pickerConfigs[pickerIndex]"
                @update:value="(newValue) => onValueChanged(picker, newValue)"
            >
                <button
                    autocomplete="off"
                    :class="[picker.expandPicker ? 'bg-white border-black' : 'bg-transparent border-gray-200', slim ? 'h-10 items-center px-3' : 'h-full py-3 px-2']"
                    class="text-left flex text-base text-dark-gray-600 content-center border-solid border rounded-md"
                    @click.prevent="picker.expandPicker = !picker.expandPicker"
                >
                    <div class="flex h-full whitespace-nowrap" :class="slim ? 'text-normal' : ''">
                        <span class="font-semibold my-auto mr-1">
                            {{ pickerPrefixes[pickerIndex] }}
                        </span>
                        <span class="my-auto" :class="slim ? 'w-auto' : 'w-21'">
                            {{ pickerInputValues[pickerIndex] }}
                        </span>
                        <span class="h-full flex items-center my-auto ml-2">
                            <ChevronDown v-if="slim" class="size-5 -mr-1 transform transition-transform" :class="{ 'rotate-[180deg]': picker.expandPicker }" />
                            <ExpandIcon v-else class="my-auto w-4 h-2" color="black" :expand="picker.expandPicker"></ExpandIcon>
                        </span>
                    </div>
                </button>
            </FlatPickrWrapper>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ChevronDown } from 'lucide-vue-next';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { store } from '@/plugins/store';
import { MutationType } from '@/plugins/store/mutations';
import FlatPickrWrapper from '@/components/ui/FlatpickrWrapper.vue';

type Picker = {
    id: string;
    labelPrefix: string;
    value: string;
    expandPicker: boolean;
};

export default defineComponent({
    components: { FlatPickrWrapper, ExpandIcon, ChevronDown },
    props: {
        slim: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'submit'],
    data() {
        return {
            pickers: [
                {
                    id: 'PERIOD_PICKER_FROM',
                    value: '',
                    expandPicker: false,
                },
                {
                    id: 'PERIOD_PICKER_TO',
                    value: '',
                    expandPicker: false,
                },
            ] as Picker[],
        };
    },
    computed: {
        pickerPrefixes() {
            return [this.$t('views.periodPicker.fromLabelPrefix'), this.$t('views.periodPicker.toLabelPrefix')];
        },
        pickerConfigs() {
            return this.pickers.map((currentPicker, index) => {
                const configKey = index === 0 ? 'maxDate' : 'minDate';
                const otherPickerValue = this.pickers[(index + 1) % 2].value;
                return { [configKey]: new Date(otherPickerValue) };
            });
        },
        pickerInputValues() {
            return this.pickers.map((currentPicker) => this.$d(new Date(Date.parse(currentPicker.value)), 'short'));
        },
    },
    created() {
        const dates = store.state.periodPickerDateString.split(' - ');
        for (let i = 0; i < dates.length; i++) {
            this.pickers[i].value = dates[i];
        }
    },
    methods: {
        onValueChanged(picker: Picker, newValue: string) {
            if (picker.value !== newValue) {
                picker.value = newValue;
                store.commit(MutationType.SetPeriodPickerDateString, `${this.pickers[0].value} - ${this.pickers[1].value}`);
            }
        },
    },
});
</script>
<style>
.period-picker .flatpickr-wrapper {
    top: 2.5rem;
    right: 0;
}
</style>
