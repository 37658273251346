import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-8 w-full" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = {
  class: "outline-none",
  tabindex: "0"
}
const _hoisted_4 = {
  key: 0,
  class: "w-full flex justify-center mb-4"
}
const _hoisted_5 = { class: "space-y-2" }
const _hoisted_6 = { class: "table-headers-medium border-collapse w-full" }
const _hoisted_7 = { class: "px-6 my-2 rounded text-dark-gray-800 text-opacity-50 font-medium" }
const _hoisted_8 = { class: "my-auto py-2 pl-2 pr-1 bg-feed-600 bg-opacity-60 rounded-l-lg" }
const _hoisted_9 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_10 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_11 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_12 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_13 = { class: "my-auto py-2 px-1 bg-feed-600 bg-opacity-60" }
const _hoisted_14 = { class: "my-auto pr-2 pl-1 bg-feed-600 bg-opacity-60 rounded-r-lg" }
const _hoisted_15 = { class: "mt-4 flex" }
const _hoisted_16 = { class: "flex space-x-4 h-14 w-full justify-center relative" }
const _hoisted_17 = { class: "flex space-x-4" }
const _hoisted_18 = { class: "flex ml-auto absolute right-0 top-0 bottom-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_SimpleInput = _resolveComponent("SimpleInput")!
  const _component_FeedDatePicker = _resolveComponent("FeedDatePicker")!
  const _component_FormCheckbox = _resolveComponent("FormCheckbox")!
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.header')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.enableRawMaterialRegister)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_AutoComplete, {
              class: "w-72",
              items: _ctx.rawMaterialRegisterItems,
              "item-text": "name",
              "item-value": "id",
              label: _ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.autoCompleteFieldLabel'),
              onItemClicked: _ctx.onRawMaterialRegisterItemClicked
            }, null, 8, ["items", "label", "onItemClicked"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([{ 'max-h-24': !_ctx.enableRawMaterialRegister || _ctx.value.register_id, 'max-h-0': !_ctx.value.register_id && _ctx.enableRawMaterialRegister, 'overflow-y-hidden': _ctx.hideSectionOverflow }, "transition-all duration-500"])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_7, [
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.name')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.feedPriceUnitHeader), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.validFrom')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.dry_matter')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.feedUnitHeaderText), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.crude_protein')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.table.headers.phosphorus')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_8, [
                  _createVNode(_component_SimpleInput, {
                    value: _ctx.value.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.name) = $event)),
                    readonly: _ctx.enableRawMaterialRegister && !_ctx.value.register_id,
                    class: "w-full"
                  }, null, 8, ["value", "readonly"])
                ]),
                _createElementVNode("td", _hoisted_9, [
                  _createVNode(_component_SimpleInput, {
                    value: _ctx.value.prices[0].price,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.prices[0].price) = $event)),
                    type: "number",
                    step: "any",
                    class: "w-full",
                    readonly: _ctx.enableRawMaterialRegister && !_ctx.value.register_id
                  }, null, 8, ["value", "readonly"])
                ]),
                _createElementVNode("td", _hoisted_10, [
                  _createVNode(_component_FeedDatePicker, {
                    value: _ctx.value.prices[0].valid_from,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.prices[0].valid_from) = $event)),
                    disabled: _ctx.enableRawMaterialRegister && !_ctx.value.register_id
                  }, null, 8, ["value", "disabled"])
                ]),
                _createElementVNode("td", _hoisted_11, [
                  _createVNode(_component_SimpleInput, {
                    value: _ctx.value.dry_matter,
                    type: "number",
                    step: "any",
                    class: "w-full",
                    readonly: _ctx.enableRawMaterialRegister && !_ctx.value.register_id,
                    onFocus: _ctx.onDryMatterFocus,
                    "onUpdate:value": _ctx.onDryMatterInput
                  }, null, 8, ["value", "readonly", "onFocus", "onUpdate:value"])
                ]),
                _createElementVNode("td", _hoisted_12, [
                  _createVNode(_component_SimpleInput, {
                    value: _ctx.value.fu,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.fu) = $event)),
                    type: "number",
                    step: "any",
                    class: "w-full",
                    readonly: _ctx.enableRawMaterialRegister && !_ctx.value.register_id
                  }, null, 8, ["value", "readonly"])
                ]),
                _createElementVNode("td", _hoisted_13, [
                  _createVNode(_component_SimpleInput, {
                    value: _ctx.value.crude_protein,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.crude_protein) = $event)),
                    type: "number",
                    step: "any",
                    class: "w-full",
                    readonly: _ctx.enableRawMaterialRegister && !_ctx.value.register_id
                  }, null, 8, ["value", "readonly"])
                ]),
                _createElementVNode("td", _hoisted_14, [
                  _createVNode(_component_SimpleInput, {
                    value: _ctx.value.phosphorus,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.phosphorus) = $event)),
                    type: "number",
                    step: "any",
                    class: "w-full",
                    readonly: _ctx.enableRawMaterialRegister && !_ctx.value.register_id
                  }, null, 8, ["value", "readonly"])
                ])
              ])
            ])
          ])
        ])
      ], 2),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", null, [
              _createVNode(_component_FormCheckbox, {
                value: _ctx.createAsFeedComponent,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.createAsFeedComponent) = $event)),
                classes: "w-full h-full",
                "label-classes": "pl-2 whitespace-nowrap my-auto",
                disabled: _ctx.enableRawMaterialRegister && !_ctx.chosenRawMaterialRegisterItem,
                label: _ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.feedComponentCheckboxLabel')
              }, null, 8, ["value", "disabled", "label"])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass([`${_ctx.createAsFeedComponent ? 'max-w-76' : 'max-w-0'} `, "transition-all duration-500"])
            }, [
              _createVNode(_component_CustomMultiSelect, {
                value: _ctx.feedComponentFarms,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.feedComponentFarms) = $event)),
                prepend: _ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.feedComponentFarmsLabel'),
                label: "",
                items: _ctx.farms,
                "item-text": "name",
                "item-value": "id",
                "show-required-indication": false,
                "dropdown-height-class": "max-h-56"
              }, null, 8, ["value", "prepend", "items"])
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_CustomButton, {
              disabled: _ctx.disableButtons,
              disable: !_ctx.validForm,
              class: "px-4 py-0",
              onClick: _ctx.onSubmitButtonClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('views.feed.rawMaterials.newRawMaterialModal.rawMaterialProperties.confirmButtonLabel')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "disable", "onClick"])
          ])
        ])
      ])
    ])
  ]))
}