<script setup lang="ts">
interface Props {
    duration?: number;
    easingEnter?: string;
    easingLeave?: string;
    opacityClosed?: number;
    opacityOpened?: number;
}

const props = withDefaults(defineProps<Props>(), {
    duration: 250,
    easingEnter: 'ease-in-out',
    easingLeave: 'ease-in-out',
    opacityClosed: 0,
    opacityOpened: 1,
});

const closed = '0px';

interface initialStyle {
    height: string;
    width: string;
    position: string;
    visibility: string;
    overflow: string;
    paddingTop: string;
    paddingBottom: string;
    borderTopWidth: string;
    borderBottomWidth: string;
    marginTop: string;
    marginBottom: string;
}

function getElementStyle(element: HTMLElement) {
    return {
        height: element.style.height,
        width: element.style.width,
        position: element.style.position,
        visibility: element.style.visibility,
        overflow: element.style.overflow,
        paddingTop: element.style.paddingTop,
        paddingBottom: element.style.paddingBottom,
        borderTopWidth: element.style.borderTopWidth,
        borderBottomWidth: element.style.borderBottomWidth,
        marginTop: element.style.marginTop,
        marginBottom: element.style.marginBottom,
    };
}

function prepareElement(element: HTMLElement, initStyle: initialStyle) {
    const { width } = getComputedStyle(element);

    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = '';

    const { height } = getComputedStyle(element);

    element.style.width = initStyle.width;
    element.style.position = initStyle.position;
    element.style.visibility = initStyle.visibility;
    element.style.height = closed;
    element.style.overflow = 'hidden';

    return initStyle.height && initStyle.height !== closed ? initStyle.height : height;
}

function animateTransition(element: HTMLElement, initStyle: initialStyle, done: () => void, keyframes: any[] | any | null, options?: number | any) {
    const animation = element.animate(keyframes, options);

    // Set height to 'auto' to restore it after animation
    element.style.height = initStyle.height;

    animation.onfinish = () => {
        element.style.overflow = initStyle.overflow;
        done();
    };
}

function getEnterKeyframes(height: string, initStyle: initialStyle) {
    return [
        {
            height: closed,
            opacity: props.opacityClosed,
            paddingTop: closed,
            paddingBottom: closed,
            borderTopWidth: closed,
            borderBottomWidth: closed,
            marginTop: closed,
            marginBottom: closed,
        },
        {
            height,
            opacity: props.opacityOpened,
            paddingTop: initStyle.paddingTop,
            paddingBottom: initStyle.paddingBottom,
            borderTopWidth: initStyle.borderTopWidth,
            borderBottomWidth: initStyle.borderBottomWidth,
            marginTop: initStyle.marginTop,
            marginBottom: initStyle.marginBottom,
        },
    ];
}

function enterTransition(element: Element, done: () => void) {
    const HTMLElement = element as HTMLElement;
    const initStyle = getElementStyle(HTMLElement);
    const height = prepareElement(HTMLElement, initStyle);
    const keyframes = getEnterKeyframes(height, initStyle);
    const options = { duration: props.duration, easing: props.easingEnter };

    animateTransition(HTMLElement, initStyle, done, keyframes, options);
}

function leaveTransition(element: Element, done: () => void) {
    const el = element as HTMLElement;
    const initStyle = getElementStyle(el);
    const { height } = getComputedStyle(el);

    el.style.height = height;
    el.style.overflow = 'hidden';

    const keyframes = getEnterKeyframes(height, initStyle).reverse();
    const options = { duration: props.duration, easing: props.easingLeave };

    animateTransition(el, initStyle, done, keyframes, options);
}
</script>

<template>
    <Transition :css="false" @enter="enterTransition" @leave="leaveTransition">
        <slot />
    </Transition>
</template>
