<template>
    <DashboardContentLayout :heading="$t('views.monitoring.teamOverview.headings.title')" :has-period-picker="false">
        <template #header-top-right>
            <div>
                <SearchInput
                    v-model="searchQuery"
                    :aria-label="$t('views.monitoring.teamOverview.fields.search.label')"
                    :placeholder="$t('views.monitoring.teamOverview.fields.search.placeholder')"
                    class="w-64"
                    @keyup.esc="searchQuery = ''"
                />
            </div>
        </template>
        <div class="border-t border-t-gray-200 mt-5 mb-6" aria-hidden="true" />
        <div class="flex items-center justify-end flex-wrap gap-4">
            <DropdownMenu :modal="false">
                <DropdownMenuTrigger as-child>
                    <Button
                        variant="outline"
                        class="transition-[opacity,background-color]"
                        :class="{ 'visible opacity-100': selectedStudyPeriodIds.length > 0, 'invisible opacity-0': selectedStudyPeriodIds.length === 0 }"
                    >
                        {{ $t('views.monitoring.teamOverview.buttons.manageSelected') }}
                        <span class="bg-gray-100 min-w-5 flex items-center justify-center rounded-[5px] px-1.5 py-px font-semibold">{{ selectedStudyPeriodIds.length }}</span>
                        <ChevronDown class="size-4" aria-hidden="true" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="min-w-[var(--radix-dropdown-menu-trigger-width)]" align="end">
                    <DropdownMenuItem class="cursor-pointer" @click="handleEditTeams">{{ $t('views.monitoring.teamOverview.buttons.edit') }}</DropdownMenuItem>
                    <DropdownMenuItem class="cursor-pointer" @click="barnSelections = {}">{{ $t('views.monitoring.teamOverview.buttons.unselectAll') }}</DropdownMenuItem>
                    <DropdownMenuItem class="cursor-pointer" @click="handleBulkDelete">
                        <span class="text-red-500">{{ $t('views.monitoring.teamOverview.buttons.delete') }}</span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <div class="cursor-pointer" :class="cn(buttonVariants({ variant: 'outline' }))" @click.self="showOnlyFavorites = !showOnlyFavorites">
                <Checkbox id="unread" v-model:checked="showOnlyFavorites" />
                <label for="unread" class="cursor-pointer select-none text-normal font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    {{ $t('views.monitoring.teamOverview.fields.showOnlyFavorites') }}
                </label>
            </div>
            <PeriodPicker slim />
        </div>
        <FadeTransition mode="out-in">
            <div v-if="isLoading" class="flex items-center justify-center px-4 py-16">
                <Spinner class="h-10 w-10" />
            </div>
            <div v-else-if="filteredData.length" key="content" class="max-w-full space-y-5 mt-12">
                <div v-for="farm in filteredData" :key="farm.farm_id" class="border border-gray-300 rounded-lg px-4 py-5">
                    <div class="flex items-center justify-between space-x-4">
                        <div class="flex items-center space-x-3">
                            <div class="size-6">
                                <Icon class="h-full w-full object-contain" :src="IconSource.FarmsPrimary"></Icon>
                            </div>
                            <div>
                                <div class="font-bold text-lg">{{ farm.farm_name }}</div>
                                <div class="text-sm text-gray-500">
                                    {{ farm.barns.length }} {{ farm.barns.length === 1 ? $t('views.monitoring.teamOverview.texts.barn') : $t('views.monitoring.teamOverview.texts.barns') }}, {{ farm.teamCount }}
                                    {{ farm.teamCount === 1 ? $t('views.monitoring.teamOverview.texts.team') : $t('views.monitoring.teamOverview.texts.teams') }}
                                </div>
                            </div>
                        </div>
                        <div class="pr-1">
                            <button
                                type="button"
                                class="h-10 w-10 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200"
                                @click="expandedSelection[farm.farm_id] = expandedSelection[farm.farm_id] !== undefined ? !expandedSelection[farm.farm_id] : true"
                            >
                                <ChevronDown class="size-7 transform transition-transform" :class="{ 'rotate-[180deg]': expandedSelection[farm.farm_id] }" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <ExpandCollapseTransition>
                        <div v-show="expandedSelection[farm.farm_id]" class="mt-5 space-y-5">
                            <BarnSection v-for="barn in farm.barns" :key="barn.barn_id" :barn="barn" :growth-intervals="growthIntervals" @delete="handleDelete" @create="handleCreate" @edit="handleEdit" />
                        </div>
                    </ExpandCollapseTransition>
                </div>
            </div>
            <div v-else class="flex items-center justify-center px-4 py-16">
                <div class="text-gray-500 text-center">
                    {{ $t('views.monitoring.teamOverview.texts.noData') }}
                </div>
            </div>
        </FadeTransition>
        <CreateTeamModal v-model="showCreateModal" :barn-id="selectedBarnId" />
        <EditTeamModal v-model="showEditModal" :team="selectedTeam" @delete="handleDelete" />
        <DeleteTeamModal v-model="showDeleteModal" :team-ids="selectedTeamIds" @deleted="showEditModal = false" />
        <EditTeamsModal v-model="showEditTeamsModal" :teams="teamsToEdit" />
    </DashboardContentLayout>
</template>

<script setup lang="ts">
import { parseISO } from 'date-fns';
import { computed, ref, nextTick, provide, watch } from 'vue';
import { ChevronDown } from 'lucide-vue-next';
import { refDebounced } from '@vueuse/core';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { IconSource, StudyPeriod, StudyPeriodBarnResponseEntry } from '@/types';
import { store } from '@/plugins/store';
import { useBarnStudyPeriods, useStudyPeriodGrowthIntervals } from '@/plugins/store/actions/queries/study-periods';
import BarnSection from './BarnSection.vue';
import DeleteTeamModal from './DeleteTeamModal.vue';
import CreateTeamModal from './CreateTeamModal.vue';
import EditTeamModal from './EditTeamModal.vue';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { buttonVariants, Button } from '@/components/ui/button';
import PeriodPicker from '@/components/ui/PeriodPicker.vue';
import { SearchInput } from '@/components/ui/input';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import EditTeamsModal from './EditTeamsModal.vue';

const sitesAreLoaded = computed(() => store.state.sitesAreLoaded);
const barnIds = computed(() => store.getters.getSelectedSiteIds);
const selectedPeriod = computed(() => store.getters.getSelectedPeriod);
const isQueryEnabled = computed(() => barnIds.value.length > 0);

const { data: studyPeriodBarns, isLoading: isStudyPeriodsLoading } = useBarnStudyPeriods({ barnIds, selectedPeriod }, { enabled: isQueryEnabled });
const isLoading = computed(() => isStudyPeriodsLoading.value || !sitesAreLoaded.value);

const expandedSelection = ref<Record<number, boolean>>({});
const showOnlyFavorites = ref(false);
const searchQuery = ref('');
const debouncedSearchQuery = refDebounced(searchQuery, 300, { maxWait: 1000 });

const data = computed(() => {
    const farms: {
        farm_id: number;
        farm_name: string;
        barns: (StudyPeriodBarnResponseEntry & { farm_id: number; barn_name: string })[];
        teamCount: number;
    }[] = [];

    const barns =
        studyPeriodBarns.value
            ?.map((b) => {
                const barn = store.state.barns.find((stateBarn) => stateBarn.barn_id === b.barn_id);

                if (!barn) {
                    return null;
                }

                const studyPeriods = b.study_periods
                    ?.filter((sp) => (showOnlyFavorites.value ? sp.is_favorite : true))
                    .map((sp) => ({
                        ...sp,
                        start: parseISO(sp.start as string),
                        end: parseISO(sp.end as string),
                    }));

                return {
                    ...b,
                    farm_id: barn.farm_id,
                    study_periods: studyPeriods,
                    barn_name: barn.name,
                };
            })
            .filter((b) => b !== null) || [];

    for (const farm of store.state.farms) {
        const farmBarns = barns.filter((b) => b.farm_id === farm.farm_id && store.state.sections?.some((s) => s.barn_id === b.barn_id));

        if (farmBarns.length) {
            farms.push({
                farm_id: farm.farm_id,
                farm_name: farm.name,
                barns: farmBarns,
                teamCount: farmBarns.map((b) => b.study_periods.length).reduce((a, b) => a + b, 0),
            });
        }
    }

    return farms;
});

const filteredData = computed(() => {
    const query = debouncedSearchQuery.value.trim().toLowerCase();

    if (!query) {
        return data.value;
    }

    return data.value
        .map((farm) => ({
            ...farm,
            barns: farm.barns
                .map((barn) => ({
                    ...barn,
                    study_periods: barn.study_periods.filter((sp) => sp.name.toLowerCase().includes(query)),
                }))
                .filter((barn) => barn.study_periods.length > 0 || barn.barn_name.toLowerCase().includes(query) || farm.farm_name.toLowerCase().includes(query)),
        }))
        .filter((farm) => farm.barns.length > 0);
});

const showDeleteModal = ref(false);
const selectedTeamIds = ref<number[]>([]);

async function handleDelete(teamId: number) {
    selectedTeamIds.value = [teamId];
    await nextTick();
    showDeleteModal.value = true;
}

async function handleBulkDelete() {
    selectedTeamIds.value = getSelectedStudyPeriods().map((sp) => sp.id);
    await nextTick();
    showDeleteModal.value = true;
}

const showCreateModal = ref(false);
const selectedBarnId = ref<number | null>(null);

async function handleCreate(barnId: number) {
    selectedBarnId.value = barnId;
    await nextTick();
    showCreateModal.value = true;
}

const showEditModal = ref(false);
const selectedTeam = ref<StudyPeriod | null>(null);

async function handleEdit(team: StudyPeriod) {
    selectedTeam.value = team;
    await nextTick();
    showEditModal.value = true;
}

const barnSelections = ref<Record<number, Record<number, boolean>>>({});

watch(
    () => studyPeriodBarns.value,
    (barns) => {
        if (barns) {
            for (const barn of barns) {
                if (!barnSelections.value[barn.barn_id]) {
                    barnSelections.value[barn.barn_id] = {};
                }
            }
        }
    }
);

function handleSelectionChange(barnId: number, selections: Record<number, boolean>) {
    barnSelections.value[barnId] = selections;
}

provide('barnSelections', {
    selections: barnSelections,
    updateSelections: handleSelectionChange,
});

const selectedStudyPeriodIds = computed(() =>
    Object.entries(barnSelections.value)
        .filter(([barnId]) => studyPeriodBarns.value?.some((b) => b.barn_id.toString() === barnId))
        // eslint-disable-next-line no-unused-vars
        .flatMap(([barnId, selections]) => Object.keys(selections))
);

function getSelectedStudyPeriods() {
    return studyPeriodBarns.value?.flatMap((b) => b.study_periods?.filter((sp) => selectedStudyPeriodIds.value.includes(sp.id.toString())) || []) || [];
}

const showEditTeamsModal = ref(false);
const teamsToEdit = ref<StudyPeriod[]>([]);

async function handleEditTeams() {
    teamsToEdit.value = getSelectedStudyPeriods();

    await nextTick();

    showEditTeamsModal.value = true;
}

const { data: growthIntervals } = useStudyPeriodGrowthIntervals();
</script>
