<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.monitoring.teamComparison.headings.title')" has-period-picker slim-period-picker :has-pig-type-picker="false">
        <div>
            <div class="relative">
                <Listbox v-slot="{ open }" v-model="selectedStudyPeriodEvaluationCategoryId">
                    <ListboxButton class="w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left">
                        <div class="flex items-center gap-x-1 leading-normal">
                            <span class="font-medium">{{ $t('views.monitoring.teamComparison.fields.category.label') }}:</span>
                            <span class="text-gray-500">
                                {{
                                    formattedStudyPeriodEvaluationCategories.find((t) => t.value === selectedStudyPeriodEvaluationCategoryId)?.label ||
                                    $t('views.monitoring.teamComparison.fields.category.texts.noSelection')
                                }}
                            </span>
                        </div>
                        <ChevronDown class="flex-shrink-0 h-5 w-5 transition-transform" :class="{ 'rotate-180': open }" aria-hidden="true" />
                    </ListboxButton>
                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-100 ease-out"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <ListboxOptions class="absolute z-20 mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <ListboxOption v-for="category in formattedStudyPeriodEvaluationCategories" :key="category.value" v-slot="{ active, selected }" :value="category.value">
                                <li
                                    class="py-2 px-3 h-full truncate block w-full text-left cursor-pointer"
                                    :class="{
                                        'bg-primary-300 bg-opacity-40': selected,
                                        'hover:bg-gray-100': !selected,
                                        'bg-gray-100': active && !selected,
                                    }"
                                >
                                    {{ category.label }}
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </Listbox>
            </div>
            <Popover v-model:open="isLocationPickerOpen">
                <PopoverTrigger as-child>
                    <button class="mt-4 w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left">
                        <div class="flex items-center gap-x-1 leading-normal">
                            <span class="font-medium">{{ $t('views.monitoring.teamComparison.fields.locations.label') }}:</span>
                            <span class="text-gray-500">
                                {{ countText }}
                            </span>
                        </div>
                        <ChevronDown class="flex-shrink-0 h-5 w-5 transition-transform" :class="{ 'rotate-180': isLocationPickerOpen }" aria-hidden="true" />
                    </button>
                </PopoverTrigger>
                <PopoverContent trigger-width align="start" class="flex flex-col p-0 h-[516px] overflow-hidden">
                    <div role="tree" aria-orientation="horizontal" class="flex flex-grow divide-x max-h-full overflow-hidden">
                        <div class="flex flex-col items-stretch w-[30%] flex-shrink-0" role="group">
                            <div class="sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2">{{ $t('views.monitoring.teamComparison.fields.locations.groups.farms') }}</div>
                            <div class="max-h-full overflow-y-auto">
                                <div
                                    v-for="(farm, farmIndex) in locationOptions"
                                    :key="farm.farm_id"
                                    class="flex items-center cursor-pointer"
                                    role="treeitem"
                                    :aria-label="farm.name"
                                    aria-level="1"
                                    :aria-expanded="selectedActiveFarmIndex === farmIndex"
                                    :aria-setsize="locationOptions.length"
                                    :aria-posinset="farmIndex + 1"
                                    tabindex="0"
                                    @keydown.enter="selectActiveFarm(farmIndex)"
                                    @mouseover="selectActiveFarm(farmIndex)"
                                    @click="selectActiveFarm(farmIndex)"
                                >
                                    <label
                                        class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors"
                                        :class="{
                                            'hover:bg-gray-100/70 focus:bg-gray-100/70': locationOptions[farmIndex]?.barns?.length && selectedActiveFarmIndex !== farmIndex,
                                            'bg-primary-300 bg-opacity-30': selectedActiveFarmIndex === farmIndex,
                                        }"
                                    >
                                        <CustomCheckbox
                                            :checked="farm.checked"
                                            :indeterminate="farm.indeterminate"
                                            disable-transition
                                            class="flex-shrink-0"
                                            @update:checked="
                                                () => {
                                                    hasMadeSelection = true;
                                                    toggleFarm({ farm });
                                                }
                                            "
                                        />
                                        <div class="flex grow gap-x-3 items-center justify-between max-w-full min-w-0">
                                            <span class="grow select-none truncate">{{ farm.name }}</span>
                                            <ChevronRight v-if="locationOptions[farmIndex]?.barns?.length" class="flex-shrink-0 h-4 w-4 -mr-1" />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-stretch w-[30%] flex-shrink-0" role="group">
                            <div class="sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2">{{ $t('views.monitoring.teamComparison.fields.locations.groups.barns') }}</div>
                            <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition duration-100 ease-in"
                                leave-from-class="opacity-0"
                                leave-to-class="opacity-0"
                            >
                                <div v-if="selectedActiveFarmIndex !== null" class="max-h-full overflow-y-auto">
                                    <div
                                        v-for="(barn, barnIndex) in barnOptions"
                                        :key="barn.id"
                                        role="treeitem"
                                        :aria-label="barn.name"
                                        aria-level="2"
                                        :aria-expanded="selectedActiveBarnIndex === barnIndex"
                                        :aria-setsize="locationOptions[selectedActiveFarmIndex]?.barns.length"
                                        :aria-posinset="barnIndex + 1"
                                        class="flex items-center cursor-pointer"
                                        tabindex="0"
                                        @keydown.enter="selectActiveBarn(barnIndex)"
                                        @mouseover="selectActiveBarn(barnIndex)"
                                        @click="selectActiveBarn(barnIndex)"
                                    >
                                        <label
                                            class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors"
                                            :class="{
                                                'hover:bg-gray-100/70 focus:bg-gray-100/70': barnOptions[barnIndex]?.studyPeriods?.length && selectedActiveBarnIndex !== barnIndex,
                                                'bg-primary-300 bg-opacity-30': selectedActiveBarnIndex === barnIndex,
                                            }"
                                        >
                                            <CustomCheckbox
                                                :checked="barn.checked"
                                                :indeterminate="barn.indeterminate"
                                                disable-transition
                                                class="flex-shrink-0"
                                                @update:checked="
                                                    () => {
                                                        hasMadeSelection = true;
                                                        toggleBarn({ barn });
                                                    }
                                                "
                                            />
                                            <div class="flex grow gap-x-3 items-center justify-between max-w-full min-w-0">
                                                <span class="grow select-none truncate">{{ barn.name }}</span>
                                                <ChevronRight v-if="barnOptions[barnIndex]?.studyPeriods?.length" class="flex-shrink-0 h-4 w-4 -mr-1" />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="flex flex-col items-stretch w-[40%] flex-shrink-0" role="group">
                            <div class="sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2">
                                <transition
                                    enter-active-class="transition duration-100 ease-out"
                                    enter-from-class="opacity-0"
                                    enter-to-class="opacity-100"
                                    leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-0"
                                    leave-to-class="opacity-0"
                                    mode="out-in"
                                >
                                    <span :key="lastSectionText.key">{{ lastSectionText.text }}</span>
                                </transition>
                            </div>
                            <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition duration-100 ease-in"
                                leave-from-class="opacity-0"
                                leave-to-class="opacity-0"
                                mode="out-in"
                            >
                                <div v-if="selectedActiveFarmIndex !== null && selectedActiveBarnIndex !== null && barnOptions[selectedActiveBarnIndex]?.studyPeriods?.length" class="max-h-full overflow-y-auto">
                                    <div
                                        v-for="studyPeriod in locationOptions[selectedActiveFarmIndex]?.barns[selectedActiveBarnIndex]?.studyPeriods"
                                        :key="studyPeriod.id"
                                        role="treeitem"
                                        :aria-label="studyPeriod.name"
                                        aria-level="3"
                                        class="flex items-center cursor-pointer"
                                        tabindex="0"
                                    >
                                        <label class="flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer hover:bg-gray-100/70 focus:bg-gray-100/70 transition-colors">
                                            <CustomCheckbox
                                                :checked="studyPeriod.checked"
                                                disable-transition
                                                class="flex-shrink-0"
                                                @update:checked="
                                                    () => {
                                                        hasMadeSelection = true;
                                                        toggleStudyPeriod(studyPeriod);
                                                    }
                                                "
                                            />
                                            <div class="grow flex items-center justify-between gap-x-2 select-none max-w-full min-w-0">
                                                <div class="flex items-center gap-x-1.5 min-w-0">
                                                    <div class="whitespace-nowrap truncate min-w-0">{{ studyPeriod.name }}</div>
                                                    <svg v-if="studyPeriod.is_favorite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="shrink-0 size-4 text-yellow-400">
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                                <div v-if="studyPeriod.start && studyPeriod.end" class="text-gray-500 text-sm whitespace-nowrap">
                                                    {{ $d(parseISO(studyPeriod.start as string), 'short') }}{{ studyPeriod.end ? ` - ${$d(parseISO(studyPeriod.end as string), 'short')}` : '' }}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
            <CustomMultiSelect
                :value="(selectedStudyPeriodFavoriteIds as any)"
                :items="studyPeriodFavoriteOptions"
                item-value="value"
                item-text="label"
                class="mt-4"
                flat-values
                @update:value="handleStudyPeriodsFavoritesChange"
            >
                <template #trigger="{ displayValue, expanded }">
                    <div class="mb-5 w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left">
                        <div class="flex items-center gap-x-1 leading-normal">
                            <span class="font-medium">{{ $t('views.monitoring.teamComparison.fields.favoriteTeams.label') }}: </span>
                            <span class="text-gray-500">
                                {{ displayValue }}
                            </span>
                        </div>
                        <ChevronDown class="flex-shrink-0 h-5 w-5 transition-transform" :class="{ 'rotate-180': expanded }" aria-hidden="true" />
                    </div>
                </template>
                <template #item="{ item, text }">
                    <div class="flex grow justify-between max-w-full min-w-0 items-center gap-x-2 pr-5">
                        <div class="whitespace-nowrap truncate min-w-0">
                            {{ text }} <span v-if="item.locationNameAugmented" class="text-gray-500">({{ item.locationNameAugmented }})</span>
                        </div>
                        <div v-if="item.start && item.end" class="text-gray-500 text-sm whitespace-nowrap">
                            {{ $d(parseISO(item.start as string), 'short') }}{{ item.end ? ` - ${$d(parseISO(item.end as string), 'short')}` : '' }}
                        </div>
                    </div>
                </template>
            </CustomMultiSelect>

            <div class="flex justify-end">
                <CustomButton class="w-auto min-w-40 px-5" :disabled="!selectedLocationIds.length || isLoading" @click="generateDashboard">
                    {{ $t('views.monitoring.teamComparison.buttons.generate') }}
                </CustomButton>
            </div>
        </div>
        <div class="default-tabs">
            <Tabs teleport="#team-comparison-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.monitoring.teamComparison.tabs.table')" :default="true">
                            <TableTab
                                :selected-study-period-evaluation-category-id="fetchState.selectedStudyPeriodEvaluationCategoryId"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :selected-favorite-study-period-ids="fetchState.selectedFavoriteStudyPeriodIds"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.monitoring.teamComparison.tabs.chart')">
                            <ChartTab
                                :selected-study-period-evaluation-category-id="fetchState.selectedStudyPeriodEvaluationCategoryId"
                                :selected-location-ids="fetchState.selectedLocationIds"
                                :selected-favorite-study-period-ids="fetchState.selectedFavoriteStudyPeriodIds"
                                :fetch-index="fetchState.fetchIndex"
                            />
                        </Tab>
                    </div>
                </nav>
            </Tabs>
            <div id="team-comparison-tabs-target" class="mt-6" />
        </div>
    </DashboardContentLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { ChevronRight, ChevronDown } from 'lucide-vue-next';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { useIsFetching, useQueryClient } from '@tanstack/vue-query';
import { parseISO } from 'date-fns';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { Farm, Module, StudyPeriod } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods/dashboards';
import { useBarnStudyPeriods } from '@/plugins/store/actions/queries/study-periods';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import TableTab from './TableTab.vue';
import ChartTab from './ChartTab.vue';

store.dispatch(ActionType.GetStudyPeriodEvaluationCategories);

const hasSectionsModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.Sections));

const isFetching = useIsFetching({ queryKey: queryKeys.getDashboardRoot });
const isLoading = computed(() => isFetching.value > 0);

const studyPeriodEvaluationCategories = computed(() => store.state.studyPeriodEvaluationCategories);
const formattedStudyPeriodEvaluationCategories = computed(() => studyPeriodEvaluationCategories.value.map((t) => ({ value: t.id.toString(), label: t.name })));
const selectedStudyPeriodEvaluationCategoryId = ref<string | null>(null);
const fetchState = ref<{ selectedStudyPeriodEvaluationCategoryId: string | undefined; selectedLocationIds: number[]; selectedFavoriteStudyPeriodIds: number[]; fetchIndex: number }>({
    selectedStudyPeriodEvaluationCategoryId: undefined,
    selectedLocationIds: [],
    selectedFavoriteStudyPeriodIds: [],
    // Used to trigger a refetch of the dashboard when generate is clicked
    fetchIndex: 0,
});
const isLocationPickerOpen = ref(false);

interface LocationStudyPeriod extends StudyPeriod {
    checked: boolean;
}

interface LocationOptionBarn {
    id: number;
    name: string;
    checked: boolean;
    indeterminate: boolean;
    studyPeriods: LocationStudyPeriod[];
}

interface LocationOption extends Farm {
    barns: LocationOptionBarn[];
    checked: boolean;
    indeterminate: boolean;
}

const barnIds = computed(() => store.getters.getSelectedSiteIds);
const selectedPeriod = computed(() => store.getters.getSelectedPeriod);
const isQueryEnabled = computed(() => barnIds.value.length > 0);

const { data: studyPeriodBarns } = useBarnStudyPeriods({ barnIds, selectedPeriod }, { enabled: isQueryEnabled });

const locationOptions = computed<LocationOption[]>(() => {
    if (!store.state.farms?.length || !studyPeriodBarns.value?.length) {
        return [];
    }

    return store.state.farms
        .map((f) => {
            const barns = store.state.barns.filter((b) => b.farm_id === f.farm_id);
            const filteredBarns = studyPeriodBarns.value?.filter((b) => barns.some((b2) => b2.barn_id === b.barn_id)) || [];
            const populatedBarns = filteredBarns.length
                ? filteredBarns
                      .map((b) => {
                          if (!b.study_periods?.length) {
                              return null;
                          }

                          const studyPeriods = b.study_periods.map((sp) => ({
                              ...sp,
                              checked: selectedLocationIds.value.includes(sp.id),
                          }));
                          const checked = studyPeriods?.every((sp) => sp.checked);

                          return {
                              id: b.barn_id,
                              name: b.barn_name,
                              checked,
                              indeterminate: (!checked && studyPeriods?.some((sp) => sp.checked)) ?? false,
                              studyPeriods,
                          };
                      })
                      .filter((b) => b !== null)
                : [];

            if (!populatedBarns.length) {
                return null;
            }

            const checked = populatedBarns?.every((b) => b.checked) ?? false;

            return {
                ...f,
                barns: populatedBarns,
                checked,
                indeterminate: (!checked && populatedBarns?.some((b) => b.checked || b.indeterminate)) ?? false,
            };
        })
        .filter((f) => f !== null);
});
const studyPeriodFavoriteOptions = computed(() =>
    studyPeriodBarns.value
        ?.flatMap((b) => b.study_periods.filter((sp) => sp.is_favorite))
        .map((sp) => ({
            value: sp.id,
            label: sp.name,
            start: sp.start,
            end: sp.end,
            locationNameAugmented: sp.location_name_augmented,
        }))
);
const barnOptions = computed(() => {
    if (selectedActiveFarmIndex.value === null) {
        return [];
    }

    return locationOptions.value[selectedActiveFarmIndex.value]?.barns || [];
});
const selectedActiveFarmIndex = ref<number | null>(0);
const selectedActiveBarnIndex = ref<number | null>(0);
const selectedLocationIds = ref<number[]>([]);
const selectedStudyPeriodFavoriteIds = ref<number[]>([]);

const counts = computed(() => {
    const state = {
        farms: 0,
        barns: 0,
        studyPeriods: 0,
    };

    for (const farm of locationOptions.value) {
        if (farm.checked || farm.indeterminate) {
            state.farms++;
        }

        for (const barn of farm.barns) {
            if (barn.checked || barn.indeterminate) {
                state.barns++;
            }

            for (const studyPeriod of barn.studyPeriods) {
                if (studyPeriod.checked) {
                    state.studyPeriods++;
                }
            }
        }
    }

    return state;
});
const countText = computed(() => {
    const { farms, barns, studyPeriods } = counts.value;

    const texts = [];

    if (farms) {
        texts.push(`${farms} ${farms === 1 ? i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.farm') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.farms')}`);
    }

    if (barns) {
        texts.push(`${barns} ${barns === 1 ? i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.barn') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.barns')}`);
    }

    if (studyPeriods) {
        texts.push(
            `${studyPeriods} ${studyPeriods === 1 ? i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.team') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.teams')}`
        );
    }

    return texts.length > 0 ? texts.join(', ') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.noSelections');
});

const lastSectionText = computed(() => {
    const batchText = {
        text: i18n.global.t('views.monitoring.teamComparison.fields.locations.groups.batches'),
        key: 'batches',
    };
    const teamText = {
        text: i18n.global.t('views.monitoring.teamComparison.fields.locations.groups.teams'),
        key: 'teams',
    };

    if (selectedActiveBarnIndex.value === null) {
        return hasSectionsModuleEnabled.value ? teamText : batchText;
    }

    if (hasSectionsModuleEnabled.value) {
        return teamText;
    }

    return batchText;
});

const hasMadeSelection = ref(false);

watch(
    () => studyPeriodBarns.value,
    async () => {
        selectedLocationIds.value = [];
        selectedStudyPeriodFavoriteIds.value = [];
    }
);

function selectActiveFarm(farmIndex: number) {
    selectedActiveFarmIndex.value = farmIndex;
    selectedActiveBarnIndex.value = null;
}

function selectActiveBarn(barnIndex: number) {
    selectedActiveBarnIndex.value = barnIndex;
}

function toggleFarm({ farm, checked }: { farm: LocationOption; checked?: boolean }) {
    const barns = farm.barns;

    for (const barn of barns) {
        toggleBarn({ barn, checked: checked ?? farm.checked });
    }
}

function toggleBarn({ barn, checked }: { barn: LocationOptionBarn; checked?: boolean }) {
    const isChecked = checked ?? barn.checked;
    const studyPeriodIds = barn.studyPeriods.map((sp) => sp.id);

    if (isChecked) {
        selectedLocationIds.value = selectedLocationIds.value.filter((id) => !studyPeriodIds.includes(id));
    } else {
        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, ...studyPeriodIds])];
    }
}

function toggleStudyPeriod(studyPeriod: LocationStudyPeriod) {
    if (selectedLocationIds.value.includes(studyPeriod.id)) {
        selectedLocationIds.value = selectedLocationIds.value.filter((id) => id !== studyPeriod.id);
    } else {
        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, studyPeriod.id])];
    }
}

function handleStudyPeriodsFavoritesChange(value: number[]) {
    selectedStudyPeriodFavoriteIds.value = value;
}

function getSelectedStudyPeriodIds() {
    const selectedIds: number[] = [];

    for (const farm of locationOptions.value) {
        for (const barn of farm.barns) {
            const checkedStudyPeriods = barn.studyPeriods.filter((sp) => sp.checked);

            selectedIds.push(...checkedStudyPeriods.map((sp) => sp.id));
        }
    }

    return selectedIds;
}

function generateDashboard() {
    fetchState.value = {
        selectedStudyPeriodEvaluationCategoryId: selectedStudyPeriodEvaluationCategoryId.value!,
        selectedLocationIds: getSelectedStudyPeriodIds(),
        selectedFavoriteStudyPeriodIds: selectedStudyPeriodFavoriteIds.value,
        fetchIndex: fetchState.value.fetchIndex + 1,
    };
}

watch(
    studyPeriodEvaluationCategories,
    (newStudyPeriodEvaluationCategories) => {
        if (!newStudyPeriodEvaluationCategories?.length || selectedStudyPeriodEvaluationCategoryId.value) {
            return;
        }

        selectedStudyPeriodEvaluationCategoryId.value = newStudyPeriodEvaluationCategories[0].id.toString();
    },
    { immediate: true }
);

const queryClient = useQueryClient();

onMounted(() => {
    queryClient.invalidateQueries({ queryKey: queryKeys.getDashboardRoot });
});
</script>
