<template>
    <Dialog v-model="showModal" :disabled="isLoading" class="sm:max-w-[540px]">
        <DialogHeader>
            <DialogTitle>{{ $t('views.monitoring.teamOverview.modals.editTeam.title') }}</DialogTitle>
            <VisuallyHidden>
                <DialogDescription class="pt-4">{{ $t('views.monitoring.teamOverview.modals.editTeam.description') }}</DialogDescription>
            </VisuallyHidden>
        </DialogHeader>
        <form class="mt-4 space-y-2" @submit.prevent="handleCreate">
            <CustomInput :value="(state?.locationNameAugmented as any) ?? ''" disabled />
            <CustomInput
                v-model:value="(state.name as any)"
                :rules="[{ validate: (value: string) => value.length > 0, message: '' }]"
                required
                :label="$t('views.monitoring.teamOverview.modals.editTeam.fields.name')"
            />
            <div class="grid grid-cols-2 gap-x-4" tabindex="-1">
                <DatePicker v-model:value="state.startDate" required :label="$t('views.monitoring.teamOverview.modals.editTeam.fields.startDate')" />
                <DatePicker v-model:value="state.endDate" required :label="$t('views.monitoring.teamOverview.modals.editTeam.fields.endDate')" />
            </div>
            <CustomCheckbox v-model:checked="state.isFavorite" :label="$t('views.monitoring.teamOverview.modals.editTeam.fields.markAsFavorite')" class="-ml-1" />
        </form>
        <CustomButton :disabled="isLoading" color-preset="warning" @click.prevent="emit('delete', props.team!.id)">
            {{ $t('views.monitoring.teamOverview.modals.editTeam.buttons.deleteTeam') }}
        </CustomButton>
        <DialogFooter>
            <DialogClose as-child>
                <CustomButton :disabled="isLoading" color-preset="white">{{ $t('views.monitoring.teamOverview.modals.editTeam.buttons.cancel') }}</CustomButton>
            </DialogClose>
            <CustomButton :loading="isLoading" :disabled="!isFormValid" @click="handleCreate">{{ $t('views.monitoring.teamOverview.modals.editTeam.buttons.save') }}</CustomButton>
        </DialogFooter>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { VisuallyHidden } from 'radix-vue';
import { useToast } from 'vue-toastification';
import { addWeeks, format, parseISO } from 'date-fns';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import DatePicker from '@/components/ui/DatePicker.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { StudyPeriod } from '@/types';
import { i18n } from '@/plugins/internationalization/i18n';

const props = defineProps<{
    team: StudyPeriod | null;
}>();
const emit = defineEmits<{
    delete: [teamId: number];
}>();

const queryClient = useQueryClient();
const toast = useToast();

const showModal = defineModel<boolean>('modelValue');

const isLoading = ref(false);

interface EditTeamState {
    locationNameAugmented: string;
    name: string;
    startDate: string;
    endDate: string;
    isFavorite: boolean;
}

function getInitialState() {
    return {
        locationNameAugmented: '',
        name: '',
        startDate: new Date().toISOString(),
        endDate: addWeeks(new Date(), 1).toISOString(),
        isFavorite: false,
    };
}

const state = ref<EditTeamState>(getInitialState());

async function handleCreate() {
    try {
        if (isLoading.value) {
            return;
        }

        isLoading.value = true;

        await ApiClient.send(
            'put',
            '/monitor/study-periods/bulk-update',
            {
                skipHandleError: true,
            },
            {
                study_periods: [
                    {
                        id: props.team?.id,
                        name: state.value.name,
                        start: format(parseISO(state.value.startDate), 'yyyy-MM-dd'),
                        end: format(parseISO(state.value.endDate), 'yyyy-MM-dd'),
                        is_favorite: state.value.isFavorite,
                    },
                ],
            }
        );

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        showModal.value = false;

        toast.success(i18n.global.t('views.monitoring.teamOverview.modals.editTeam.successMessages.teamWasEdited'));
    } catch (err) {
        const errorMessage = (err as any)?.data?.message || i18n.global.t('views.monitoring.teamOverview.modals.editTeam.errors.couldNotEditTeam');
        toast.error(errorMessage);
    } finally {
        isLoading.value = false;
    }
}

const isFormValid = computed(() => state.value.name.length > 0);

watch(showModal, (value) => {
    if (value) {
        state.value = {
            locationNameAugmented: props.team?.location_name_augmented ?? '',
            name: props.team?.name ?? '',
            startDate: props.team?.start ? new Date(props.team.start as string).toISOString() : new Date().toISOString(),
            endDate: props.team?.end ? new Date(props.team.end as string).toISOString() : addWeeks(new Date(), 1).toISOString(),
            isFavorite: props.team?.is_favorite ?? false,
        };
    }
});
</script>
