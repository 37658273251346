<template>
    <div class="relative overflow-hidden">
        <input
            :id="uid"
            ref="input-field"
            v-model="computedValue"
            v-focus="focusOnRender"
            :class="[filledClass, borderClass, inputClass, paddingClass, focusedClass, cursorClass, addInputClass]"
            :style="[paddingStyle]"
            :autocomplete="autoComplete"
            :disabled="disabled"
            class="custom-input block w-full box-border outline-none rounded"
            :type="inputType"
            :step="step"
            :readonly="readonly"
            :tabIndex="tabIndex"
            @keydown.enter="$emit('submit')"
            @input="$emit('input')"
            @focus="onFocus"
        />
        <span v-if="prepend" ref="prepend" class="absolute text-dark-gray-600 left-5 top-3.5 font-semibold">{{ prepend }}</span>
        <label v-else :class="[labelClass, cursorClass]" :for="uid" class="custom-input-label text-gray-400 absolute top-4 left-5 transition-all truncate">
            <span>{{ label }}</span>
            <span v-show="required && showRequiredIndication && !disabled" class="text-black">*</span>
        </label>
        <span v-show="hint.text" class="min-h-4 flex text-xs text-left w-full ml-1" :class="{ 'text-red-600': hint?.type === 'error', 'text-gray-500': hint?.type === 'hint' }">{{ hint?.text || '' }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Focus from '@makeabledk/vue-ui/directives/focus/src/Focus';
import { validatableField } from '@/mixins/validatableField';

export default defineComponent({
    directives: { Focus },
    mixins: [validatableField],
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            default: null,
        },
        autoComplete: {
            default: null,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        step: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        prepend: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        showRequiredIndication: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        errorState: {
            type: Boolean,
            default: false,
        },
        tabIndex: {
            type: String,
            default: null,
        },
        focusHighlight: {
            type: Boolean,
            default: false,
        },
        focusOnRender: {
            type: Boolean,
            default: false,
        },
        hideAuthState: {
            type: Boolean,
            default: false,
        },
        cursor: {
            type: String,
            default: 'cursor-text',
        },
        addInputClass: {
            type: String,
            default: '',
        },
    },
    emits: ['update:value', 'submit', 'input', 'focus'],
    data() {
        return {
            uid: `input-${Math.random().toString(36).substring(6)}`,
            prependOffsetWidth: 0,
        };
    },
    computed: {
        paddingClass(): string {
            return this.$props.label ? 'pt-6 pb-1 px-5' : 'py-3.5 px-5';
        },
        paddingStyle(): string {
            return this.prependOffsetWidth ? `padding-left: ${this.prependOffsetWidth + 24}px` : '';
        },
        labelClass(): string {
            return this.disabled ? 'opacity-60' : '';
        },
        inputClass(): string {
            return this.disabled ? 'bg-white text-gray-400' : 'bg-gray-100';
        },
        cursorClass(): string {
            return this.disabled ? 'cursor-default' : this.$props.cursor;
        },
        borderClass(): string {
            if (this.hideAuthState) {
                return '';
            }
            if (this.disabled) {
                return 'border-gray-100 border-l border-t border-r border-b-2';
            }
            return this.validInput && !this.errorState ? 'border-green-200 border-b-2' : 'border-red-300 border-b-2';
        },
        filledClass(): string {
            return this.active || (this.value !== null && `${this.value}`.length > 0) ? 'filled' : '';
        },
        focusedClass(): string {
            return this.focusHighlight ? 'focused' : '';
        },
        computedValue: {
            get(): string | number | null {
                return this.value;
            },
            set(newValue: string) {
                if (this.inputType === 'number' && newValue !== '' && newValue !== '-' && !Number.isNaN(newValue as any)) {
                    this.$emit('update:value', Number(newValue));
                } else if (this.inputType === 'number' && newValue === '') {
                    this.$emit('update:value', null);
                } else {
                    this.$emit('update:value', newValue);
                }
            },
        },
    },
    mounted() {
        this.prependOffsetWidth = (this.$refs.prepend as HTMLSpanElement | undefined)?.offsetWidth || 0;
    },
    methods: {
        onFocus() {
            if (this.computedValue === 0 || this.computedValue === '0') {
                this.computedValue = '';
            }
            this.$emit('focus');
        },
        focusInput(): void {
            (this.$refs['input-field'] as HTMLInputElement).focus();
        },
    },
});
</script>

<style scoped>
.custom-input.filled + .custom-input-label,
.custom-input:focus + .custom-input-label {
    top: 0.4rem !important;
    font-size: 0.8125rem !important;
}

body.--keyboard-navigation .custom-input.focused,
body.--keyboard-navigation .custom-input:focus {
    border-color: #63b3ed;
    border-right: 4px solid #63b3ed !important;
}
</style>
