import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { i18n } from '@/plugins/internationalization/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteTeamModal',
  props: /*@__PURE__*/_mergeModels({
    teamIds: {}
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["deleted"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const queryClient = useQueryClient();
const toast = useToast();

const showModal = _useModel<boolean>(__props, 'modelValue');

const isLoading = ref(false);

async function handleDelete() {
    try {
        if (isLoading.value) {
            return;
        }

        isLoading.value = true;

        const requests = props.teamIds.map((teamId) => ApiClient.send('delete', `/monitor/study-periods/${teamId}`, { skipHandleError: true }));

        await Promise.all(requests);

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        emit('deleted');

        showModal.value = false;

        const successMessage =
            props.teamIds.length > 1
                ? i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.successMessages.teamsWereDeleted')
                : i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.successMessages.teamWasDeleted');

        toast.success(successMessage);
    } catch (err) {
        const errorMessage =
            props.teamIds.length > 1
                ? i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.errors.couldNotDeleteTeams')
                : i18n.global.t('views.monitoring.teamOverview.modals.deleteTeam.errors.couldNotDeleteTeam');

        toast.error(errorMessage);
    } finally {
        isLoading.value = false;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    modelValue: showModal.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    disabled: isLoading.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(DialogHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogTitle), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.teamIds.length > 1 ? _ctx.$t('views.monitoring.teamOverview.modals.deleteTeam.title.multiple') : _ctx.$t('views.monitoring.teamOverview.modals.deleteTeam.title.single')), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(DialogDescription), { class: "pt-4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.teamIds.length > 1 ? _ctx.$t('views.monitoring.teamOverview.modals.deleteTeam.description.multiple') : _ctx.$t('views.monitoring.teamOverview.modals.deleteTeam.description.single')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(DialogFooter), null, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogClose), { "as-child": "" }, {
            default: _withCtx(() => [
              _createVNode(CustomButton, {
                disabled: isLoading.value,
                "color-preset": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.deleteTeam.buttons.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(CustomButton, {
            loading: isLoading.value,
            "color-preset": "warning",
            onClick: handleDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.deleteTeam.buttons.delete')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "disabled"]))
}
}

})