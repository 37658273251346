<template>
    <div v-if="isError">
        <div class="py-14 text-center px-4 text-gray-500 text-lg">{{ $t('views.analysis.errors.couldNotLoadData') }}</div>
    </div>
    <GenericDashboard
        v-else-if="dashboard || isFetching"
        :data="dashboard"
        :loading="isFetching"
        :fetch-params="fetchParams"
        :fetch-action-type="ActionType.GetAnalysisDashboard"
        persistent-loading
        :no-data-text="$t('views.analysis.texts.noData')"
        @update:item="onUpdateItem"
        @update:section-collapse="onUpdateSectionCollapse"
    />
    <div v-else class="py-14 text-center px-4 text-gray-500 text-lg">{{ $t('views.analysis.texts.generateToViewInsight') }}</div>
</template>

<script setup lang="ts">
import { computed, Ref, ref, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import { useAnalysisDashboard, AnalysisDashboardScope } from '@/plugins/store/actions/queries/analysis/dashboards';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { ActionType } from '@/plugins/store/actions';
import { Dashboard, DashboardItem } from '@/types';

interface Props {
    selectedAnalysisType?: string;
    selectedLocationIds: number[];
    isWeaners?: boolean;
    period: string;
    includeCompleteBatch: boolean;
    fetchIndex: number;
}

const props = defineProps<Props>();

const typeId = computed(() => props.selectedAnalysisType);
const scope = ref(AnalysisDashboardScope.Barn);
const isWeaners = computed(() => props.isWeaners || null);
const locationIds = computed(() => props.selectedLocationIds);
const period = computed(() => props.period);
const includeCompleteBatch = computed(() => props.includeCompleteBatch);
const fetchIndex = computed(() => props.fetchIndex);
const isQueryEnabled = computed(() => Boolean(props.selectedAnalysisType) && props.selectedLocationIds.length > 0);

const { data, isFetching, isError } = useAnalysisDashboard(
    {
        typeId: typeId as Ref<string>,
        isWeaners,
        scope,
        locationIds,
        period,
        includeCompleteBatch,
        fetchIndex,
    },
    {
        enabled: isQueryEnabled,
    }
);

const dashboard = ref<Dashboard | undefined>();

watch(
    data,
    (newData) => {
        dashboard.value = cloneDeep(newData);
    },
    { immediate: true }
);

const fetchParams = computed(() => ({
    typeId: typeId.value,
    scope: scope.value,
    isWeaners: isWeaners.value,
    locationIds: locationIds.value,
}));

function onUpdateItem({ item }: { item: DashboardItem }) {
    const itemIndex = dashboard.value!.items.findIndex((i) => i.item_no === item.item_no);

    if (itemIndex !== -1) {
        dashboard.value!.items[itemIndex] = item;
    }
}

function onUpdateSectionCollapse({ sectionIndex, isCollapsed }: { sectionIndex: number; isCollapsed: boolean }) {
    if (dashboard.value?.sections?.[sectionIndex]) {
        dashboard.value.sections[sectionIndex].is_collapsed = isCollapsed;
    }
}
</script>
