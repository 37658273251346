<script setup lang="ts">
import { computed, ref, nextTick, h } from 'vue';
import { ChevronRight, ChevronDown } from 'lucide-vue-next';
import { ColumnDef } from '@tanstack/vue-table';
import { useQueryClient } from '@tanstack/vue-query';
import { DataTable, DataTableFilter } from '@/components/ui/data-table';
import { Card } from '@/components/ui/card';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { cn } from '@/lib/utils';
import { buttonVariants, Button } from '@/components/ui/button';
import { SearchInput } from '@/components/ui/input';
import Separator from '@/components/ui/separator/Separator.vue';
import { Form, Notification, NotificationStatusType } from '@/types';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import TextNotificationModal from '@/views/dashboard/notifications/notificationsIndex/TextNotificationModal.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { useFilterableNotificationGroups, useNotifications, queryKeys } from '@/plugins/store/actions/queries/notifications';
import { useNotificationsSearchParams } from '@/composables/notifications/useNotificationsSearchParams';
import StatusBadge from '@/views/dashboard/notifications/StatusBadge.vue';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import { Skeleton } from '@/components/ui/skeleton';
import { Checkbox } from '@/components/ui/checkbox';
import { i18n } from '@/plugins/internationalization/i18n';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useLoadingPageOverlayState } from '@/composables/useLoadingPageOverlayState';

const queryClient = useQueryClient();

const { data: filterableNotificationGroups, isLoading: isFilterableNotificationGroupsLoading } = useFilterableNotificationGroups();
const typeOptions = computed(() => filterableNotificationGroups.value?.map((g) => ({ value: g.id.toString(), label: g.text, icon_id: g.icon_id })) || []);
const statusOptions = computed(() => [
    { value: NotificationStatusType.REQUIRES_ACTION, label: i18n.global.t('views.notifications.statusOptions.requiresAction'), type: NotificationStatusType.REQUIRES_ACTION },
    { value: NotificationStatusType.APPROVED, label: i18n.global.t('views.notifications.statusOptions.approved'), type: NotificationStatusType.APPROVED },
    { value: NotificationStatusType.REJECTED, label: i18n.global.t('views.notifications.statusOptions.rejected'), type: NotificationStatusType.REJECTED },
]);

const { fetchParams, searchQuery, fetchSearchQuery, page, pageSize, sorting, pageSizeOptions, types, statuses, unread } = useNotificationsSearchParams();

const computedTypes = computed({
    get() {
        return filterableNotificationGroups.value?.filter((g) => types.value?.includes(g.id.toString())).map((g) => g.id.toString()) || [];
    },
    set(value) {
        types.value = value;
    },
});
const computedStatuses = computed({
    get() {
        return statusOptions.value.filter((o) => statuses.value?.includes(o.value)).map((o) => o.value);
    },
    set(value) {
        statuses.value = value;
    },
});
const fetchTypesIds = computed(() => filterableNotificationGroups.value?.filter((g) => types.value?.includes(g.id.toString())).flatMap((g) => g.type_ids) || []);
const fetchStatuses = computed(() => statusOptions.value.filter((o) => statuses.value?.includes(o.value)).map((o) => o.value) as any);
const populatedFetchParams = computed(() => ({ ...fetchParams.value, typeIds: fetchTypesIds.value, statuses: fetchStatuses.value }));
const isQueryEnabled = computed(() => !isFilterableNotificationGroupsLoading.value);
const { data: notifications, isFetching: isNotificationsLoading } = useNotifications(populatedFetchParams, { enabled: isQueryEnabled });
const isLoading = computed(() => isFilterableNotificationGroupsLoading.value || isNotificationsLoading.value);

const notificationsData = computed(
    () =>
        notifications.value?.data.map((n) => ({
            ...n,
            _type: filterableNotificationGroups.value?.find((g) => g.type_ids.includes(n.type_id)),
        })) || []
);
const pagination = computed(() => ({
    totalPages: notifications.value?.total_pages || 0,
    totalRows: notifications.value?.total_rows || 0,
}));

const columns = computed<ColumnDef<Notification>[]>(() => [
    {
        id: '_select',
        header: ({ table }) =>
            h(Checkbox, {
                checked: table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
                'onUpdate:checked': (value) => table.toggleAllPageRowsSelected(!!value),
                ariaLabel: i18n.global.t('views.notifications.fields.selectAll.label'),
                class: 'mt-0.5',
            }),
        cell: ({ row }) =>
            h(Checkbox, {
                checked: row.getIsSelected(),
                'onUpdate:checked': (value) => row.toggleSelected(!!value),
                ariaLabel: i18n.global.t('views.notifications.fields.selectNotification.label'),
                class: 'mt-0.5',
            }),
    },
    {
        header: i18n.global.t('views.notifications.table.headers.type'),
        accessorKey: '_type',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.notifications.table.headers.message'),
        accessorKey: 'message',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.notifications.table.headers.status'),
        accessorKey: '_status',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.notifications.table.headers.publishDate'),
        accessorKey: 'publish_date',
        enableSorting: true,
    },
    {
        header: '',
        accessorKey: '_actions',
    },
]);
const rowSelection = ref<Record<number, boolean>>({});

const actionModalStepConfig = ref([{}, {}, { rejectable: true, submitActionType: ActionType.PutForm }, {}]);
const actionModalInitialState = ref<any>({
    location: null,
    form: null,
    confirmed: false,
    farm: null,
    record_id: null,
    event: null,
    notification: null,
});

const loadingNotificationId = ref<number | null>(null);

async function onOpenNotification(notification: Notification, open: CallableFunction) {
    if (!notification.resource || loadingNotificationId.value !== null) {
        return;
    }

    try {
        loadingNotificationId.value = notification.id;

        const form: Form = await store.dispatch(ActionType.GetForm, {
            form: { form_id: notification.resource.resource_id, nref: notification.resource.reference_id },
        });

        actionModalInitialState.value = {
            ...actionModalInitialState.value,
            form: form || null,
            notification,
        };

        await nextTick();

        open();
    } finally {
        loadingNotificationId.value = null;
    }
}

const { open: openLoadingPageOverlay, close: closeLoadingPageOverlay } = useLoadingPageOverlayState();

async function handleBulkMarkReadStatus(readStatus: boolean) {
    try {
        openLoadingPageOverlay(readStatus ? i18n.global.t('views.notifications.texts.markingAsRead') : i18n.global.t('views.notifications.texts.markingAsUnread'));

        await Promise.all([
            store.dispatch(ActionType.BulkMarkReadStatusNotifications, {
                form: {
                    notificationIds: Object.keys(rowSelection.value).map(Number),
                    readStatus,
                },
            }),
            // eslint-disable-next-line
            new Promise((resolve) => setTimeout(resolve, 1000)),
        ]);

        await queryClient.invalidateQueries({ queryKey: queryKeys.notifications });
    } catch (err) {
        //
    } finally {
        closeLoadingPageOverlay();
    }
}
</script>

<template>
    <div class="space-y-5">
        <Separator />
        <div class="space-y-5">
            <div class="flex items-end justify-between gap-x-3 gap-y-2">
                <div class="flex flex-wrap gap-2 items-center">
                    <SearchInput
                        v-model="searchQuery"
                        :aria-label="$t('views.notifications.fields.search.label')"
                        :placeholder="$t('views.notifications.fields.search.placeholder')"
                        class="w-64"
                        @keydown.enter="fetchSearchQuery = searchQuery"
                        @keyup.esc="fetchSearchQuery = ''"
                    />
                    <div class="cursor-pointer" :class="cn(buttonVariants({ variant: 'outline' }))" @click.self="unread = !unread">
                        <Checkbox id="unread" v-model:checked="unread" />
                        <label for="unread" class="cursor-pointer select-none text-normal font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            {{ $t('views.notifications.fields.unread.label') }}
                        </label>
                    </div>
                    <FadeTransition mode="out-in">
                        <div v-if="isFilterableNotificationGroupsLoading" class="flex gap-2 items-center">
                            <Skeleton class="w-20 h-10 bg-gray-200" />
                            <Skeleton class="w-24 h-10 bg-gray-200" />
                        </div>
                        <div v-else class="flex gap-2 items-center">
                            <DataTableFilter v-model="computedTypes" :options="typeOptions" :title="$t('views.notifications.fields.type.label')" item-value="value" item-label="label" update-only-on-close>
                                <template #item="{ item }">
                                    <div class="flex items-center gap-2">
                                        <Icon :src="item.icon_id" class="w-6 h-6" />
                                        <div>{{ item.label }}</div>
                                    </div>
                                </template>
                            </DataTableFilter>
                            <DataTableFilter
                                v-model="computedStatuses"
                                :options="statusOptions"
                                :title="$t('views.notifications.fields.status.label')"
                                item-value="value"
                                item-label="label"
                                update-only-on-close
                            >
                                <template #item="{ item }">
                                    <StatusBadge :type="item.value" />
                                </template>
                            </DataTableFilter>
                        </div>
                    </FadeTransition>
                </div>
                <DropdownMenu :modal="false">
                    <DropdownMenuTrigger as-child>
                        <Button
                            variant="outline"
                            class="transition-[opacity,background-color]"
                            :class="{ 'visible opacity-100': Object.keys(rowSelection).length > 0, 'invisible opacity-0': Object.keys(rowSelection).length === 0 }"
                        >
                            {{ $t('views.notifications.buttons.manageSelected') }}
                            <span class="bg-gray-100 min-w-5 flex items-center justify-center rounded-[5px] px-1.5 py-px font-semibold">{{ Object.keys(rowSelection).length }}</span>
                            <ChevronDown class="size-4" aria-hidden="true" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent class="min-w-[var(--radix-dropdown-menu-trigger-width)]" align="end">
                        <DropdownMenuItem class="cursor-pointer" @click="handleBulkMarkReadStatus(true)">
                            {{ $t('views.notifications.buttons.markAsRead') }}
                        </DropdownMenuItem>
                        <DropdownMenuItem class="cursor-pointer" @click="handleBulkMarkReadStatus(false)">
                            {{ $t('views.notifications.buttons.markAsUnread') }}
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <Card class="overflow-hidden">
                <DataTable
                    v-model:page="page"
                    v-model:sorting="sorting"
                    v-model:page-size="pageSize"
                    v-model:row-selection="rowSelection"
                    :data="notificationsData"
                    :columns="columns"
                    :loading="isLoading"
                    :page-size-options="pageSizeOptions"
                    :total-pages="pagination.totalPages"
                    :total-rows="pagination.totalRows"
                    server-side
                >
                    <template #item[_type]="{ item }">
                        <div v-if="item._type" class="flex items-center gap-2.5">
                            <Icon :src="item._type?.icon_id" class="w-9 h-9" />
                            <div>{{ item._type?.text }}</div>
                        </div>
                    </template>
                    <template #item[message]="{ item }">
                        <div v-if="item.read">{{ item.message }}</div>
                        <div v-else class="flex items-center gap-2">
                            <div class="shrink-0 h-2 w-2 bg-sky-500 rounded-full" />
                            <span class="font-semibold">{{ item.message }}</span>
                        </div>
                    </template>
                    <template #item[_status]="{ item }">
                        <StatusBadge :notification="item" />
                    </template>
                    <template #item[publish_date]="{ item }">
                        {{ $d(item.publish_date, 'long') }}
                    </template>
                    <template #item[_actions]="{ item }">
                        <div class="flex justify-end">
                            <ActionModal
                                v-if="item.category === 'NfnCategory.Form' && item.resource"
                                :step-config="actionModalStepConfig"
                                :header="{ icon_url: item.resource.icon_url, text: item.resource.text }"
                                :initial-state="actionModalInitialState"
                                :initial-step-index="item.resource?.open_in_edit ? 1 : 2"
                            >
                                <template #default="{ open }">
                                    <Button variant="ghost" size="icon" class="hover:gray-300 rounded-full -mr-1" :disabled="loadingNotificationId !== null" @click="onOpenNotification(item, open)">
                                        <Spinner v-if="loadingNotificationId === item.id" class="h-5 w-5" aria-hidden="true" />
                                        <ChevronRight v-else class="h-5 w-5" aria-hidden="true" />
                                    </Button>
                                </template>
                            </ActionModal>
                            <span v-else class="flex justify-end">
                                <TextNotificationModal :notification="item">
                                    <template #default="scope">
                                        <Button variant="ghost" size="icon" class="hover:gray-300 rounded-full -mr-1" :disabled="loadingNotificationId !== null" @click="scope.open">
                                            <ChevronRight class="h-5 w-5" aria-hidden="true" />
                                        </Button>
                                    </template>
                                </TextNotificationModal>
                            </span>
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
    </div>
</template>
