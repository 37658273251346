<template>
    <div :class="['flatpickr-component-wrapper', `position-${position}`, { 'flatpickr-wrapper-inline': inline }]">
        <!-- Slot with our default input field -->
        <slot></slot>

        <!-- Flatpicker - Hidden to disable its default input field -->
        <div v-if="dashboardLoaded" :class="[{ 'auto-width': autoWidth }, { 'h-0': !inline }]">
            <FlatPickr ref="datePicker" v-model="computedValue" class="w-0 h-0 outline-none relative" :config="resolvedConfig" @on-open="computedShow = true" @on-close="onClose" @keydown.esc.stop></FlatPickr>
        </div>

        <!-- Shadow overlay -->
        <div
            v-show="computedShow && !inline && showOverlay"
            style="background-color: rgba(0, 0, 0, 0.1); transform: translate3d(0, 0, 0)"
            class="cursor-default w-full fixed h-full top-24 left-0 bottom-0 right-0 z-50"
            @click="onClose"
        ></div>
        <div
            v-show="computedShow && !inline && showOverlay"
            style="background-color: rgba(0, 0, 0, 0.1); z-index: 1041; transform: translate3d(0, 0, 0)"
            class="cursor-default h-24 fixed top-0 left-0 right-0"
            @click="onClose"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FlatPickr from 'vue-flatpickr-component';
import { Danish } from 'flatpickr/dist/l10n/da.js';
import { english } from 'flatpickr/dist/l10n/default.js';
import { Swedish } from 'flatpickr/dist/l10n/sv.js';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import 'flatpickr/dist/flatpickr.css';
import { store } from '@/plugins/store';
import { IconSource } from '@/types';

export default defineComponent({
    components: { FlatPickr },
    props: {
        mode: {
            type: String,
            default: 'single',
        },
        show: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: {},
        },
        autoWidth: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        wrapperClass: {
            type: String,
            default: 'flatpickr-component-wrapper',
        },
        disableMonths: {
            type: Object as () => null | { [key: string]: number[] }, // e.g. { 2020: [2, 4, 5] }
            default: null,
        },
        position: {
            type: String,
            default: 'below',
            validator: (value: string) => ['below', 'above'].includes(value),
        },
        showOverlay: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:show', 'update:value'],
    computed: {
        dashboardLoaded() {
            return store.state.dashboardLoaded;
        },
        computedValue: {
            get(): string {
                return this.$props.value;
            },
            set(newValue: string) {
                this.$emit('update:value', newValue);
            },
        },
        computedShow: {
            get(): boolean {
                return this.$props.show;
            },
            set(newExpandState: boolean) {
                this.$emit('update:show', newExpandState);
            },
        },
        resolvedConfig() {
            const rightSource = store.state.icons ? store.state.icons[IconSource.Right] : '';
            const config = {
                mode: this.$props.mode,
                wrap: false,
                dateFormat: 'Z',
                conjuntion: ' - ',
                weekNumbers: true,
                inline: this.$props.inline,
                static: true,
                position: this.$props.position,
                errorHandler: () => {},
                nextArrow: `
                <div class="flatpickr-custom-arrow right">
                    <div style="border-radius: 50%; padding: 0.35rem;" class="bg-primary-500">
                        <img class="m-auto w-2 h-2" src="${rightSource}" />
                    </div>
                </div>
                `,
                prevArrow: `
                <div class="flatpickr-custom-arrow left">
                    <div style="border-radius: 50%; padding: 0.35rem;" class="bg-primary-500">
                        <img style="transform: rotate(180deg);" class="m-auto w-2 h-2" src="${rightSource}" />
                    </div>
                </div>
                `,
            } as any;

            Object.assign(config, this.$props.config);

            if (this.disabled) {
                Object.assign(config, { clickOpens: false });
            }

            if (this.disableMonths) {
                Object.assign(config, { onYearChange: this.checkDisabledMonths, onOpen: this.checkDisabledMonths });
            }

            switch (this.$i18n.locale) {
                case 'da': {
                    config.locale = Danish;
                    break;
                }
                case 'sv': {
                    config.locale = Swedish;
                    break;
                }
                case 'es': {
                    config.locale = Spanish;
                    break;
                }
                case 'pt': {
                    config.locale = Portuguese;
                    break;
                }
                default: {
                    english.firstDayOfWeek = 1;
                    config.locale = english;
                    break;
                }
            }

            return config;
        },
    },
    watch: {
        show(newValue: boolean) {
            if (newValue) {
                (this.$refs.datePicker as any).fp.open();

                if (this.$props.mode === 'range') {
                    (this.$refs.datePicker as any).fp.l10n.rangeSeparator = ' - ';
                }
                if (this.config.disableMonths) {
                    this.checkDisabledMonths();
                }
            } else {
                (this.$refs.datePicker as any).fp.close();
            }
        },
    },
    mounted() {
        if (this.config.enableTime) {
            const inputs = this.$el.querySelectorAll('.numInput, .flatpickr-time');
            (Array.from(inputs) as HTMLElement[]).forEach((currentTimeElement) => {
                currentTimeElement.onkeydown = (event) => {
                    if (event.key === 'Escape' || event.key === 'Esc') {
                        this.computedShow = false;
                        event.stopPropagation();
                    }
                };
            });
        }
    },
    methods: {
        onClose() {
            this.computedShow = false;
        },
        checkDisabledMonths() {
            if (!this.$props.disableMonths) {
                return;
            }
            const flatpickrInstance = (this.$refs.datePicker as any).fp;
            const disabledMonthsInCurrentYear = this.$props.disableMonths[`${flatpickrInstance.currentYear}`];
            const rContainer = flatpickrInstance.rContainer;
            const monthsContainer = rContainer.children[0];
            const monthElements = [...monthsContainer.children];
            monthElements.forEach((el) => {
                el.removeEventListener('keydown', this.disableEnter);
            });
            if (disabledMonthsInCurrentYear) {
                disabledMonthsInCurrentYear.forEach((monthIndex) => {
                    setTimeout(() => {
                        const monthEl = monthElements[monthIndex];
                        monthEl.classList.add('disabled');
                        monthEl.removeEventListener('keydown', this.disableEnter);
                        monthEl.addEventListener('keydown', this.disableEnter);
                    }, 100);
                });
            }
        },
        disableEnter(event: any) {
            if (event.keyCode === 13) {
                event.preventDefault();
                event.stopPropagation();
            }
        },
    },
});
</script>
<style>
/* Add these new styles */
.flatpickr-component-wrapper .flatpickr-calendar {
    transform-origin: center bottom !important;
    margin-bottom: 8px !important;
}

.flatpickr-component-wrapper .flatpickr-calendar.open {
    transform: translate3d(0px, -100%, 0px) !important;
}

/* Align the prev/next arrows with the month/year picker */
.flatpickr-component-wrapper .flatpickr-months .flatpickr-prev-month,
.flatpickr-component-wrapper .flatpickr-months .flatpickr-next-month {
    position: inherit !important;
}

.flatpickr-component-wrapper .flatpickr-months {
    padding: 0px 30px 20px 30px;
}

.flatpickr-component-wrapper .flatpickr-wrapper {
    position: absolute !important;
    z-index: 900;
}

.flatpickr-component-wrapper.flatpickr-wrapper-inline .flatpickr-wrapper {
    position: static !important;
}

/* Allows for more widespace without cutting content off. Requires the autoWidth prop */
.flatpickr-component-wrapper .auto-width .flatpickr-calendar {
    width: auto !important;
    padding: 20px 10px 10px 10px;
}

.flatpickr-wrapper-inline .flatpickr-calendar {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

/* More white space in the calendar dropdown */
.flatpickr-component-wrapper .flatpickr-innerContainer {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

/* Removes top arrow to make it more consistent with our dropdowns */
.flatpickr-component-wrapper .flatpickr-calendar:before,
.flatpickr-component-wrapper .flatpickr-calendar:after {
    display: none;
}

/* Fattens the selected month/year */
.flatpickr-component-wrapper .flatpickr-monthDropdown-months,
.flatpickr-component-wrapper .cur-year {
    font-weight: 500 !important;
}

.flatpickr-component-wrapper .flatpickr-current-month {
    display: flex !important;
}

.flatpickr-component-wrapper .flatpickr-current-month input.cur-year {
    padding-top: 2px;
}
.flatpickr-component-wrapper .flatpickr-current-month .flatpickr-monthDropdown-months {
    padding-bottom: 2px;
}

/* hides the 'WK' column header for weekdays */
.flatpickr-component-wrapper .flatpickr-weekwrapper .flatpickr-weekday {
    opacity: 0;
}

.flatpickr-component-wrapper .flatpickr-day.selected.startRange,
.flatpickr-component-wrapper .flatpickr-day.startRange.startRange,
.flatpickr-component-wrapper .flatpickr-day.endRange.startRange {
    border-radius: 5px 0 0 5px !important;
}

.flatpickr-component-wrapper .flatpickr-day.selected.endRange,
.flatpickr-component-wrapper .flatpickr-day.startRange.endRange,
.flatpickr-component-wrapper .flatpickr-day.endRange.endRange {
    border-radius: 0 5px 5px 0 !important;
}

/* Overrides the blue colors used by Flatpickr with bg-primary-500 */
.flatpickr-component-wrapper .flatpickr-day.selected,
.flatpickr-component-wrapper .flatpickr-day.startRange,
.flatpickr-component-wrapper .flatpickr-day.endRange,
.flatpickr-component-wrapper .flatpickr-day.selected.inRange,
.flatpickr-component-wrapper .flatpickr-day.startRange.inRange,
.flatpickr-component-wrapper .flatpickr-day.endRange.inRange,
.flatpickr-component-wrapper .flatpickr-day.selected:focus,
.flatpickr-component-wrapper .flatpickr-day.startRange:focus,
.flatpickr-component-wrapper .flatpickr-day.endRange:focus,
.flatpickr-component-wrapper .flatpickr-day.selected:hover,
.flatpickr-component-wrapper .flatpickr-day.startRange:hover,
.flatpickr-component-wrapper .flatpickr-day.endRange:hover,
.flatpickr-component-wrapper .flatpickr-day.selected.prevMonthDay,
.flatpickr-component-wrapper .flatpickr-day.startRange.prevMonthDay,
.flatpickr-component-wrapper .flatpickr-day.endRange.prevMonthDay,
.flatpickr-component-wrapper .flatpickr-day.selected.nextMonthDay,
.flatpickr-component-wrapper .flatpickr-day.startRange.nextMonthDay,
.flatpickr-component-wrapper .flatpickr-day.endRange.nextMonthDay {
    @apply bg-primary-500 border-primary-500;
    color: black !important;
}
.flatpickr-component-wrapper .fllatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-component-wrapper .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-component-wrapper .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 #e6e6e6 !important;
    box-shadow: -10px 0 0 #e6e6e6 !important;
}
.flatpickr-component-wrapper .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-component-wrapper .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
    display: inherit !important;
    opacity: 0;
}

/* Position above styles */
.flatpickr-component-wrapper.position-above .flatpickr-calendar {
    transform-origin: center bottom !important;
    margin-bottom: 8px !important;
}

.flatpickr-component-wrapper.position-above .flatpickr-calendar.open {
    transform: translate3d(0px, -100%, 0px) !important;
}

/* Position below styles - this is the default Flatpickr behavior */
.flatpickr-component-wrapper.position-below .flatpickr-calendar {
    transform-origin: center top !important;
    margin-top: 8px !important;
}

.flatpickr-component-wrapper.position-below .flatpickr-calendar.open {
    transform: translate3d(0px, 0%, 0px) !important;
}
</style>
