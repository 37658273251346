<template>
    <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="opacity-0"
        leave-to-class="opacity-0"
        appear
        mode="out-in"
    >
        <div v-if="(persistentLoading && loading) || (!computedData && loading)" class="flex items-center justify-center px-4 py-16">
            <Spinner class="h-10 w-10" />
        </div>
        <div v-else-if="noDataText && computedData && (computedData as Dashboard).items?.length === 0" class="py-14 text-center px-4 text-gray-400 text-lg">{{ noDataText }}</div>
        <div v-else-if="computedData">
            <div v-if="computedData.sections?.length" class="space-y-6">
                <div v-for="(section, sectionIndex) in computedData.sections" :key="`section-${sectionIndex}`" class="border border-gray-300 rounded-lg px-4 py-5 space-y-5">
                    <div class="flex items-center justify-between gap-x-3">
                        <div class="flex items-center gap-x-3">
                            <div v-if="section.icon">
                                <Icon :src="section.icon.icon_id" :height="section.icon.height" :style="{ height: `${section.icon.height * 28}px` }" />
                            </div>
                            <div class="space-y-1">
                                <div v-if="section.title" class="font-bold text-lg leading-tight">
                                    {{ section.title }}
                                </div>
                                <div v-if="section.subtitle" class="text-gray-500 leading-tight">
                                    {{ section.subtitle }}
                                </div>
                            </div>
                        </div>
                        <div v-if="section.is_collapsible" class="flex-shrink-0">
                            <button type="button" class="p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200" @click="toggleSectionCollapse(sectionIndex)">
                                <Icon class="h-4 w-4 transform transition-transform rotate-90 cursor-pointer" :class="{ 'rotate-[270deg]': !section.is_collapsed }" :src="IconSource.Right"></Icon>
                            </button>
                        </div>
                    </div>
                    <ExpandCollapseTransition>
                        <div v-if="!section.is_collapsed || !section.is_collapsible" class="grid grid-cols-2 items-stretch gap-4">
                            <GenericDashboardElement
                                v-for="item in (section as DashboardSectionWithItems).items"
                                :key="`${item.item_no}-${item.width}`"
                                :item="item"
                                :loading="loadingState[item.item_no]"
                                @update:options="onChartOptionsUpdate($event, item.item_no)"
                                @update:chart="setChartInstance($event, item.item_no)"
                            />
                        </div>
                    </ExpandCollapseTransition>
                </div>
            </div>
            <div v-else class="grid grid-cols-2 items-stretch gap-4">
                <GenericDashboardElement
                    v-for="item in (computedData as Dashboard).items"
                    :key="`${item.item_no}-${item.width}`"
                    :item="item"
                    :loading="loadingState[item.item_no]"
                    @update:options="onChartOptionsUpdate($event, item.item_no)"
                    @update:chart="setChartInstance($event, item.item_no)"
                />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { merge } from 'lodash-es';
import * as Highcharts from 'highcharts';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Dashboard, DashboardBurgerMenuOption, DashboardItem, DashboardSectionWithItems, IconSource } from '@/types';
import Spinner from '@/components/ui/Spinner.vue';
import GenericDashboardElement from './GenericDashboardElement.vue';
import Icon from '@/components/icons/Icon.vue';
import ExpandCollapseTransition from '../ExpandCollapseTransition.vue';
import useDefaultChartOptions from '@/components/charts/composables/useDefaultChartOptions';

interface Props {
    data: Dashboard | null | undefined;
    loading: boolean;
    fetchParams?: Record<string, any>;
    fetchActionType: ActionType;
    persistentLoading?: boolean;
    noDataText?: string;
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
    fetchParams: () => ({}),
    persistentLoading: false,
    noDataText: '',
});
const emit = defineEmits<{
    'update:item': [payload: { item: DashboardItem; sectionIndex?: number }];
    'update:section-collapse': [payload: { sectionIndex: number; isCollapsed: boolean }];
}>();

const chartInstances = ref<Record<number, Highcharts.Chart>>({});
const isChartsUpdating = ref(false);

function setChartInstance(instance: Highcharts.Chart, itemNo: number) {
    chartInstances.value[itemNo] = instance;
}

function setChartExtremes(event: any, updatedChartId: number) {
    if (event.trigger !== 'navigator' || isChartsUpdating.value) {
        return;
    }

    isChartsUpdating.value = true;

    for (const chartId in chartInstances.value) {
        if (chartId.toString() !== updatedChartId.toString() && chartInstances.value[chartId]) {
            chartInstances.value?.[chartId]?.xAxis?.[0]?.setExtremes(event.min, event.max);
            chartInstances.value?.[chartId]?.redraw();
        }
    }

    isChartsUpdating.value = false;
}

function handleLegendClick(legendId: string) {
    Object.values(chartInstances.value).forEach((chart) => {
        const series = chart.series?.find((s: any) => s.options && s.options.id === legendId);

        if (series) {
            if (series.visible) {
                series.hide();
            } else {
                series.show();
            }
        }
    });
}

function toggleSectionCollapse(sectionIndex: number) {
    const clonedData = props.data;

    if (!clonedData?.sections) {
        return;
    }

    const section = (clonedData.sections as DashboardSectionWithItems[])[sectionIndex];

    if (!section) {
        return;
    }

    emit('update:section-collapse', { sectionIndex, isCollapsed: !section.is_collapsed });
}

const loadingState = ref<Record<number, boolean>>([]);

async function onChartOptionsUpdate(payload: { options: DashboardBurgerMenuOption[]; hasChanged: boolean }, itemNo: number, sectionIndex?: number) {
    if (!payload.hasChanged) {
        return;
    }

    try {
        loadingState.value[itemNo] = true;

        const requestOptions = {
            ...props.fetchParams,
            filterItems: itemNo.toString(),
            filterOptions: payload.options.map((option) => option.query_parameter).join(','),
            ignoreCache: true,
            cacheResponse: false,
        };

        const data = await store.dispatch(props.fetchActionType, { options: requestOptions });

        emit('update:item', { item: data.items[0], sectionIndex });
    } finally {
        loadingState.value[itemNo] = false;
    }
}

const DEFAULT_CHART_HEIGHT = 300; // pixels

function getChartItemWithOptions(item: DashboardItem) {
    const defaultOptions = useDefaultChartOptions(item.chart_options, null, item.apply_custom_tooltip);

    if (item.chart_options.xAxis?.[0]) {
        item.chart_options.xAxis[0].events = {
            setExtremes: (event: Highcharts.AxisSetExtremesEventCallbackFunction) => setChartExtremes(event, item.item_no),
        };
    }

    const mergedOptions = merge({}, defaultOptions.value, {
        chart: {
            height: DEFAULT_CHART_HEIGHT * (item.height || 1),
        },
    });

    mergedOptions.plotOptions = mergedOptions.plotOptions || {};
    mergedOptions.plotOptions.series = mergedOptions.plotOptions.series || {};
    mergedOptions.plotOptions.series.events = {
        ...(defaultOptions.value?.plotOptions?.series?.events || {}),
        legendItemClick(event: Highcharts.SeriesLegendItemClickEventObject) {
            const legendId = (this as any).options.id;

            if (!legendId.includes('generic-chart-modal')) {
                handleLegendClick(legendId);
                event?.preventDefault();
            }
        },
    };

    return {
        ...item,
        chart_options: mergedOptions,
    };
}

const computedData = computed(() => {
    if (!props.data) {
        return null;
    }

    const items = props.data.items.map((item) => {
        if (item.type === 'chart' && item.chart_options) {
            return getChartItemWithOptions(item);
        }

        return item;
    });

    if (props.data.sections?.length) {
        const sections = props.data.sections.map((section) => ({
            ...section,
            items: items.filter((item) => section.item_numbers?.includes(item.item_no)),
        }));

        return {
            sections,
        } as { sections: DashboardSectionWithItems[] };
    }

    return {
        ...store.state.dashboardOverview,
        items,
    };
});
</script>
