<template>
    <Modal close-esc-event="keydown" :close-button="false" :modal-class="`m-auto border-0 w-256 xl:w-288 max-w-6xl transition-width duration-500`" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4">
                <MultiStepFormHeaderIcon :icon-source="IconSource.Food" :is-final-step="false"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.mixes.editMixModal.mixRawMaterials.header').replace('$MIX_NAME', mix.name) }}</h2>
                <div>
                    <HomeMixRawMaterialsTable :value="mix">
                        <template #sum_name="{ sumHeader }">
                            <td :key="sumHeader" style="break" class="flex flex-1 px-2 my-auto word-break">
                                {{ $t('views.feed.mixes.homeMixRawMaterialsTable.total') }}
                            </td>
                        </template>

                        <template #price="{ header, item }">
                            <td :key="header.value" class="flex flex-1 px-2 my-auto word-break">
                                {{ $n(getPrice(item.material)?.price || 0, 'price') }}
                            </td>
                        </template>

                        <template #sum_components="{ sumHeader }">
                            <td :key="sumHeader" class="flex flex-1 px-2 my-auto word-break">
                                <span class="w-20">
                                    {{ $n(mixComponentsSum) }}
                                </span>
                            </td>
                        </template>

                        <template #sum_price="{ sumHeader }">
                            <td :key="sumHeader" class="flex flex-1 px-2 my-auto word-break">
                                <span class="w-20">
                                    {{ $n(getPrice(mix)?.price || 0, 'price') }}
                                </span>
                            </td>
                        </template>
                    </HomeMixRawMaterialsTable>
                </div>
                <div class="flex justify-end my-4 space-x-4">
                    <div class="flex border-2 border-opacity-10 rounded-lg space-x-4 px-4 box-content">
                        <span class="my-auto py-2 space-x-2">
                            <label for="surcharge">{{ $t('views.feed.mixes.editMixModal.mixRawMaterials.surcharge') }}:</label>
                            <SimpleInput id="surcharge" :value="$n(mix.surcharge || 0, 'price')" disabled class="w-16" type="number" name="surcharge" step="any"></SimpleInput>
                        </span>
                        <span class="my-auto font-medium py-2">
                            {{ `${totalPriceDisplayText}: ${$n((getPrice(mix).price || 0) + (mix.surcharge || 0), 'price')}` }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Mix } from '@/types';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import HomeMixRawMaterialsTable from '@/views/dashboard/feed/feedMixes/HomeMixRawMaterialsTable.vue';
import useGetPrice from '@/components/common/composables/useGetPrice';
import SimpleInput from '@/components/ui/SimpleInput.vue';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, HomeMixRawMaterialsTable, CloseModalButton, SimpleInput },
    props: {
        mix: {
            type: Object as () => Mix,
            required: true,
        },
    },
    emits: ['submit'],
    computed: {
        mixComponentsSum(): number {
            return this.$props.mix.components.reduce((accum, current) => accum + current.admixture_percentage, 0);
        },
        totalPriceDisplayText() {
            if (store.getters.priceCurrencyPerWeightUnitDisplayText) {
                return this.$t('views.feed.mixes.editMixModal.mixRawMaterials.totalPriceUnit').replace('%VALUE%', store.getters.priceCurrencyPerWeightUnitDisplayText);
            }

            return this.$t('views.feed.mixes.editMixModal.mixRawMaterials.totalPrice');
        },
    },
    methods: {
        getPrice: useGetPrice,
    },
});
</script>
