import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DialogTitle, type DialogTitleProps, useForwardProps } from 'radix-vue';
import { computed, type HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'DialogTitle',
  props: {
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DialogTitle), _mergeProps(_unref(forwardedProps), {
    class: _unref(cn)('text-2xl text-center font-semibold leading-none tracking-tight break-all', props.class)
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})