import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute -mt-2 right-5 top-1/2" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-end py-2 px-3"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    modelValue: _ctx.listboxValue,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.listboxValue) = $event))
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, {
          class: "overflow-hidden relative w-full shadow-none",
          disabled: _ctx.disabled,
          onClick: _ctx.onListboxButtonClicked,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.buttonFocused = true)),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.buttonFocused = false)),
          onKeydown: [
            _withKeys(_ctx.onListboxButtonClicked, ["enter"]),
            _withKeys(_ctx.onListboxButtonClicked, ["space"])
          ]
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "trigger", {
              expanded: _ctx.expandListbox,
              displayValue: _ctx.inputDisplayValue,
              label: _ctx.label,
              disabled: _ctx.disabled
            }, () => [
              _createVNode(_component_CustomInput, {
                ref: "input-field",
                value: _ctx.inputDisplayValue,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputDisplayValue) = $event)),
                "show-required-indication": _ctx.showRequiredIndication,
                active: _ctx.expandListbox,
                required: _ctx.required,
                prepend: _ctx.prepend,
                "focus-highlight": _ctx.buttonFocused,
                rules: _ctx.rules,
                "input-type": "text",
                label: _ctx.label,
                details: _ctx.details,
                disabled: _ctx.disabled,
                "error-state": _ctx.validInput === false,
                "tab-index": "-1",
                readonly: "",
                cursor: "cursor-pointer"
              }, null, 8, ["value", "show-required-indication", "active", "required", "prepend", "focus-highlight", "rules", "label", "details", "disabled", "error-state"]),
              _withDirectives(_createElementVNode("span", _hoisted_2, [
                _createVNode(_component_ExpandIcon, {
                  expand: _ctx.expandListbox,
                  color: "black"
                }, null, 8, ["expand"])
              ], 512), [
                [_vShow, !_ctx.disabled]
              ])
            ])
          ]),
          _: 3
        }, 8, ["disabled", "onClick", "onKeydown"]),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-100 ease-out",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([[_ctx.dropdownHeightClass], "absolute -mt-4 top-full left-0 w-full bg-white border z-30 rounded overflow-y-auto"])
            }, [
              (_ctx.showSelectAll)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("button", {
                      class: "text-sky-500 hover:text-sky-700 text-sm transition-colors underline",
                      style: {"text-underline-offset":"2px"},
                      onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleSelectAll && _ctx.toggleSelectAll(...args)), ["prevent","stop"]))
                    }, _toDisplayString(_ctx.isAllSelected ? _ctx.$t('global.uiElements.multiSelect.deselectAllButtonLabel') : _ctx.$t('global.uiElements.multiSelect.selectAllButtonLabel')), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_ListboxOptions, {
                static: "",
                class: "outline-none",
                onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.expandListbox = false), ["stop"]), ["esc"]))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item[_ctx.itemValue]
                    }, [
                      _createVNode(_component_ListboxOption, {
                        value: _ctx.flatValues ? item[_ctx.itemValue] : item,
                        disabled: item.disabled
                      }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("button", {
                            type: "button",
                            class: _normalizeClass([[
                                        {
                                            'bg-primary-300 bg-opacity-40': _ctx.isSelected(item),
                                            'hover:bg-gray-100': !_ctx.isSelected(item) && !item.disabled,
                                            'bg-gray-100': active,
                                            'text-gray-400': item.disabled,
                                        },
                                        item.disabled ? 'cursor-default' : 'cursor-pointer',
                                    ], "p-3 h-full text-left w-full flex"]),
                            disabled: item.disabled
                          }, [
                            _renderSlot(_ctx.$slots, "item", {
                              item: item,
                              text: item[_ctx.itemText]
                            }, () => [
                              _createElementVNode("span", _hoisted_5, _toDisplayString(item[_ctx.itemText]), 1)
                            ]),
                            _createElementVNode("span", {
                              class: _normalizeClass(["my-auto justify-self-end", { 'pointer-events-none': item.disabled }])
                            }, [
                              _createVNode(_component_CustomCheckbox, {
                                checked: _ctx.isSelected(item),
                                disabled: item.disabled,
                                class: _normalizeClass({ 'opacity-40': item.disabled }),
                                "onUpdate:checked": ($event: any) => (_ctx.listboxValue = item)
                              }, null, 8, ["checked", "disabled", "class", "onUpdate:checked"])
                            ], 2)
                          ], 10, _hoisted_4)
                        ]),
                        _: 2
                      }, 1032, ["value", "disabled"]),
                      (_ctx.groups && item[_ctx.groupKey] && item[_ctx.groupKey].length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item[_ctx.groupKey], (child) => {
                              return (_openBlock(), _createBlock(_component_ListboxOption, {
                                key: _ctx.groupItemValue ? child[_ctx.groupItemValue] : child[_ctx.itemValue],
                                value: child,
                                disabled: child.disabled
                              }, {
                                default: _withCtx(({ active }) => [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: _normalizeClass([[
                                            {
                                                'bg-primary-300 bg-opacity-40': _ctx.isSelected(child, true),
                                                'hover:bg-gray-100': !_ctx.isSelected(child, true) && !child.disabled,
                                                'bg-gray-100': active,
                                                'text-gray-400': child.disabled,
                                            },
                                            child.disabled ? 'cursor-default' : 'cursor-pointer',
                                        ], "p-3 pl-10 h-full text-left w-full flex"]),
                                    disabled: child.disabled
                                  }, [
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(child[_ctx.groupItemText || _ctx.itemText]), 1),
                                    _createElementVNode("span", {
                                      class: _normalizeClass(["my-auto justify-self-end", { 'pointer-events-none': child.disabled }])
                                    }, [
                                      _createVNode(_component_CustomCheckbox, {
                                        checked: _ctx.isSelected(child, true),
                                        disabled: child.disabled,
                                        class: _normalizeClass({ 'opacity-40': child.disabled }),
                                        "onUpdate:checked": ($event: any) => (_ctx.listboxValue = child)
                                      }, null, 8, ["checked", "disabled", "class", "onUpdate:checked"])
                                    ], 2)
                                  ], 10, _hoisted_7)
                                ]),
                                _: 2
                              }, 1032, ["value", "disabled"]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _: 3
              })
            ], 2), [
              [_vShow, _ctx.expandListbox]
            ])
          ]),
          _: 3
        })
      ])), [
        [_directive_click_outside, _ctx.onClickOutside]
      ])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}