import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative w-full" }
const _hoisted_2 = { class: "bg-white" }
const _hoisted_3 = { class: "text-left pl-4 py-4 text-gray-400" }
const _hoisted_4 = { class: "text-left pr-4 py-4 text-gray-400" }
const _hoisted_5 = { class: "text-left pr-4 py-4 text-gray-400" }
const _hoisted_6 = { class: "text-left pr-4 py-4 text-gray-400" }
const _hoisted_7 = { class: "pl-4 pr-4 pt-4" }
const _hoisted_8 = { class: "w-40 pr-4 pt-4" }
const _hoisted_9 = { class: "w-40 pr-4 pt-4" }
const _hoisted_10 = { class: "pr-4 pt-4" }
const _hoisted_11 = {
  colspan: "4",
  class: "pl-9 pr-4 py-0"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "text-red-900 pb-4" }

import { ref, watch } from 'vue';
import { VisuallyHidden } from 'radix-vue';
import { addWeeks, format, parseISO } from 'date-fns';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import DatePicker from '@/components/ui/DatePicker.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import { StudyPeriod } from '@/types';
import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import { i18n } from '@/plugins/internationalization/i18n';

interface EditTeamState {
    id: number;
    locationNameAugmented: string;
    name: string;
    startDate: string;
    endDate: string;
    isFavorite: boolean;
    error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditTeamsModal',
  props: /*@__PURE__*/_mergeModels({
    teams: {}
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const queryClient = useQueryClient();
const toast = useToast();

const showModal = _useModel<boolean>(__props, 'modelValue');

function getInitialState() {
    return props.teams.map<EditTeamState>((team) => ({
        id: team.id,
        locationNameAugmented: team.location_name_augmented,
        name: team.name,
        startDate: team.start ? new Date(team.start as string).toISOString() : new Date().toISOString(),
        endDate: team.end ? new Date(team.end as string).toISOString() : addWeeks(new Date(), 1).toISOString(),
        isFavorite: team.is_favorite,
    }));
}

function getPayload() {
    return state.value.map((team) => ({
        id: team.id,
        name: team.name,
        start: format(parseISO(team.startDate), 'yyyy-MM-dd'),
        end: format(parseISO(team.endDate), 'yyyy-MM-dd'),
        is_favorite: team.isFavorite,
    }));
}

const errors = ref<Record<number, string>>({});

function removeErrorMessage(id: number) {
    const { [id]: _, ...rest } = errors.value;
    errors.value = rest;
}

async function handleEdit() {
    try {
        if (isLoading.value) {
            return;
        }

        errors.value = {};

        isLoading.value = true;

        await ApiClient.send(
            'put',
            '/monitor/study-periods/bulk-update',
            {
                skipHandleError: true,
            },
            {
                study_periods: getPayload(),
            }
        );

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        showModal.value = false;

        toast.success(i18n.global.t('views.monitoring.teamOverview.modals.editTeams.successMessages.teamsWereEdited'));
    } catch (err) {
        // @ts-ignore
        errors.value = (err?.data?.conflicts || []).reduce((acc: Record<number, string>, c: any) => {
            acc[c.id] = c.conflict_message;
            return acc;
        }, {});

        toast.error(i18n.global.t('views.monitoring.teamOverview.modals.editTeams.errors.couldNotEditTeams'));
    } finally {
        isLoading.value = false;
    }
}
const state = ref<EditTeamState[]>(getInitialState());

const isLoading = ref(false);

watch(showModal, (value) => {
    if (value) {
        state.value = getInitialState();
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    modelValue: showModal.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    disabled: isLoading.value,
    class: "pb-4 px-0 sm:max-w-[1200px]"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(DialogHeader), { class: "pt-8 pb-4 px-8" }, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogTitle), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.title')), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(VisuallyHidden), null, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogDescription), { class: "pt-4" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.description')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_2, [
            _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.table.headings.name')), 1),
            _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.table.headings.startDate')), 1),
            _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.table.headings.endDate')), 1),
            _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.table.headings.location')), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value, (team, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: team.id
            }, [
              _createElementVNode("tr", {
                class: _normalizeClass(["transition-colors duration-200", { 'bg-gray-50': index % 2 === 0 && !errors.value[team.id], 'bg-red-100': errors.value[team.id] }])
              }, [
                _createElementVNode("td", _hoisted_7, [
                  _createVNode(CustomInput, {
                    value: (state.value[index].name as any),
                    "onUpdate:value": [($event: any) => (((state.value[index].name as any)) = $event), ($event: any) => (removeErrorMessage(team.id))],
                    rules: [{ validate: (value) => value.length > 0, message: '' }],
                    required: false
                  }, null, 8, ["value", "onUpdate:value", "rules"])
                ]),
                _createElementVNode("td", _hoisted_8, [
                  _createVNode(DatePicker, {
                    value: state.value[index].startDate,
                    "onUpdate:value": [($event: any) => ((state.value[index].startDate) = $event), ($event: any) => (removeErrorMessage(team.id))],
                    class: "w-44",
                    resetable: false,
                    "show-overlay": false
                  }, null, 8, ["value", "onUpdate:value"])
                ]),
                _createElementVNode("td", _hoisted_9, [
                  _createVNode(DatePicker, {
                    value: state.value[index].endDate,
                    "onUpdate:value": [($event: any) => ((state.value[index].endDate) = $event), ($event: any) => (removeErrorMessage(team.id))],
                    class: "w-44",
                    resetable: false,
                    "show-overlay": false
                  }, null, 8, ["value", "onUpdate:value"])
                ]),
                _createElementVNode("td", _hoisted_10, [
                  _createVNode(CustomInput, {
                    value: (state.value[index].locationNameAugmented as any),
                    "onUpdate:value": ($event: any) => (((state.value[index].locationNameAugmented as any)) = $event),
                    disabled: ""
                  }, null, 8, ["value", "onUpdate:value"])
                ])
              ], 2),
              _createElementVNode("tr", {
                class: _normalizeClass(["transition-colors duration-200", { 'bg-gray-50': index % 2 === 0 && !errors.value[team.id], 'bg-red-100': errors.value[team.id] }])
              }, [
                _createElementVNode("td", _hoisted_11, [
                  _createVNode(ExpandTransition, null, {
                    default: _withCtx(() => [
                      (errors.value[team.id])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("p", _hoisted_13, _toDisplayString(errors.value[team.id]), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ], 2)
            ], 64))
          }), 128))
        ])
      ]),
      _createVNode(_unref(DialogFooter), { class: "inline-flex mx-auto pb-4 px-8" }, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogClose), { "as-child": "" }, {
            default: _withCtx(() => [
              _createVNode(CustomButton, {
                disabled: isLoading.value,
                "color-preset": "white",
                class: "min-w-56 max-w-full"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.buttons.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(CustomButton, {
            loading: isLoading.value,
            class: "min-w-56 max-w-full",
            onClick: handleEdit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeams.buttons.save')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "disabled"]))
}
}

})