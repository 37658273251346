import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end flex-wrap gap-4" }
const _hoisted_2 = { class: "bg-gray-100 min-w-5 flex items-center justify-center rounded-[5px] px-1.5 py-px font-semibold" }
const _hoisted_3 = { class: "text-red-500" }
const _hoisted_4 = {
  for: "unread",
  class: "cursor-pointer select-none text-normal font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
}
const _hoisted_5 = {
  key: 0,
  class: "flex items-center justify-center px-4 py-16"
}
const _hoisted_6 = {
  key: "content",
  class: "max-w-full space-y-5 mt-12"
}
const _hoisted_7 = { class: "flex items-center justify-between space-x-4" }
const _hoisted_8 = { class: "flex items-center space-x-3" }
const _hoisted_9 = { class: "size-6" }
const _hoisted_10 = { class: "font-bold text-lg" }
const _hoisted_11 = { class: "text-sm text-gray-500" }
const _hoisted_12 = { class: "pr-1" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "mt-5 space-y-5" }
const _hoisted_15 = {
  key: 2,
  class: "flex items-center justify-center px-4 py-16"
}
const _hoisted_16 = { class: "text-gray-500 text-center" }

import { parseISO } from 'date-fns';
import { computed, ref, nextTick, provide, watch } from 'vue';
import { ChevronDown } from 'lucide-vue-next';
import { refDebounced } from '@vueuse/core';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { IconSource, StudyPeriod, StudyPeriodBarnResponseEntry } from '@/types';
import { store } from '@/plugins/store';
import { useBarnStudyPeriods, useStudyPeriodGrowthIntervals } from '@/plugins/store/actions/queries/study-periods';
import BarnSection from './BarnSection.vue';
import DeleteTeamModal from './DeleteTeamModal.vue';
import CreateTeamModal from './CreateTeamModal.vue';
import EditTeamModal from './EditTeamModal.vue';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { buttonVariants, Button } from '@/components/ui/button';
import PeriodPicker from '@/components/ui/PeriodPicker.vue';
import { SearchInput } from '@/components/ui/input';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import EditTeamsModal from './EditTeamsModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const sitesAreLoaded = computed(() => store.state.sitesAreLoaded);
const barnIds = computed(() => store.getters.getSelectedSiteIds);
const selectedPeriod = computed(() => store.getters.getSelectedPeriod);
const isQueryEnabled = computed(() => barnIds.value.length > 0);

const { data: studyPeriodBarns, isLoading: isStudyPeriodsLoading } = useBarnStudyPeriods({ barnIds, selectedPeriod }, { enabled: isQueryEnabled });
const isLoading = computed(() => isStudyPeriodsLoading.value || !sitesAreLoaded.value);

const expandedSelection = ref<Record<number, boolean>>({});
const showOnlyFavorites = ref(false);
const searchQuery = ref('');
const debouncedSearchQuery = refDebounced(searchQuery, 300, { maxWait: 1000 });

const data = computed(() => {
    const farms: {
        farm_id: number;
        farm_name: string;
        barns: (StudyPeriodBarnResponseEntry & { farm_id: number; barn_name: string })[];
        teamCount: number;
    }[] = [];

    const barns =
        studyPeriodBarns.value
            ?.map((b) => {
                const barn = store.state.barns.find((stateBarn) => stateBarn.barn_id === b.barn_id);

                if (!barn) {
                    return null;
                }

                const studyPeriods = b.study_periods
                    ?.filter((sp) => (showOnlyFavorites.value ? sp.is_favorite : true))
                    .map((sp) => ({
                        ...sp,
                        start: parseISO(sp.start as string),
                        end: parseISO(sp.end as string),
                    }));

                return {
                    ...b,
                    farm_id: barn.farm_id,
                    study_periods: studyPeriods,
                    barn_name: barn.name,
                };
            })
            .filter((b) => b !== null) || [];

    for (const farm of store.state.farms) {
        const farmBarns = barns.filter((b) => b.farm_id === farm.farm_id && store.state.sections?.some((s) => s.barn_id === b.barn_id));

        if (farmBarns.length) {
            farms.push({
                farm_id: farm.farm_id,
                farm_name: farm.name,
                barns: farmBarns,
                teamCount: farmBarns.map((b) => b.study_periods.length).reduce((a, b) => a + b, 0),
            });
        }
    }

    return farms;
});

const filteredData = computed(() => {
    const query = debouncedSearchQuery.value.trim().toLowerCase();

    if (!query) {
        return data.value;
    }

    return data.value
        .map((farm) => ({
            ...farm,
            barns: farm.barns
                .map((barn) => ({
                    ...barn,
                    study_periods: barn.study_periods.filter((sp) => sp.name.toLowerCase().includes(query)),
                }))
                .filter((barn) => barn.study_periods.length > 0 || barn.barn_name.toLowerCase().includes(query) || farm.farm_name.toLowerCase().includes(query)),
        }))
        .filter((farm) => farm.barns.length > 0);
});

const showDeleteModal = ref(false);
const selectedTeamIds = ref<number[]>([]);

async function handleDelete(teamId: number) {
    selectedTeamIds.value = [teamId];
    await nextTick();
    showDeleteModal.value = true;
}

async function handleBulkDelete() {
    selectedTeamIds.value = getSelectedStudyPeriods().map((sp) => sp.id);
    await nextTick();
    showDeleteModal.value = true;
}

const showCreateModal = ref(false);
const selectedBarnId = ref<number | null>(null);

async function handleCreate(barnId: number) {
    selectedBarnId.value = barnId;
    await nextTick();
    showCreateModal.value = true;
}

const showEditModal = ref(false);
const selectedTeam = ref<StudyPeriod | null>(null);

async function handleEdit(team: StudyPeriod) {
    selectedTeam.value = team;
    await nextTick();
    showEditModal.value = true;
}

const barnSelections = ref<Record<number, Record<number, boolean>>>({});

watch(
    () => studyPeriodBarns.value,
    (barns) => {
        if (barns) {
            for (const barn of barns) {
                if (!barnSelections.value[barn.barn_id]) {
                    barnSelections.value[barn.barn_id] = {};
                }
            }
        }
    }
);

function handleSelectionChange(barnId: number, selections: Record<number, boolean>) {
    barnSelections.value[barnId] = selections;
}

provide('barnSelections', {
    selections: barnSelections,
    updateSelections: handleSelectionChange,
});

const selectedStudyPeriodIds = computed(() =>
    Object.entries(barnSelections.value)
        .filter(([barnId]) => studyPeriodBarns.value?.some((b) => b.barn_id.toString() === barnId))
        // eslint-disable-next-line no-unused-vars
        .flatMap(([barnId, selections]) => Object.keys(selections))
);

function getSelectedStudyPeriods() {
    return studyPeriodBarns.value?.flatMap((b) => b.study_periods?.filter((sp) => selectedStudyPeriodIds.value.includes(sp.id.toString())) || []) || [];
}

const showEditTeamsModal = ref(false);
const teamsToEdit = ref<StudyPeriod[]>([]);

async function handleEditTeams() {
    teamsToEdit.value = getSelectedStudyPeriods();

    await nextTick();

    showEditTeamsModal.value = true;
}

const { data: growthIntervals } = useStudyPeriodGrowthIntervals();

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(DashboardContentLayout, {
    heading: _ctx.$t('views.monitoring.teamOverview.headings.title'),
    "has-period-picker": false
  }, {
    "header-top-right": _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_unref(SearchInput), {
          modelValue: searchQuery.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
          "aria-label": _ctx.$t('views.monitoring.teamOverview.fields.search.label'),
          placeholder: _ctx.$t('views.monitoring.teamOverview.fields.search.placeholder'),
          class: "w-64",
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (searchQuery.value = ''), ["esc"]))
        }, null, 8, ["modelValue", "aria-label", "placeholder"])
      ])
    ]),
    default: _withCtx(() => [
      _cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "border-t border-t-gray-200 mt-5 mb-6",
        "aria-hidden": "true"
      }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(DropdownMenu), { modal: false }, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "outline",
                  class: _normalizeClass(["transition-[opacity,background-color]", { 'visible opacity-100': selectedStudyPeriodIds.value.length > 0, 'invisible opacity-0': selectedStudyPeriodIds.value.length === 0 }])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.manageSelected')) + " ", 1),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(selectedStudyPeriodIds.value.length), 1),
                    _createVNode(_unref(ChevronDown), {
                      class: "size-4",
                      "aria-hidden": "true"
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), {
              class: "min-w-[var(--radix-dropdown-menu-trigger-width)]",
              align: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: handleEditTeams
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.edit')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (barnSelections.value = {}))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.unselectAll')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: handleBulkDelete
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.delete')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["cursor-pointer", _unref(cn)(_unref(buttonVariants)({ variant: 'outline' }))]),
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (showOnlyFavorites.value = !showOnlyFavorites.value), ["self"]))
        }, [
          _createVNode(_unref(Checkbox), {
            id: "unread",
            checked: showOnlyFavorites.value,
            "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((showOnlyFavorites).value = $event))
          }, null, 8, ["checked"]),
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.fields.showOnlyFavorites')), 1)
        ], 2),
        _createVNode(PeriodPicker, { slim: "" })
      ]),
      _createVNode(FadeTransition, { mode: "out-in" }, {
        default: _withCtx(() => [
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(Spinner, { class: "h-10 w-10" })
              ]))
            : (filteredData.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredData.value, (farm) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: farm.farm_id,
                      class: "border border-gray-300 rounded-lg px-4 py-5"
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_Icon, {
                              class: "h-full w-full object-contain",
                              src: _unref(IconSource).FarmsPrimary
                            }, null, 8, ["src"])
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_10, _toDisplayString(farm.farm_name), 1),
                            _createElementVNode("div", _hoisted_11, _toDisplayString(farm.barns.length) + " " + _toDisplayString(farm.barns.length === 1 ? _ctx.$t('views.monitoring.teamOverview.texts.barn') : _ctx.$t('views.monitoring.teamOverview.texts.barns')) + ", " + _toDisplayString(farm.teamCount) + " " + _toDisplayString(farm.teamCount === 1 ? _ctx.$t('views.monitoring.teamOverview.texts.team') : _ctx.$t('views.monitoring.teamOverview.texts.teams')), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "h-10 w-10 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200",
                            onClick: ($event: any) => (expandedSelection.value[farm.farm_id] = expandedSelection.value[farm.farm_id] !== undefined ? !expandedSelection.value[farm.farm_id] : true)
                          }, [
                            _createVNode(_unref(ChevronDown), {
                              class: _normalizeClass(["size-7 transform transition-transform", { 'rotate-[180deg]': expandedSelection.value[farm.farm_id] }]),
                              "aria-hidden": "true"
                            }, null, 8, ["class"])
                          ], 8, _hoisted_13)
                        ])
                      ]),
                      _createVNode(ExpandCollapseTransition, null, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("div", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(farm.barns, (barn) => {
                              return (_openBlock(), _createBlock(BarnSection, {
                                key: barn.barn_id,
                                barn: barn,
                                "growth-intervals": _unref(growthIntervals),
                                onDelete: handleDelete,
                                onCreate: handleCreate,
                                onEdit: handleEdit
                              }, null, 8, ["barn", "growth-intervals"]))
                            }), 128))
                          ], 512), [
                            [_vShow, expandedSelection.value[farm.farm_id]]
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.texts.noData')), 1)
                ]))
        ]),
        _: 1
      }),
      _createVNode(CreateTeamModal, {
        modelValue: showCreateModal.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showCreateModal).value = $event)),
        "barn-id": selectedBarnId.value
      }, null, 8, ["modelValue", "barn-id"]),
      _createVNode(EditTeamModal, {
        modelValue: showEditModal.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((showEditModal).value = $event)),
        team: selectedTeam.value,
        onDelete: handleDelete
      }, null, 8, ["modelValue", "team"]),
      _createVNode(DeleteTeamModal, {
        modelValue: showDeleteModal.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((showDeleteModal).value = $event)),
        "team-ids": selectedTeamIds.value,
        onDeleted: _cache[8] || (_cache[8] = ($event: any) => (showEditModal.value = false))
      }, null, 8, ["modelValue", "team-ids"]),
      _createVNode(EditTeamsModal, {
        modelValue: showEditTeamsModal.value,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((showEditTeamsModal).value = $event)),
        teams: teamsToEdit.value
      }, null, 8, ["modelValue", "teams"])
    ]),
    _: 1
  }, 8, ["heading"]))
}
}

})