export type NotificationResponse = {
    page_number: number;
    page_size: number;
    next_page: null | number;
    total_pages: number | null;
    total_rows: number | null;
    data: Notification[];
};

export type Notification = {
    category: 'NfnCategory.Text' | 'NfnCategory.Form';
    disabled: boolean;
    hidden: boolean;
    rejected: boolean;
    approved: boolean;
    requires_action: boolean;
    icon_url: string;
    id: number;
    message: string;
    publish_date: string;
    read: boolean;
    resource: {
        reference_id: number;
        resource_id: number;
        open_in_edit: boolean;
        icon_url: string;
        icon_id: number;
        text: string;
    } | null;
    type: 'NfnType.Alert' | 'NfnType.Warning' | 'NfnType.Form.Slaughter';
    type_id: number;
};

export type FilterableNotificationGroup = {
    id: number;
    icon_id: number;
    text: string;
    type_ids: number[];
};

/* eslint-disable */
export enum NotificationStatusType {
    REQUIRES_ACTION = 'requires_action',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    READ = 'read',
    UNREAD = 'unread',
}
/* eslint-enable */
