<script setup lang="ts">
import { DialogRoot, DialogPortal } from 'radix-vue';
import { X } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import { DialogTrigger, DialogContent, DialogClose } from '.';
import { Button } from '@/components/ui/button';

interface Props {
    modelValue?: boolean;
    disabled?: boolean;
    closable?: boolean;
}

defineOptions({
    inheritAttrs: false,
});
const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    closable: true,
});
const emit = defineEmits<{
    'update:modelValue': [value: boolean];
}>();

const internalModelValue = ref(false);
const computedModelValue = computed(() => props.modelValue ?? internalModelValue.value);

function handleOpen(value: boolean) {
    if (props.disabled) {
        return;
    }

    if (props.modelValue === undefined) {
        internalModelValue.value = value;
    }

    emit('update:modelValue', value);
}
</script>

<template>
    <DialogRoot :open="computedModelValue" @update:open="handleOpen">
        <DialogTrigger v-if="$slots.trigger" as-child>
            <slot name="trigger" />
        </DialogTrigger>
        <DialogPortal>
            <DialogContent v-bind="$attrs">
                <slot />
                <DialogClose v-if="closable" as-child>
                    <Button :disabled="props.disabled" variant="ghost" size="icon" class="h-8 w-8 absolute top-3 right-3">
                        <span class="sr-only">Close</span>
                        <X class="size-6" aria-hidden="true" />
                    </Button>
                </DialogClose>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>
