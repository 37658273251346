<template>
    <Dialog v-model="showModal" :disabled="isLoading" class="pb-4 px-0 sm:max-w-[1200px]">
        <DialogHeader class="pt-8 pb-4 px-8">
            <DialogTitle>{{ $t('views.monitoring.teamOverview.modals.editTeams.title') }}</DialogTitle>
            <VisuallyHidden>
                <DialogDescription class="pt-4">{{ $t('views.monitoring.teamOverview.modals.editTeams.description') }}</DialogDescription>
            </VisuallyHidden>
        </DialogHeader>
        <table class="relative w-full">
            <thead>
                <tr class="bg-white">
                    <th class="text-left pl-4 py-4 text-gray-400">{{ $t('views.monitoring.teamOverview.modals.editTeams.table.headings.name') }}</th>
                    <th class="text-left pr-4 py-4 text-gray-400">{{ $t('views.monitoring.teamOverview.modals.editTeams.table.headings.startDate') }}</th>
                    <th class="text-left pr-4 py-4 text-gray-400">{{ $t('views.monitoring.teamOverview.modals.editTeams.table.headings.endDate') }}</th>
                    <th class="text-left pr-4 py-4 text-gray-400">{{ $t('views.monitoring.teamOverview.modals.editTeams.table.headings.location') }}</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(team, index) in state" :key="team.id">
                    <tr class="transition-colors duration-200" :class="{ 'bg-gray-50': index % 2 === 0 && !errors[team.id], 'bg-red-100': errors[team.id] }">
                        <td class="pl-4 pr-4 pt-4">
                            <CustomInput
                                v-model:value="(state[index].name as any)"
                                :rules="[{ validate: (value: string) => value.length > 0, message: '' }]"
                                :required="false"
                                @update:value="removeErrorMessage(team.id)"
                            />
                        </td>
                        <td class="w-40 pr-4 pt-4"><DatePicker v-model:value="state[index].startDate" class="w-44" :resetable="false" :show-overlay="false" @update:value="removeErrorMessage(team.id)" /></td>
                        <td class="w-40 pr-4 pt-4"><DatePicker v-model:value="state[index].endDate" class="w-44" :resetable="false" :show-overlay="false" @update:value="removeErrorMessage(team.id)" /></td>
                        <td class="pr-4 pt-4"><CustomInput v-model:value="(state[index].locationNameAugmented as any)" disabled /></td>
                    </tr>
                    <tr class="transition-colors duration-200" :class="{ 'bg-gray-50': index % 2 === 0 && !errors[team.id], 'bg-red-100': errors[team.id] }">
                        <td colspan="4" class="pl-9 pr-4 py-0">
                            <ExpandTransition>
                                <div v-if="errors[team.id]">
                                    <p class="text-red-900 pb-4">{{ errors[team.id] }}</p>
                                </div>
                            </ExpandTransition>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <DialogFooter class="inline-flex mx-auto pb-4 px-8">
            <DialogClose as-child>
                <CustomButton :disabled="isLoading" color-preset="white" class="min-w-56 max-w-full">{{ $t('views.monitoring.teamOverview.modals.editTeams.buttons.cancel') }}</CustomButton>
            </DialogClose>
            <CustomButton :loading="isLoading" class="min-w-56 max-w-full" @click="handleEdit">{{ $t('views.monitoring.teamOverview.modals.editTeams.buttons.save') }}</CustomButton>
        </DialogFooter>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { VisuallyHidden } from 'radix-vue';
import { addWeeks, format, parseISO } from 'date-fns';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import DatePicker from '@/components/ui/DatePicker.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import { StudyPeriod } from '@/types';
import ExpandTransition from '@/components/transitions/ExpandTransition.vue';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import { i18n } from '@/plugins/internationalization/i18n';

const props = defineProps<{
    teams: StudyPeriod[];
}>();

const queryClient = useQueryClient();
const toast = useToast();

const showModal = defineModel<boolean>('modelValue');

interface EditTeamState {
    id: number;
    locationNameAugmented: string;
    name: string;
    startDate: string;
    endDate: string;
    isFavorite: boolean;
    error?: string;
}

function getInitialState() {
    return props.teams.map<EditTeamState>((team) => ({
        id: team.id,
        locationNameAugmented: team.location_name_augmented,
        name: team.name,
        startDate: team.start ? new Date(team.start as string).toISOString() : new Date().toISOString(),
        endDate: team.end ? new Date(team.end as string).toISOString() : addWeeks(new Date(), 1).toISOString(),
        isFavorite: team.is_favorite,
    }));
}

function getPayload() {
    return state.value.map((team) => ({
        id: team.id,
        name: team.name,
        start: format(parseISO(team.startDate), 'yyyy-MM-dd'),
        end: format(parseISO(team.endDate), 'yyyy-MM-dd'),
        is_favorite: team.isFavorite,
    }));
}

const errors = ref<Record<number, string>>({});

function removeErrorMessage(id: number) {
    const { [id]: _, ...rest } = errors.value;
    errors.value = rest;
}

async function handleEdit() {
    try {
        if (isLoading.value) {
            return;
        }

        errors.value = {};

        isLoading.value = true;

        await ApiClient.send(
            'put',
            '/monitor/study-periods/bulk-update',
            {
                skipHandleError: true,
            },
            {
                study_periods: getPayload(),
            }
        );

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        showModal.value = false;

        toast.success(i18n.global.t('views.monitoring.teamOverview.modals.editTeams.successMessages.teamsWereEdited'));
    } catch (err) {
        // @ts-ignore
        errors.value = (err?.data?.conflicts || []).reduce((acc: Record<number, string>, c: any) => {
            acc[c.id] = c.conflict_message;
            return acc;
        }, {});

        toast.error(i18n.global.t('views.monitoring.teamOverview.modals.editTeams.errors.couldNotEditTeams'));
    } finally {
        isLoading.value = false;
    }
}
const state = ref<EditTeamState[]>(getInitialState());

const isLoading = ref(false);

watch(showModal, (value) => {
    if (value) {
        state.value = getInitialState();
    }
});
</script>
