<template>
    <div class="space-y-2">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.feed.mixes.newMixModal.mixRawMaterials.header').replace('$MIX_NAME', computedValue.name) }}</h2>
        <div class="justify-center flex flex-col">
            <div class="w-full flex justify-center my-4">
                <AutoComplete
                    class="w-72"
                    :items="filteredRawMaterials"
                    item-text="name"
                    item-value="id"
                    :label="$t('views.feed.mixes.newMixModal.mixRawMaterials.autoCompleteFieldLabel')"
                    @item-clicked="onItemClicked"
                ></AutoComplete>
            </div>
            <div class="mt-4">
                <HomeMixRawMaterialsTable :value="computedValue" include-actions>
                    <template #header_actions>
                        <th class="flex w-10 px-2 justify-end"></th>
                    </template>

                    <template #sum_name="{ sumHeader }">
                        <td :key="sumHeader" style="break" class="flex flex-1 px-2 my-auto word-break">
                            {{ $t('views.feed.mixes.homeMixRawMaterialsTable.total') }}
                        </td>
                    </template>

                    <template #admixture_percentage="{ item }">
                        <td class="flex flex-1 px-2 my-auto">
                            <SimpleInput
                                v-model:value="item.admixture_percentage"
                                :disabled="computedValue.type.id === componentMixTypeId"
                                :class="`${computedValue.type.id === componentMixTypeId ? 'text-gray-400' : ''} w-20`"
                                background-color="bg-gray-100"
                                type="number"
                                step="any"
                            ></SimpleInput>
                        </td>
                    </template>

                    <template #actions="{ item }">
                        <td class="flex w-10 px-2 my-auto justify-end">
                            <Icon class="cursor-pointer w-8 h-8" :src="IconSource.Delete" @click="onDeleteItemClicked(item)"></Icon>
                        </td>
                    </template>

                    <template #price="{ item, header }">
                        <td :key="header.value" class="flex flex-1 px-2 my-auto word-break">
                            {{ $n(getPrice(item.material)?.price || 0, 'price') }}
                        </td>
                    </template>

                    <template #sum_components="{ sumHeader }">
                        <td :key="sumHeader" :class="{ 'text-red-600': amountSum !== 100 }" class="flex text-center flex-1 px-2 my-auto word-break">
                            <span class="w-20">
                                {{ $n(amountSum) }}
                            </span>
                        </td>
                    </template>

                    <template #sum_price="{ sumHeader }">
                        <td :key="sumHeader" class="flex flex-1 px-2 my-auto word-break">
                            <span class="w-20">
                                {{ $n(value.prices[0].price, 'price') }}
                            </span>
                        </td>
                    </template>
                    <template #sum_actions>
                        <td class="flex w-10 px-2 justify-end"></td>
                    </template>
                </HomeMixRawMaterialsTable>
            </div>
            <div class="flex justify-end my-4 space-x-4">
                <div class="flex border-2 border-opacity-10 rounded-lg space-x-4 px-4 box-content">
                    <span class="my-auto space-x-2">
                        <label for="surcharge">{{ $t('views.feed.mixes.newMixModal.mixRawMaterials.surcharge') }}:</label>
                        <SimpleInput id="surcharge" v-model:value="computedValue.surcharge" class="w-16" type="number" name="surcharge" step="any"></SimpleInput>
                    </span>
                    <span class="my-auto font-medium">
                        {{ `${totalPriceDisplayText}: ${$n(computedValue.prices[0].price + (computedValue.surcharge || 0), 'price')}` }}
                    </span>
                </div>
                <div class="w-60">
                    <CustomButton :disabled="disableButtons" :disable="!validForm" @click="onSubmitButtonClicked">
                        {{ $t('views.feed.mixes.newMixModal.mixRawMaterials.confirmButtonLabel') }}
                    </CustomButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import { store } from '@/plugins/store';
import { RawMaterial, MixForm, MixComponent, MixTypeId } from '@/types';
import HomeMixRawMaterialsTable from '@/views/dashboard/feed/feedMixes/HomeMixRawMaterialsTable.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { ActionType } from '@/plugins/store/actions';
import useGetPrice from '@/components/common/composables/useGetPrice';
import SimpleInput from '@/components/ui/SimpleInput.vue';
import { roundIfNeccessary } from '@/util/numbers';

export default defineComponent({
    components: { AutoComplete, HomeMixRawMaterialsTable, CustomButton, SimpleInput },
    props: {
        value: {
            type: Object as () => Form<MixForm> & MixForm,
            required: true,
        },
    },
    data() {
        return {
            disableButtons: false,
            componentMixTypeId: MixTypeId.COMPONENT_MIX,
        };
    },
    computed: {
        amountSum(): number {
            return Number((this.computedValue.components?.reduce((accum, current) => accum + Number(current.admixture_percentage), 0) || 0).toFixed(4));
        },
        computedValue: {
            get(): Form<MixForm> & MixForm {
                return this.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
        validForm(): boolean {
            return (
                this.amountSum === 100 &&
                Array.isArray(this.computedValue.prices) &&
                this.computedValue.prices.length === 1 &&
                typeof this.computedValue.prices[0].price === 'number' &&
                this.computedValue.prices[0].price >= 0 &&
                Array.isArray(this.computedValue.components) &&
                typeof this.computedValue.surcharge === 'number' &&
                this.computedValue.components.every((component) => typeof component.admixture_percentage === 'number' && component.admixture_percentage >= 0)
            );
        },
        filteredRawMaterials(): RawMaterial[] {
            return store.state.rawMaterials.filter(
                (currentRawMaterial) => this.computedValue.components === null || !this.computedValue.components.find((currentComponent) => currentComponent.id === currentRawMaterial.id)
            );
        },
        totalPriceDisplayText() {
            if (store.getters.priceCurrencyPerWeightUnitDisplayText) {
                return this.$t('views.feed.mixes.newMixModal.mixRawMaterials.totalPriceUnit').replace('%VALUE%', store.getters.priceCurrencyPerWeightUnitDisplayText);
            }

            return this.$t('views.feed.mixes.newMixModal.mixRawMaterials.totalPrice');
        },
    },
    watch: {
        value: {
            deep: true,
            handler() {
                const weightedAverageProperties = [
                    { weightedAverageKey: 'wgt_avg_fu', componentKey: 'material.fu' },
                    { weightedAverageKey: 'wgt_avg_crude_protein', componentKey: 'material.crude_protein' },
                    { weightedAverageKey: 'wgt_avg_phosphorus', componentKey: 'material.phosphorus' },
                    { weightedAverageKey: 'prices[0].price', componentKey: null },
                ];
                weightedAverageProperties.forEach((weightedAverageProperty) => {
                    const weightedAverage = (this.value.components || []).reduce((accum, current) => {
                        const itemProperty = weightedAverageProperty.componentKey ? get(current, weightedAverageProperty.componentKey) : useGetPrice(current.material)?.price || 0;
                        const itemPercentage = current.admixture_percentage;
                        if (itemProperty && itemPercentage) {
                            accum += Number(itemProperty) * itemPercentage * 0.01;
                        }
                        return accum;
                    }, 0);
                    const roundedWeightedAverage = roundIfNeccessary(weightedAverage, 2);
                    set(this.computedValue, weightedAverageProperty.weightedAverageKey, roundedWeightedAverage);
                });
            },
        },
    },
    methods: {
        onItemClicked(item: RawMaterial) {
            if (this.computedValue.type.id === MixTypeId.HOME_MIX && !(this.computedValue as MixForm).components?.find((rawMaterial) => rawMaterial.id === item.id)) {
                this.computedValue.fill({ components: [...this.computedValue.components, { id: item.id, admixture_percentage: 0, material: item }] });
            } else if (this.computedValue.type.id === MixTypeId.COMPONENT_MIX) {
                this.computedValue.fill({ components: [{ id: item.id, admixture_percentage: 100, material: item }] });
            }
        },
        onDeleteItemClicked(item: MixComponent) {
            this.computedValue.fill({
                components: this.computedValue.components?.filter((component) => component.id !== item.id),
            });
        },
        async onSubmitButtonClicked() {
            if (this.validForm) {
                this.disableButtons = true;
                this.setValidFrom();
                await store.dispatch(ActionType.CreateFeedMix, { form: this.computedValue });
                store.dispatch(ActionType.GetMixes, { options: { ignoreCache: true } });
                if (!this.computedValue.errors.hasErrors()) {
                    this.$emit('submit');
                }
            }
        },
        /* A new HomeMix's valid from will always be the same as the raw material with the latest valid_from */
        setValidFrom() {
            if (!this.computedValue.components || this.computedValue.components.length === 0) {
                return;
            }
            const latestRawMaterialPrice = this.computedValue.components
                .map((currentMixComponent) => this.getPrice(currentMixComponent.material))
                .reduce((currentPrice, latestPrice) => {
                    if (latestPrice === null || (currentPrice && Date.parse(currentPrice.valid_from) > Date.parse(latestPrice.valid_from))) {
                        return currentPrice;
                    }
                    return latestPrice;
                }, null);

            if (latestRawMaterialPrice && this.computedValue.prices && this.computedValue.prices.length === 1) {
                Object.assign(this.computedValue.prices[0], { valid_from: latestRawMaterialPrice.valid_from });
            }
        },
        getPrice: useGetPrice,
    },
});
</script>

<style>
.word-break {
    word-break: break-word;
}
</style>
