<script setup lang="ts">
import { ref, computed, h, watch, inject, Ref } from 'vue';
import { ColumnDef, SortingState } from '@tanstack/vue-table';
import { EllipsisVertical, Plus, ChevronDown } from 'lucide-vue-next';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import Icon from '@/components/icons/Icon.vue';
import { IconSource, StudyPeriod, StudyPeriodBarnResponseEntry, StudyPeriodGrowthInterval } from '@/types';
import { Checkbox } from '@/components/ui/checkbox';
import { i18n } from '@/plugins/internationalization/i18n';
import DataTable from '@/components/ui/data-table/DataTable.vue';
import { store } from '@/plugins/store';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Select as UISelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const props = withDefaults(
    defineProps<{
        barn: StudyPeriodBarnResponseEntry;
        growthIntervals?: StudyPeriodGrowthInterval[];
    }>(),
    {
        growthIntervals: () => [],
    }
);
const emit = defineEmits<{
    delete: [teamId: number];
    create: [barnId: number];
    edit: [team: StudyPeriod];
}>();

const columns = computed<ColumnDef<StudyPeriod & { _locationName: string }>[]>(() => [
    {
        id: '_select',
        header: ({ table }) =>
            h(Checkbox, {
                checked: table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
                'onUpdate:checked': (value) => table.toggleAllPageRowsSelected(!!value),
                ariaLabel: i18n.global.t('views.monitoring.teamOverview.fields.selectAll.label'),
                class: 'mt-1',
            }),
        cell: ({ row }) =>
            h(Checkbox, {
                checked: row.getIsSelected(),
                'onUpdate:checked': (value) => row.toggleSelected(!!value),
                ariaLabel: i18n.global.t('views.monitoring.teamOverview.fields.selectTeam.label'),
                class: 'mt-1',
            }),
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.name'),
        accessorKey: 'name',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.startDate'),
        accessorKey: 'start',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.endDate'),
        accessorKey: 'end',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.location'),
        accessorKey: '_locationName',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.growthIndex'),
        accessorKey: '_growthIndex',
        enableSorting: true,
        meta: {
            align: 'center',
        },
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.creationType'),
        accessorKey: 'is_auto_generated',
        enableSorting: true,
        meta: {
            align: 'center',
        },
    },
    {
        header: '',
        accessorKey: 'is_favorite',
    },
    {
        header: '',
        accessorKey: '_actions',
        meta: {
            cellClass: 'w-10',
        },
    },
]);
const selectedGrowthIndex = ref<string | undefined>(undefined);

const sorting = ref<SortingState>([
    {
        id: 'start',
        desc: true,
    },
]);

function getSection(locationId: number) {
    return store.state.sections?.find((s) => s.id === locationId);
}

const items = computed(() =>
    props.barn.study_periods.map((sp) => ({
        ...sp,
        _locationName: getSection(sp.location_id)?.name || '',
        _growthIndex: sp.growth_indices?.length ? sp.growth_indices.find((i) => i.growth_interval_id.toString() === selectedGrowthIndex.value)?.value : null,
    }))
);

const page = ref(1);
const pageSize = ref(9999);
const pageSizeOptions = ref([10, 25, 50, 100]);

const isExpanded = ref(false);
const computedExpanded = computed(() => Boolean(isExpanded.value && props.barn.study_periods?.length));

const { selections, updateSelections } = inject('barnSelections') as {
    selections: Ref<Record<number, Record<number, boolean>>>;
    // eslint-disable-next-line
    updateSelections: (barnId: number, selections: Record<number, boolean>) => void
};

const rowSelection = computed({
    get() {
        return selections.value[props.barn.barn_id] || {};
    },
    set(value) {
        updateSelections(props.barn.barn_id, value);
    },
});

function setInitialGrowthIndex() {
    if (!selectedGrowthIndex.value && props.growthIntervals.length) {
        selectedGrowthIndex.value = props.growthIntervals[0].id.toString();
    }
}

setInitialGrowthIndex();

const stopSelectedGrowthIndexWatcher = watch(
    () => props.growthIntervals,
    (newVal) => {
        if (newVal.length) {
            setInitialGrowthIndex();
            stopSelectedGrowthIndexWatcher();
        }
    }
);
</script>

<template>
    <MainViewCard class="max-w-full overflow-hidden">
        <div class="divide-y divide-gray-200 space-y-4 px-4 py-4">
            <div class="flex justify-between items-center space-x-3">
                <div class="flex items-center space-x-3">
                    <div>
                        <Icon class="h-6" :src="IconSource.Location"></Icon>
                    </div>
                    <div>
                        <div class="font-bold text-lg">{{ barn.barn_name }}</div>
                        <div class="text-sm text-gray-500">
                            {{ barn.study_periods?.length || 0 }} {{ barn.study_periods?.length === 1 ? $t('views.monitoring.teamOverview.texts.team') : $t('views.monitoring.teamOverview.texts.teams') }}
                        </div>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-3">
                    <div class="transition-opacity duration-200" :class="{ 'opacity-100 visible': isExpanded, 'opacity-0 invisible': !isExpanded }">
                        <UISelect v-model="selectedGrowthIndex" :disabled="!growthIntervals.length">
                            <SelectTrigger class="rounded-lg font-medium bg-white border-gray-200 h-auto text-gray-700 hover:bg-gray-100 hover:text-gray-800 transition-colors pl-3 pr-2.5 py-2 text-normal">
                                <span class="font-semibold mr-1.5">{{ $t('views.monitoring.teamOverview.fields.growthIndexInterval') }}:</span> <SelectValue />
                            </SelectTrigger>
                            <SelectContent align="end" :body-lock="false">
                                <SelectGroup>
                                    <SelectItem v-for="interval in growthIntervals" :key="interval.id" :value="interval.id.toString()">{{ interval.name }}</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </UISelect>
                    </div>
                    <button class="flex items-center rounded-lg gap-x-1 text-sky-500 hover:bg-sky-400/10 transition-colors px-3.5 py-2" @click="emit('create', barn.barn_id)">
                        <Plus class="size-5" aria-hidden="true" />
                        {{ $t('views.monitoring.teamOverview.buttons.createTeam') }}
                    </button>
                    <button
                        v-if="barn.study_periods && barn.study_periods.length"
                        type="button"
                        class="h-9 w-9 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200"
                        @click="isExpanded = !isExpanded"
                    >
                        <ChevronDown class="size-6 transform transition-transform" :class="{ 'rotate-[180deg]': isExpanded }" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <ExpandCollapseTransition>
            <div v-show="computedExpanded">
                <DataTable
                    v-model:sorting="sorting"
                    v-model:row-selection="rowSelection"
                    :data="items"
                    :columns="columns"
                    :page="page"
                    :page-size="pageSize"
                    :page-size-options="pageSizeOptions"
                    :enable-pagination="false"
                    @update:row-selection="updateSelections(barn.barn_id, $event)"
                >
                    <template #item[start]="{ item }">
                        {{ $d(item.start as Date, 'short') }}
                    </template>
                    <template #item[end]="{ item }">
                        {{ $d(item.end as Date, 'short') }}
                    </template>
                    <template #item[is_auto_generated]="{ item }">
                        {{ item.is_auto_generated ? $t('views.monitoring.teamOverview.creationTypes.autoGenerated') : $t('views.monitoring.teamOverview.creationTypes.manuallyGenerated') }}
                    </template>
                    <template #item[growth_indices]="{ item }">
                        <div v-if="item.growth_indices?.length">
                            {{
                                item.growth_indices
                                    .sort((a, b) => a.value - b.value)
                                    .map((i) => i.value)
                                    .join(', ')
                            }}
                        </div>
                    </template>
                    <template #item[is_favorite]="{ item }">
                        <div class="flex justify-end">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-7" :class="{ 'text-yellow-400': item.is_favorite, 'text-gray-200': !item.is_favorite }">
                                <path
                                    fill-rule="evenodd"
                                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                    </template>
                    <template #item[_actions]="{ item }">
                        <div class="flex justify-end">
                            <DropdownMenu :modal="false">
                                <DropdownMenuTrigger as-child>
                                    <Button variant="ghost" size="icon" class="hover:gray-300 rounded-full -mr-1">
                                        <span class="sr-only">{{ $t('views.monitoring.teamOverview.buttons.actions') }}</span>
                                        <EllipsisVertical class="size-5" aria-hidden="true" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent class="min-w-[var(--radix-dropdown-menu-trigger-width)]" align="end">
                                    <DropdownMenuItem class="cursor-pointer font-medium" @click="emit('edit', item)">{{ $t('views.monitoring.teamOverview.buttons.edit') }}</DropdownMenuItem>
                                    <DropdownMenuItem class="cursor-pointer" @click="emit('delete', item.id)">
                                        <span class="text-red-500 font-medium">{{ $t('views.monitoring.teamOverview.buttons.delete') }}</span>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </template>
                </DataTable>
            </div>
        </ExpandCollapseTransition>
    </MainViewCard>
</template>
