<template>
    <div class="flex space-x-4">
        <AddGlobalPriceModal :step-config="addGlobalPriceStepConfig">
            <template #default="scope">
                <AddButton text-class="font-medium" @click="scope.open">
                    {{ $t('views.economy.status.modals.addGlobalPrice.buttonLabel') }}
                </AddButton>
            </template>
        </AddGlobalPriceModal>
        <EditGlobalPriceModal :step-config="editGlobalPriceStepConfig">
            <template #default="scope">
                <AddButton text-class="font-medium" @click="scope.open">
                    {{ $t('views.economy.status.modals.editGlobalPrice.buttonLabel') }}
                </AddButton>
            </template>
        </EditGlobalPriceModal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import AddGlobalPriceModal from '@/components/common/economy/globalPrices/AddGlobalPriceModal.vue';
import EditGlobalPriceModal from '@/components/common/economy/globalPrices/EditGlobalPriceModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import { EconomyStatusEntry } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';

export default defineComponent({
    components: { AddGlobalPriceModal, EditGlobalPriceModal, AddButton },
    props: {
        entries: {
            type: Array as () => EconomyStatusEntry[],
            default: () => [],
        },
    },
    computed: {
        addGlobalPriceStepConfig() {
            return [
                {
                    text: this.$t('views.economy.status.modals.addGlobalPrice.header'),
                    numberFields: [
                        {
                            value: 'piglet_dkk',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.addGlobalPrice.pigletFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.addGlobalPrice.pigletFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'piglet_dkk_pr_kg',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.addGlobalPrice.pigletPerKgFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.addGlobalPrice.pigletPerKgFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'slpig_dkk',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.addGlobalPrice.slaughterPigsFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.addGlobalPrice.slaughterPigsFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'slpig_dkk_pr_kg',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.addGlobalPrice.slaughterPigsPerKgFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.addGlobalPrice.slaughterPigsPerKgFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                    ],
                    transformPeriod: (period: string) => ({ period: format(new Date(period), 'yyyy-MM-dd') }),
                    submitActionType: ActionType.CreateGlobalStatusEntry,
                    fetchActionType: ActionType.GetEconomyStatusEntries,
                    confirmButtonLabel: this.$t('views.economy.status.modals.addGlobalPrice.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.status.modals.addGlobalPrice.periodFieldLabel'),
                    monthPicker: {
                        disableMonths: this.$props.entries.reduce((accum, current) => {
                            const date = new Date(current.period_date);
                            const year = `${date.getFullYear()}`;
                            const month = date.getMonth();
                            if (!accum[year]) {
                                accum[year] = [];
                            }
                            if (!accum[year].includes(month)) {
                                accum[year].push(month);
                            }
                            return accum;
                        }, {} as { [key: string]: number[] }),
                    },
                },
                { text: this.$t('views.economy.status.modals.addGlobalPrice.successMessage') },
            ];
        },
        editGlobalPriceStepConfig() {
            return [
                {
                    text: this.$t('views.economy.status.modals.editGlobalPrice.header'),
                    numberFields: [
                        {
                            value: 'piglet_dkk',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.editGlobalPrice.pigletFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.editGlobalPrice.pigletFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'piglet_dkk_pr_kg',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.editGlobalPrice.pigletPerKgFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.editGlobalPrice.pigletPerKgFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'slpig_dkk',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.editGlobalPrice.slaughterPigsFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.editGlobalPrice.slaughterPigsFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                        {
                            value: 'slpig_dkk_pr_kg',
                            label: store.getters.priceUnitDisplayText
                                ? this.$t('views.economy.status.modals.editGlobalPrice.slaughterPigsPerKgFieldLabelUnit').replace('%VALUE%', store.getters.priceUnitDisplayText)
                                : this.$t('views.economy.status.modals.editGlobalPrice.slaughterPigsPerKgFieldLabel'),
                            colSpan: 'col-span-1',
                        },
                    ],
                    items: this.$props.entries,
                    fetchActionType: ActionType.GetEconomyStatusEntries,
                    confirmButtonLabel: this.$t('views.economy.status.modals.editGlobalPrice.confirmButtonLabel'),
                    periodFieldLabel: this.$t('views.economy.status.modals.editGlobalPrice.periodFieldLabel'),
                    updateActionType: ActionType.EditGlobalStatusEntry,
                    deleteButtonLabel: this.$t('views.economy.status.modals.deleteGlobalPriceConfirmationModal.deleteButtonLabel'),
                    deleteModal: {
                        header: this.$t('views.economy.status.modals.deleteGlobalPriceConfirmationModal.header'),
                        message: this.$t('views.economy.status.modals.deleteGlobalPriceConfirmationModal.message'),
                        cancelButtonLabel: this.$t('views.economy.status.modals.deleteGlobalPriceConfirmationModal.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('views.economy.status.modals.deleteGlobalPriceConfirmationModal.confirmButtonLabel'),
                        deleteActionType: ActionType.DeleteGlobalStatusEntry,
                        fetchActionType: ActionType.GetEconomyStatusEntries,
                    },
                },
                { text: this.$t('views.economy.status.modals.editGlobalPrice.successMessage') },
                { text: this.$t('views.economy.status.modals.deleteGlobalPriceConfirmationModal.successMessage') },
            ];
        },
    },
});
</script>
