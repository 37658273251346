import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "grid grid-cols-2 gap-x-4",
  tabindex: "-1"
}

import { ref, computed, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { VisuallyHidden } from 'radix-vue';
import { useToast } from 'vue-toastification';
import { addWeeks, format, parseISO } from 'date-fns';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import DatePicker from '@/components/ui/DatePicker.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { StudyPeriod } from '@/types';
import { i18n } from '@/plugins/internationalization/i18n';

interface EditTeamState {
    locationNameAugmented: string;
    name: string;
    startDate: string;
    endDate: string;
    isFavorite: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditTeamModal',
  props: /*@__PURE__*/_mergeModels({
    team: {}
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["delete"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const queryClient = useQueryClient();
const toast = useToast();

const showModal = _useModel<boolean>(__props, 'modelValue');

const isLoading = ref(false);

function getInitialState() {
    return {
        locationNameAugmented: '',
        name: '',
        startDate: new Date().toISOString(),
        endDate: addWeeks(new Date(), 1).toISOString(),
        isFavorite: false,
    };
}

const state = ref<EditTeamState>(getInitialState());

async function handleCreate() {
    try {
        if (isLoading.value) {
            return;
        }

        isLoading.value = true;

        await ApiClient.send(
            'put',
            '/monitor/study-periods/bulk-update',
            {
                skipHandleError: true,
            },
            {
                study_periods: [
                    {
                        id: props.team?.id,
                        name: state.value.name,
                        start: format(parseISO(state.value.startDate), 'yyyy-MM-dd'),
                        end: format(parseISO(state.value.endDate), 'yyyy-MM-dd'),
                        is_favorite: state.value.isFavorite,
                    },
                ],
            }
        );

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        showModal.value = false;

        toast.success(i18n.global.t('views.monitoring.teamOverview.modals.editTeam.successMessages.teamWasEdited'));
    } catch (err) {
        const errorMessage = (err as any)?.data?.message || i18n.global.t('views.monitoring.teamOverview.modals.editTeam.errors.couldNotEditTeam');
        toast.error(errorMessage);
    } finally {
        isLoading.value = false;
    }
}

const isFormValid = computed(() => state.value.name.length > 0);

watch(showModal, (value) => {
    if (value) {
        state.value = {
            locationNameAugmented: props.team?.location_name_augmented ?? '',
            name: props.team?.name ?? '',
            startDate: props.team?.start ? new Date(props.team.start as string).toISOString() : new Date().toISOString(),
            endDate: props.team?.end ? new Date(props.team.end as string).toISOString() : addWeeks(new Date(), 1).toISOString(),
            isFavorite: props.team?.is_favorite ?? false,
        };
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    modelValue: showModal.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showModal).value = $event)),
    disabled: isLoading.value,
    class: "sm:max-w-[540px]"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(DialogHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogTitle), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeam.title')), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(VisuallyHidden), null, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogDescription), { class: "pt-4" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeam.description')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("form", {
        class: "mt-4 space-y-2",
        onSubmit: _withModifiers(handleCreate, ["prevent"])
      }, [
        _createVNode(CustomInput, {
          value: (state.value?.locationNameAugmented as any) ?? '',
          disabled: ""
        }, null, 8, ["value"]),
        _createVNode(CustomInput, {
          value: (state.value.name as any),
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (((state.value.name as any)) = $event)),
          rules: [{ validate: (value) => value.length > 0, message: '' }],
          required: "",
          label: _ctx.$t('views.monitoring.teamOverview.modals.editTeam.fields.name')
        }, null, 8, ["value", "rules", "label"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(DatePicker, {
            value: state.value.startDate,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((state.value.startDate) = $event)),
            required: "",
            label: _ctx.$t('views.monitoring.teamOverview.modals.editTeam.fields.startDate')
          }, null, 8, ["value", "label"]),
          _createVNode(DatePicker, {
            value: state.value.endDate,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((state.value.endDate) = $event)),
            required: "",
            label: _ctx.$t('views.monitoring.teamOverview.modals.editTeam.fields.endDate')
          }, null, 8, ["value", "label"])
        ]),
        _createVNode(CustomCheckbox, {
          checked: state.value.isFavorite,
          "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((state.value.isFavorite) = $event)),
          label: _ctx.$t('views.monitoring.teamOverview.modals.editTeam.fields.markAsFavorite'),
          class: "-ml-1"
        }, null, 8, ["checked", "label"])
      ], 32),
      _createVNode(CustomButton, {
        disabled: isLoading.value,
        "color-preset": "warning",
        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (emit('delete', props.team!.id)), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeam.buttons.deleteTeam')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_unref(DialogFooter), null, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogClose), { "as-child": "" }, {
            default: _withCtx(() => [
              _createVNode(CustomButton, {
                disabled: isLoading.value,
                "color-preset": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeam.buttons.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(CustomButton, {
            loading: isLoading.value,
            disabled: !isFormValid.value,
            onClick: handleCreate
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.modals.editTeam.buttons.save')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "disabled"]))
}
}

})