<template>
    <Dialog v-model="showModal" :disabled="isLoading" class="sm:max-w-[540px]">
        <DialogHeader>
            <DialogTitle>{{ $t('views.monitoring.teamOverview.modals.createTeam.title') }}</DialogTitle>
            <VisuallyHidden>
                <DialogDescription class="pt-4">{{ $t('views.monitoring.teamOverview.modals.createTeam.description') }}</DialogDescription>
            </VisuallyHidden>
        </DialogHeader>
        <form class="mt-4 space-y-2" @submit.prevent="handleCreate">
            <CustomSelect
                v-model:value="state.sectionId"
                :items="sectionOptions"
                :rules="[{ validate: (value: any) => value !== null, message: '' }]"
                item-text="name"
                item-value="id"
                :label="$t('views.monitoring.teamOverview.modals.createTeam.fields.section')"
                required
                :allow-empty="false"
            />
            <CustomInput
                v-model:value="(state.name as any)"
                :rules="[{ validate: (value: string) => value.length > 0, message: '' }]"
                required
                :label="$t('views.monitoring.teamOverview.modals.createTeam.fields.name')"
            />
            <div class="grid grid-cols-2 gap-x-4">
                <DatePicker v-model:value="state.startDate" required :label="$t('views.monitoring.teamOverview.modals.createTeam.fields.startDate')" />
                <DatePicker v-model:value="state.endDate" required :label="$t('views.monitoring.teamOverview.modals.createTeam.fields.endDate')" />
            </div>
            <CustomCheckbox v-model:checked="state.isFavorite" :label="$t('views.monitoring.teamOverview.modals.createTeam.fields.markAsFavorite')" class="-ml-1" />
        </form>
        <DialogFooter class="mt-2">
            <DialogClose as-child>
                <CustomButton :disabled="isLoading" color-preset="white">{{ $t('views.monitoring.teamOverview.modals.createTeam.buttons.cancel') }}</CustomButton>
            </DialogClose>
            <CustomButton :loading="isLoading" :disabled="!isFormValid" @click="handleCreate">{{ $t('views.monitoring.teamOverview.modals.createTeam.buttons.create') }}</CustomButton>
        </DialogFooter>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { VisuallyHidden } from 'radix-vue';
import { addWeeks, format, parseISO } from 'date-fns';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import { store } from '@/plugins/store';
import DatePicker from '@/components/ui/DatePicker.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { i18n } from '@/plugins/internationalization/i18n';

const props = defineProps<{
    barnId: number | null;
}>();

const queryClient = useQueryClient();
const toast = useToast();

const showModal = defineModel<boolean>('modelValue');

const isLoading = ref(false);

interface CreateTeamState {
    sectionId: number | null;
    name: string;
    startDate: string;
    endDate: string;
    isFavorite: boolean;
}

function getInitialState() {
    return {
        sectionId: null,
        name: '',
        startDate: new Date().toISOString(),
        endDate: addWeeks(new Date(), 1).toISOString(),
        isFavorite: false,
    };
}

const state = ref<CreateTeamState>(getInitialState());

const sectionOptions = computed(() => store.state.sections?.filter((s) => s.barn_id === props.barnId) ?? []);

async function handleCreate() {
    try {
        if (isLoading.value) {
            return;
        }

        isLoading.value = true;

        await ApiClient.send(
            'post',
            '/monitor/study-periods',
            {
                skipHandleError: true,
            },
            {
                location_id: state.value.sectionId,
                name: state.value.name,
                start: format(parseISO(state.value.startDate), 'yyyy-MM-dd'),
                end: format(parseISO(state.value.endDate), 'yyyy-MM-dd'),
                is_favorite: state.value.isFavorite,
            }
        );

        await queryClient.invalidateQueries({ queryKey: queryKeys.getBarnStudyPeriodsRoot });

        showModal.value = false;

        toast.success(i18n.global.t('views.monitoring.teamOverview.modals.createTeam.successMessages.teamWasCreated'));
    } catch (err) {
        const errorMessage = (err as any)?.data?.message || i18n.global.t('views.monitoring.teamOverview.modals.createTeam.errors.couldNotCreateTeam');
        toast.error(errorMessage);
    } finally {
        isLoading.value = false;
    }
}

function resetForm() {
    state.value = getInitialState();
}

const isFormValid = computed(() => state.value.sectionId !== null && state.value.name.length > 0);

watch(showModal, (value) => {
    if (!value) {
        setTimeout(() => {
            resetForm();
        }, 300);
    }
});
</script>
