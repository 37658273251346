import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "divide-y divide-gray-200 space-y-4 px-4 py-4" }
const _hoisted_2 = { class: "flex justify-between items-center space-x-3" }
const _hoisted_3 = { class: "flex items-center space-x-3" }
const _hoisted_4 = { class: "font-bold text-lg" }
const _hoisted_5 = { class: "text-sm text-gray-500" }
const _hoisted_6 = { class: "flex shrink-0 items-center gap-x-3" }
const _hoisted_7 = { class: "font-semibold mr-1.5" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "flex justify-end" }
const _hoisted_10 = { class: "flex justify-end" }
const _hoisted_11 = { class: "sr-only" }
const _hoisted_12 = { class: "text-red-500 font-medium" }

import { ref, computed, h, watch, inject, Ref } from 'vue';
import { ColumnDef, SortingState } from '@tanstack/vue-table';
import { EllipsisVertical, Plus, ChevronDown } from 'lucide-vue-next';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import Icon from '@/components/icons/Icon.vue';
import { IconSource, StudyPeriod, StudyPeriodBarnResponseEntry, StudyPeriodGrowthInterval } from '@/types';
import { Checkbox } from '@/components/ui/checkbox';
import { i18n } from '@/plugins/internationalization/i18n';
import DataTable from '@/components/ui/data-table/DataTable.vue';
import { store } from '@/plugins/store';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Select as UISelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';


export default /*@__PURE__*/_defineComponent({
  __name: 'BarnSection',
  props: {
    barn: {},
    growthIntervals: { default: () => [] }
  },
  emits: ["delete", "create", "edit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const columns = computed<ColumnDef<StudyPeriod & { _locationName: string }>[]>(() => [
    {
        id: '_select',
        header: ({ table }) =>
            h(Checkbox, {
                checked: table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
                'onUpdate:checked': (value) => table.toggleAllPageRowsSelected(!!value),
                ariaLabel: i18n.global.t('views.monitoring.teamOverview.fields.selectAll.label'),
                class: 'mt-1',
            }),
        cell: ({ row }) =>
            h(Checkbox, {
                checked: row.getIsSelected(),
                'onUpdate:checked': (value) => row.toggleSelected(!!value),
                ariaLabel: i18n.global.t('views.monitoring.teamOverview.fields.selectTeam.label'),
                class: 'mt-1',
            }),
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.name'),
        accessorKey: 'name',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.startDate'),
        accessorKey: 'start',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.endDate'),
        accessorKey: 'end',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.location'),
        accessorKey: '_locationName',
        enableSorting: true,
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.growthIndex'),
        accessorKey: '_growthIndex',
        enableSorting: true,
        meta: {
            align: 'center',
        },
    },
    {
        header: i18n.global.t('views.monitoring.teamOverview.tables.barnTable.headings.creationType'),
        accessorKey: 'is_auto_generated',
        enableSorting: true,
        meta: {
            align: 'center',
        },
    },
    {
        header: '',
        accessorKey: 'is_favorite',
    },
    {
        header: '',
        accessorKey: '_actions',
        meta: {
            cellClass: 'w-10',
        },
    },
]);
const selectedGrowthIndex = ref<string | undefined>(undefined);

const sorting = ref<SortingState>([
    {
        id: 'start',
        desc: true,
    },
]);

function getSection(locationId: number) {
    return store.state.sections?.find((s) => s.id === locationId);
}

const items = computed(() =>
    props.barn.study_periods.map((sp) => ({
        ...sp,
        _locationName: getSection(sp.location_id)?.name || '',
        _growthIndex: sp.growth_indices?.length ? sp.growth_indices.find((i) => i.growth_interval_id.toString() === selectedGrowthIndex.value)?.value : null,
    }))
);

const page = ref(1);
const pageSize = ref(9999);
const pageSizeOptions = ref([10, 25, 50, 100]);

const isExpanded = ref(false);
const computedExpanded = computed(() => Boolean(isExpanded.value && props.barn.study_periods?.length));

const { selections, updateSelections } = inject('barnSelections') as {
    selections: Ref<Record<number, Record<number, boolean>>>;
    // eslint-disable-next-line
    updateSelections: (barnId: number, selections: Record<number, boolean>) => void
};

const rowSelection = computed({
    get() {
        return selections.value[props.barn.barn_id] || {};
    },
    set(value) {
        updateSelections(props.barn.barn_id, value);
    },
});

function setInitialGrowthIndex() {
    if (!selectedGrowthIndex.value && props.growthIntervals.length) {
        selectedGrowthIndex.value = props.growthIntervals[0].id.toString();
    }
}

setInitialGrowthIndex();

const stopSelectedGrowthIndexWatcher = watch(
    () => props.growthIntervals,
    (newVal) => {
        if (newVal.length) {
            setInitialGrowthIndex();
            stopSelectedGrowthIndexWatcher();
        }
    }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainViewCard, { class: "max-w-full overflow-hidden" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(Icon, {
                class: "h-6",
                src: _unref(IconSource).Location
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.barn.barn_name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.barn.study_periods?.length || 0) + " " + _toDisplayString(_ctx.barn.study_periods?.length === 1 ? _ctx.$t('views.monitoring.teamOverview.texts.team') : _ctx.$t('views.monitoring.teamOverview.texts.teams')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["transition-opacity duration-200", { 'opacity-100 visible': isExpanded.value, 'opacity-0 invisible': !isExpanded.value }])
            }, [
              _createVNode(_unref(UISelect), {
                modelValue: selectedGrowthIndex.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedGrowthIndex).value = $event)),
                disabled: !_ctx.growthIntervals.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(SelectTrigger), { class: "rounded-lg font-medium bg-white border-gray-200 h-auto text-gray-700 hover:bg-gray-100 hover:text-gray-800 transition-colors pl-3 pr-2.5 py-2 text-normal" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.fields.growthIndexInterval')) + ":", 1),
                      _cache[6] || (_cache[6] = _createTextVNode()),
                      _createVNode(_unref(SelectValue))
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(SelectContent), {
                    align: "end",
                    "body-lock": false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(SelectGroup), null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.growthIntervals, (interval) => {
                            return (_openBlock(), _createBlock(_unref(SelectItem), {
                              key: interval.id,
                              value: interval.id.toString()
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(interval.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "disabled"])
            ], 2),
            _createElementVNode("button", {
              class: "flex items-center rounded-lg gap-x-1 text-sky-500 hover:bg-sky-400/10 transition-colors px-3.5 py-2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('create', _ctx.barn.barn_id)))
            }, [
              _createVNode(_unref(Plus), {
                class: "size-5",
                "aria-hidden": "true"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.createTeam')), 1)
            ]),
            (_ctx.barn.study_periods && _ctx.barn.study_periods.length)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "h-9 w-9 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (isExpanded.value = !isExpanded.value))
                }, [
                  _createVNode(_unref(ChevronDown), {
                    class: _normalizeClass(["size-6 transform transition-transform", { 'rotate-[180deg]': isExpanded.value }]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(ExpandCollapseTransition, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(DataTable, {
              sorting: sorting.value,
              "onUpdate:sorting": _cache[3] || (_cache[3] = ($event: any) => ((sorting).value = $event)),
              "row-selection": rowSelection.value,
              "onUpdate:rowSelection": [
                _cache[4] || (_cache[4] = ($event: any) => ((rowSelection).value = $event)),
                _cache[5] || (_cache[5] = ($event: any) => (_unref(updateSelections)(_ctx.barn.barn_id, $event)))
              ],
              data: items.value,
              columns: columns.value,
              page: page.value,
              "page-size": pageSize.value,
              "page-size-options": pageSizeOptions.value,
              "enable-pagination": false
            }, {
              "item[start]": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.$d(item.start as Date, 'short')), 1)
              ]),
              "item[end]": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.$d(item.end as Date, 'short')), 1)
              ]),
              "item[is_auto_generated]": _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(item.is_auto_generated ? _ctx.$t('views.monitoring.teamOverview.creationTypes.autoGenerated') : _ctx.$t('views.monitoring.teamOverview.creationTypes.manuallyGenerated')), 1)
              ]),
              "item[growth_indices]": _withCtx(({ item }) => [
                (item.growth_indices?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.growth_indices
                                    .sort((a, b) => a.value - b.value)
                                    .map((i) => i.value)
                                    .join(', ')), 1))
                  : _createCommentVNode("", true)
              ]),
              "item[is_favorite]": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(), _createElementBlock("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24",
                    fill: "currentColor",
                    class: _normalizeClass(["size-7", { 'text-yellow-400': item.is_favorite, 'text-gray-200': !item.is_favorite }])
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z",
                      "clip-rule": "evenodd"
                    }, null, -1)
                  ]), 2))
                ])
              ]),
              "item[_actions]": _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(DropdownMenu), { modal: false }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Button), {
                            variant: "ghost",
                            size: "icon",
                            class: "hover:gray-300 rounded-full -mr-1"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.actions')), 1),
                              _createVNode(_unref(EllipsisVertical), {
                                class: "size-5",
                                "aria-hidden": "true"
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(DropdownMenuContent), {
                        class: "min-w-[var(--radix-dropdown-menu-trigger-width)]",
                        align: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(DropdownMenuItem), {
                            class: "cursor-pointer font-medium",
                            onClick: ($event: any) => (emit('edit', item))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.edit')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_unref(DropdownMenuItem), {
                            class: "cursor-pointer",
                            onClick: ($event: any) => (emit('delete', item.id))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('views.monitoring.teamOverview.buttons.delete')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 1
            }, 8, ["sorting", "row-selection", "data", "columns", "page", "page-size", "page-size-options"])
          ], 512), [
            [_vShow, computedExpanded.value]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})