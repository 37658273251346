import { keepPreviousData, useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import ApiRequest from '@/plugins/store/actions/api/ApiRequest';
import { Dashboard } from '@/types';

const DEFAULT_OPTIONS: UseQueryOptions<any> = {
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
};

/* eslint-disable */
export enum StudyPeriodDashboardScope {
    Table = 0,
    Chart = 1,
}
/* eslint-enable */

export const queryKeys = {
    getDashboardRoot: ['dashboards', 'study-periods'],
    getDashboard: ({
        evaluationCategoryId,
        scope,
        studyPeriodIds,
        favoriteStudyPeriodIds,
        fetchIndex,
    }: {
        evaluationCategoryId: Ref<string>;
        scope: Ref<StudyPeriodDashboardScope>;
        studyPeriodIds: Ref<number[]>;
        favoriteStudyPeriodIds: Ref<number[]>;
        fetchIndex: Ref<number>;
    }) => [...queryKeys.getDashboardRoot, { evaluationCategoryId, scope, studyPeriodIds, favoriteStudyPeriodIds, fetchIndex }],
};

export function useStudyPeriodDashboard(
    {
        evaluationCategoryId,
        scope,
        studyPeriodIds,
        favoriteStudyPeriodIds,
        fetchIndex,
    }: {
        evaluationCategoryId: Ref<string>;
        scope: Ref<StudyPeriodDashboardScope>;
        studyPeriodIds: Ref<number[]>;
        favoriteStudyPeriodIds: Ref<number[]>;
        fetchIndex: Ref<number>;
    },
    options: UseQueryOptions<Dashboard> = {}
) {
    return useQuery<Dashboard>({
        queryKey: queryKeys.getDashboard({ evaluationCategoryId, scope, studyPeriodIds, favoriteStudyPeriodIds, fetchIndex }),
        queryFn: () =>
            new ApiRequest().send(
                'get',
                `dashboards/study-periods/evaluation-category?evaluation_category_id=${evaluationCategoryId.value}&scope=${
                    scope.value
                }&study_period_ids=${studyPeriodIds.value.toString()}&favorite_study_period_ids=${favoriteStudyPeriodIds.value.toString()}&initialrequest=true`
            ),
        placeholderData: keepPreviousData,
        ...DEFAULT_OPTIONS,
        ...options,
    });
}
