<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.feed.mixes.table.header') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.feed.mixes.table.searchFieldLabel')"></SearchInput>
            </span>
            <NewMixModal>
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.feed.mixes.table.addButtonLabel') }}
                    </AddButton>
                </template>
            </NewMixModal>
        </div>
        <CustomTable :items="filteredItems" :headers="headers">
            <template #header_actions>
                <th class="w-6"></th>
            </template>

            <template #prices="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <span>{{ getStyledPriceForMix(item) }}</span>
                </td>
            </template>

            <template #farms="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <LocationsTooltip
                        :locations="item.farms"
                        number-of-locations-replace-text="$NUMBER_OF_FARMS"
                        :number-of-locations-text="$t('views.feed.mixes.table.farmsTooltip.tableText')"
                        :tooltip-header="$t('views.feed.mixes.table.farmsTooltip.tooltipHeader')"
                    ></LocationsTooltip>
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-6 my-auto">
                    <EditMixModal :mix="item">
                        <template #default="{ open }">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="open">
                                    <Icon class="h-3 w-3 cursor-pointer" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </template>
                    </EditMixModal>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { FeedUnitType, Mix, TableHeader } from '@/types';
import SearchInput from '@/components/ui/SearchInput.vue';
import NewMixModal from '@/views/dashboard/feed/feedMixes/newMixModal/NewMixModal.vue';
import EditMixModal from '@/views/dashboard/feed/feedMixes/editMixModal/EditMixModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import useGetPrice from '@/components/common/composables/useGetPrice';
import LocationsTooltip from '@/components/common/LocationsTooltip.vue';
import useFilterableTable from '@/composables/useFilterableTable';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { MainViewCard, CustomTable, SearchInput, NewMixModal, AddButton, LocationsTooltip, EditMixModal },
    setup() {
        store.dispatch(ActionType.GetMixes);

        const energyUnitHeader = computed(() => {
            const energyDisplayText = store.getters.energyPerWeightUnitDisplayText;

            if (energyDisplayText) {
                return i18n.global.t('views.feed.mixes.table.headings.energyUnit').replace('%VALUE%', energyDisplayText);
            }

            return store.state.user?.energy_unit === FeedUnitType.Mj ? i18n.global.t('views.feed.mixes.table.headings.mj') : i18n.global.t('views.feed.mixes.table.headings.fu');
        });
        const priceUnitHeader = computed(() => {
            const priceCurrencyDisplayText = store.getters.priceCurrencyPerWeightUnitDisplayText;

            if (priceCurrencyDisplayText) {
                return i18n.global.t('views.feed.mixes.table.headings.priceUnit').replace('%VALUE%', priceCurrencyDisplayText);
            }

            return i18n.global.t('views.feed.mixes.table.headings.price');
        });

        const headers = computed<TableHeader[]>(() => [
            {
                text: i18n.global.t('views.feed.mixes.table.headings.name'),
                value: 'name',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: energyUnitHeader.value,
                value: 'wgt_avg_fu',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.feed.mixes.table.headings.crude_protein'),
                value: 'wgt_avg_crude_protein',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.feed.mixes.table.headings.phosphorus'),
                value: 'wgt_avg_phosphorus',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: priceUnitHeader.value,
                value: 'prices',
                align: 'left',
                sortable: true,
                searchable: true,
                sortComparison: (a: Mix, b: Mix, orderMultiplier: number) => {
                    const aPrice = useGetPrice(a);
                    const bPrice = useGetPrice(b);
                    if (!aPrice) {
                        return -1 * orderMultiplier;
                    }
                    if (!bPrice) {
                        return 1 * orderMultiplier;
                    }
                    return (aPrice.price + (a.surcharge || 0) - (bPrice.price + (b.surcharge || 0))) * orderMultiplier;
                },
                searchComparison: (item, search) => getStyledPriceForMix(item).includes(search.toLowerCase().trim()),
            },
            {
                text: i18n.global.t('views.feed.mixes.table.headings.location'),
                value: 'farms',
                align: 'left',
                sortable: true,
                searchable: true,
                searchComparison: (item, search) =>
                    item.farms &&
                    ((item.farms.length === 0 && search === '-') ||
                        item.farms
                            .map((farm: { name: string; id: number }) => farm.name)
                            .join(',')
                            .toLowerCase()
                            .trim()
                            .includes(search.toLowerCase().trim())),
            },
            {
                text: i18n.global.t('views.feed.mixes.table.headings.type'),
                value: 'type.name',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                searchable: false,
            },
        ]);
        const items = computed(() => store.state.mixes);
        const { search, filteredItems } = useFilterableTable(items, headers);

        function getStyledPriceForMix(mix: Mix) {
            const priceObject = useGetPrice(mix);
            return priceObject === null ? '-' : i18n.global.n(priceObject.price + (mix.surcharge || 0), 'price');
        }

        return {
            headers,
            search,
            filteredItems,
            getStyledPriceForMix,
        };
    },
});
</script>
