import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, Transition as _Transition, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex items-center gap-x-1 leading-normal" }
const _hoisted_3 = { class: "font-medium" }
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "mt-4 w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left" }
const _hoisted_6 = { class: "flex items-center gap-x-1 leading-normal" }
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = { class: "text-gray-500" }
const _hoisted_9 = {
  role: "tree",
  "aria-orientation": "horizontal",
  class: "flex flex-grow divide-x max-h-full overflow-hidden"
}
const _hoisted_10 = {
  class: "flex flex-col items-stretch w-[30%] flex-shrink-0",
  role: "group"
}
const _hoisted_11 = { class: "sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2" }
const _hoisted_12 = { class: "max-h-full overflow-y-auto" }
const _hoisted_13 = ["aria-label", "aria-expanded", "aria-setsize", "aria-posinset", "onKeydown", "onMouseover", "onClick"]
const _hoisted_14 = { class: "flex grow gap-x-3 items-center justify-between max-w-full min-w-0" }
const _hoisted_15 = { class: "grow select-none truncate" }
const _hoisted_16 = {
  class: "flex flex-col items-stretch w-[30%] flex-shrink-0",
  role: "group"
}
const _hoisted_17 = { class: "sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2" }
const _hoisted_18 = {
  key: 0,
  class: "max-h-full overflow-y-auto"
}
const _hoisted_19 = ["aria-label", "aria-expanded", "aria-setsize", "aria-posinset", "onKeydown", "onMouseover", "onClick"]
const _hoisted_20 = { class: "flex grow gap-x-3 items-center justify-between max-w-full min-w-0" }
const _hoisted_21 = { class: "grow select-none truncate" }
const _hoisted_22 = {
  class: "flex flex-col items-stretch w-[40%] flex-shrink-0",
  role: "group"
}
const _hoisted_23 = { class: "sticky z-10 top-0 bg-white font-medium text-base border-b px-3 py-2" }
const _hoisted_24 = {
  key: 0,
  class: "max-h-full overflow-y-auto"
}
const _hoisted_25 = ["aria-label"]
const _hoisted_26 = { class: "flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer hover:bg-gray-100/70 focus:bg-gray-100/70 transition-colors" }
const _hoisted_27 = { class: "grow flex items-center justify-between gap-x-2 select-none max-w-full min-w-0" }
const _hoisted_28 = { class: "flex items-center gap-x-1.5 min-w-0" }
const _hoisted_29 = { class: "whitespace-nowrap truncate min-w-0" }
const _hoisted_30 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "currentColor",
  class: "shrink-0 size-4 text-yellow-400"
}
const _hoisted_31 = {
  key: 0,
  class: "text-gray-500 text-sm whitespace-nowrap"
}
const _hoisted_32 = { class: "mb-5 w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left" }
const _hoisted_33 = { class: "flex items-center gap-x-1 leading-normal" }
const _hoisted_34 = { class: "font-medium" }
const _hoisted_35 = { class: "text-gray-500" }
const _hoisted_36 = { class: "flex grow justify-between max-w-full min-w-0 items-center gap-x-2 pr-5" }
const _hoisted_37 = { class: "whitespace-nowrap truncate min-w-0" }
const _hoisted_38 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_39 = {
  key: 0,
  class: "text-gray-500 text-sm whitespace-nowrap"
}
const _hoisted_40 = { class: "flex justify-end" }
const _hoisted_41 = { class: "default-tabs" }
const _hoisted_42 = { class: "space-x-8 flex border-b border-black border-opacity-10" }
const _hoisted_43 = { class: "flex pr-4" }
const _hoisted_44 = { class: "flex" }

import { computed, onMounted, ref, watch } from 'vue';
import { ChevronRight, ChevronDown } from 'lucide-vue-next';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { useIsFetching, useQueryClient } from '@tanstack/vue-query';
import { parseISO } from 'date-fns';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import { Farm, Module, StudyPeriod } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { queryKeys } from '@/plugins/store/actions/queries/study-periods/dashboards';
import { useBarnStudyPeriods } from '@/plugins/store/actions/queries/study-periods';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import TableTab from './TableTab.vue';
import ChartTab from './ChartTab.vue';

interface LocationStudyPeriod extends StudyPeriod {
    checked: boolean;
}

interface LocationOptionBarn {
    id: number;
    name: string;
    checked: boolean;
    indeterminate: boolean;
    studyPeriods: LocationStudyPeriod[];
}

interface LocationOption extends Farm {
    barns: LocationOptionBarn[];
    checked: boolean;
    indeterminate: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

store.dispatch(ActionType.GetStudyPeriodEvaluationCategories);

const hasSectionsModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.Sections));

const isFetching = useIsFetching({ queryKey: queryKeys.getDashboardRoot });
const isLoading = computed(() => isFetching.value > 0);

const studyPeriodEvaluationCategories = computed(() => store.state.studyPeriodEvaluationCategories);
const formattedStudyPeriodEvaluationCategories = computed(() => studyPeriodEvaluationCategories.value.map((t) => ({ value: t.id.toString(), label: t.name })));
const selectedStudyPeriodEvaluationCategoryId = ref<string | null>(null);
const fetchState = ref<{ selectedStudyPeriodEvaluationCategoryId: string | undefined; selectedLocationIds: number[]; selectedFavoriteStudyPeriodIds: number[]; fetchIndex: number }>({
    selectedStudyPeriodEvaluationCategoryId: undefined,
    selectedLocationIds: [],
    selectedFavoriteStudyPeriodIds: [],
    // Used to trigger a refetch of the dashboard when generate is clicked
    fetchIndex: 0,
});
const isLocationPickerOpen = ref(false);

const barnIds = computed(() => store.getters.getSelectedSiteIds);
const selectedPeriod = computed(() => store.getters.getSelectedPeriod);
const isQueryEnabled = computed(() => barnIds.value.length > 0);

const { data: studyPeriodBarns } = useBarnStudyPeriods({ barnIds, selectedPeriod }, { enabled: isQueryEnabled });

const locationOptions = computed<LocationOption[]>(() => {
    if (!store.state.farms?.length || !studyPeriodBarns.value?.length) {
        return [];
    }

    return store.state.farms
        .map((f) => {
            const barns = store.state.barns.filter((b) => b.farm_id === f.farm_id);
            const filteredBarns = studyPeriodBarns.value?.filter((b) => barns.some((b2) => b2.barn_id === b.barn_id)) || [];
            const populatedBarns = filteredBarns.length
                ? filteredBarns
                      .map((b) => {
                          if (!b.study_periods?.length) {
                              return null;
                          }

                          const studyPeriods = b.study_periods.map((sp) => ({
                              ...sp,
                              checked: selectedLocationIds.value.includes(sp.id),
                          }));
                          const checked = studyPeriods?.every((sp) => sp.checked);

                          return {
                              id: b.barn_id,
                              name: b.barn_name,
                              checked,
                              indeterminate: (!checked && studyPeriods?.some((sp) => sp.checked)) ?? false,
                              studyPeriods,
                          };
                      })
                      .filter((b) => b !== null)
                : [];

            if (!populatedBarns.length) {
                return null;
            }

            const checked = populatedBarns?.every((b) => b.checked) ?? false;

            return {
                ...f,
                barns: populatedBarns,
                checked,
                indeterminate: (!checked && populatedBarns?.some((b) => b.checked || b.indeterminate)) ?? false,
            };
        })
        .filter((f) => f !== null);
});
const studyPeriodFavoriteOptions = computed(() =>
    studyPeriodBarns.value
        ?.flatMap((b) => b.study_periods.filter((sp) => sp.is_favorite))
        .map((sp) => ({
            value: sp.id,
            label: sp.name,
            start: sp.start,
            end: sp.end,
            locationNameAugmented: sp.location_name_augmented,
        }))
);
const barnOptions = computed(() => {
    if (selectedActiveFarmIndex.value === null) {
        return [];
    }

    return locationOptions.value[selectedActiveFarmIndex.value]?.barns || [];
});
const selectedActiveFarmIndex = ref<number | null>(0);
const selectedActiveBarnIndex = ref<number | null>(0);
const selectedLocationIds = ref<number[]>([]);
const selectedStudyPeriodFavoriteIds = ref<number[]>([]);

const counts = computed(() => {
    const state = {
        farms: 0,
        barns: 0,
        studyPeriods: 0,
    };

    for (const farm of locationOptions.value) {
        if (farm.checked || farm.indeterminate) {
            state.farms++;
        }

        for (const barn of farm.barns) {
            if (barn.checked || barn.indeterminate) {
                state.barns++;
            }

            for (const studyPeriod of barn.studyPeriods) {
                if (studyPeriod.checked) {
                    state.studyPeriods++;
                }
            }
        }
    }

    return state;
});
const countText = computed(() => {
    const { farms, barns, studyPeriods } = counts.value;

    const texts = [];

    if (farms) {
        texts.push(`${farms} ${farms === 1 ? i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.farm') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.farms')}`);
    }

    if (barns) {
        texts.push(`${barns} ${barns === 1 ? i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.barn') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.barns')}`);
    }

    if (studyPeriods) {
        texts.push(
            `${studyPeriods} ${studyPeriods === 1 ? i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.team') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.teams')}`
        );
    }

    return texts.length > 0 ? texts.join(', ') : i18n.global.t('views.monitoring.teamComparison.fields.locations.texts.noSelections');
});

const lastSectionText = computed(() => {
    const batchText = {
        text: i18n.global.t('views.monitoring.teamComparison.fields.locations.groups.batches'),
        key: 'batches',
    };
    const teamText = {
        text: i18n.global.t('views.monitoring.teamComparison.fields.locations.groups.teams'),
        key: 'teams',
    };

    if (selectedActiveBarnIndex.value === null) {
        return hasSectionsModuleEnabled.value ? teamText : batchText;
    }

    if (hasSectionsModuleEnabled.value) {
        return teamText;
    }

    return batchText;
});

const hasMadeSelection = ref(false);

watch(
    () => studyPeriodBarns.value,
    async () => {
        selectedLocationIds.value = [];
        selectedStudyPeriodFavoriteIds.value = [];
    }
);

function selectActiveFarm(farmIndex: number) {
    selectedActiveFarmIndex.value = farmIndex;
    selectedActiveBarnIndex.value = null;
}

function selectActiveBarn(barnIndex: number) {
    selectedActiveBarnIndex.value = barnIndex;
}

function toggleFarm({ farm, checked }: { farm: LocationOption; checked?: boolean }) {
    const barns = farm.barns;

    for (const barn of barns) {
        toggleBarn({ barn, checked: checked ?? farm.checked });
    }
}

function toggleBarn({ barn, checked }: { barn: LocationOptionBarn; checked?: boolean }) {
    const isChecked = checked ?? barn.checked;
    const studyPeriodIds = barn.studyPeriods.map((sp) => sp.id);

    if (isChecked) {
        selectedLocationIds.value = selectedLocationIds.value.filter((id) => !studyPeriodIds.includes(id));
    } else {
        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, ...studyPeriodIds])];
    }
}

function toggleStudyPeriod(studyPeriod: LocationStudyPeriod) {
    if (selectedLocationIds.value.includes(studyPeriod.id)) {
        selectedLocationIds.value = selectedLocationIds.value.filter((id) => id !== studyPeriod.id);
    } else {
        selectedLocationIds.value = [...new Set([...selectedLocationIds.value, studyPeriod.id])];
    }
}

function handleStudyPeriodsFavoritesChange(value: number[]) {
    selectedStudyPeriodFavoriteIds.value = value;
}

function getSelectedStudyPeriodIds() {
    const selectedIds: number[] = [];

    for (const farm of locationOptions.value) {
        for (const barn of farm.barns) {
            const checkedStudyPeriods = barn.studyPeriods.filter((sp) => sp.checked);

            selectedIds.push(...checkedStudyPeriods.map((sp) => sp.id));
        }
    }

    return selectedIds;
}

function generateDashboard() {
    fetchState.value = {
        selectedStudyPeriodEvaluationCategoryId: selectedStudyPeriodEvaluationCategoryId.value!,
        selectedLocationIds: getSelectedStudyPeriodIds(),
        selectedFavoriteStudyPeriodIds: selectedStudyPeriodFavoriteIds.value,
        fetchIndex: fetchState.value.fetchIndex + 1,
    };
}

watch(
    studyPeriodEvaluationCategories,
    (newStudyPeriodEvaluationCategories) => {
        if (!newStudyPeriodEvaluationCategories?.length || selectedStudyPeriodEvaluationCategoryId.value) {
            return;
        }

        selectedStudyPeriodEvaluationCategoryId.value = newStudyPeriodEvaluationCategories[0].id.toString();
    },
    { immediate: true }
);

const queryClient = useQueryClient();

onMounted(() => {
    queryClient.invalidateQueries({ queryKey: queryKeys.getDashboardRoot });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.monitoring.teamComparison.headings.title'),
    "has-period-picker": "",
    "slim-period-picker": "",
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(Listbox), {
            modelValue: selectedStudyPeriodEvaluationCategoryId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedStudyPeriodEvaluationCategoryId).value = $event))
          }, {
            default: _withCtx(({ open }) => [
              _createVNode(_unref(ListboxButton), { class: "w-full min-h-[46px] flex items-center justify-between gap-x-3 bg-white border rounded-md py-2.5 px-3.5 text-left" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('views.monitoring.teamComparison.fields.category.label')) + ":", 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(formattedStudyPeriodEvaluationCategories.value.find((t) => t.value === selectedStudyPeriodEvaluationCategoryId.value)?.label ||
                                    _ctx.$t('views.monitoring.teamComparison.fields.category.texts.noSelection')), 1)
                  ]),
                  _createVNode(_unref(ChevronDown), {
                    class: _normalizeClass(["flex-shrink-0 h-5 w-5 transition-transform", { 'rotate-180': open }]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_Transition, {
                "enter-active-class": "transition duration-100 ease-out",
                "enter-from-class": "transform scale-95 opacity-0",
                "enter-to-class": "transform scale-100 opacity-100",
                "leave-active-class": "transition duration-100 ease-out",
                "leave-from-class": "transform scale-100 opacity-100",
                "leave-to-class": "transform scale-95 opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ListboxOptions), { class: "absolute z-20 mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedStudyPeriodEvaluationCategories.value, (category) => {
                        return (_openBlock(), _createBlock(_unref(ListboxOption), {
                          key: category.value,
                          value: category.value
                        }, {
                          default: _withCtx(({ active, selected }) => [
                            _createElementVNode("li", {
                              class: _normalizeClass(["py-2 px-3 h-full truncate block w-full text-left cursor-pointer", {
                                        'bg-primary-300 bg-opacity-40': selected,
                                        'hover:bg-gray-100': !selected,
                                        'bg-gray-100': active && !selected,
                                    }])
                            }, _toDisplayString(category.label), 3)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createVNode(_unref(Popover), {
          open: isLocationPickerOpen.value,
          "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((isLocationPickerOpen).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(PopoverTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('views.monitoring.teamComparison.fields.locations.label')) + ":", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(countText.value), 1)
                  ]),
                  _createVNode(_unref(ChevronDown), {
                    class: _normalizeClass(["flex-shrink-0 h-5 w-5 transition-transform", { 'rotate-180': isLocationPickerOpen.value }]),
                    "aria-hidden": "true"
                  }, null, 8, ["class"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(PopoverContent), {
              "trigger-width": "",
              align: "start",
              class: "flex flex-col p-0 h-[516px] overflow-hidden"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('views.monitoring.teamComparison.fields.locations.groups.farms')), 1),
                    _createElementVNode("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationOptions.value, (farm, farmIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: farm.farm_id,
                          class: "flex items-center cursor-pointer",
                          role: "treeitem",
                          "aria-label": farm.name,
                          "aria-level": "1",
                          "aria-expanded": selectedActiveFarmIndex.value === farmIndex,
                          "aria-setsize": locationOptions.value.length,
                          "aria-posinset": farmIndex + 1,
                          tabindex: "0",
                          onKeydown: _withKeys(($event: any) => (selectActiveFarm(farmIndex)), ["enter"]),
                          onMouseover: ($event: any) => (selectActiveFarm(farmIndex)),
                          onClick: ($event: any) => (selectActiveFarm(farmIndex))
                        }, [
                          _createElementVNode("label", {
                            class: _normalizeClass(["flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors", {
                                            'hover:bg-gray-100/70 focus:bg-gray-100/70': locationOptions.value[farmIndex]?.barns?.length && selectedActiveFarmIndex.value !== farmIndex,
                                            'bg-primary-300 bg-opacity-30': selectedActiveFarmIndex.value === farmIndex,
                                        }])
                          }, [
                            _createVNode(CustomCheckbox, {
                              checked: farm.checked,
                              indeterminate: farm.indeterminate,
                              "disable-transition": "",
                              class: "flex-shrink-0",
                              "onUpdate:checked": 
                                                () => {
                                                    hasMadeSelection.value = true;
                                                    toggleFarm({ farm });
                                                }
                                            
                            }, null, 8, ["checked", "indeterminate", "onUpdate:checked"]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("span", _hoisted_15, _toDisplayString(farm.name), 1),
                              (locationOptions.value[farmIndex]?.barns?.length)
                                ? (_openBlock(), _createBlock(_unref(ChevronRight), {
                                    key: 0,
                                    class: "flex-shrink-0 h-4 w-4 -mr-1"
                                  }))
                                : _createCommentVNode("", true)
                            ])
                          ], 2)
                        ], 40, _hoisted_13))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('views.monitoring.teamComparison.fields.locations.groups.barns')), 1),
                    _createVNode(_Transition, {
                      "enter-active-class": "transition duration-100 ease-out",
                      "enter-from-class": "opacity-0",
                      "enter-to-class": "opacity-100",
                      "leave-active-class": "transition duration-100 ease-in",
                      "leave-from-class": "opacity-0",
                      "leave-to-class": "opacity-0"
                    }, {
                      default: _withCtx(() => [
                        (selectedActiveFarmIndex.value !== null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(barnOptions.value, (barn, barnIndex) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: barn.id,
                                  role: "treeitem",
                                  "aria-label": barn.name,
                                  "aria-level": "2",
                                  "aria-expanded": selectedActiveBarnIndex.value === barnIndex,
                                  "aria-setsize": locationOptions.value[selectedActiveFarmIndex.value]?.barns.length,
                                  "aria-posinset": barnIndex + 1,
                                  class: "flex items-center cursor-pointer",
                                  tabindex: "0",
                                  onKeydown: _withKeys(($event: any) => (selectActiveBarn(barnIndex)), ["enter"]),
                                  onMouseover: ($event: any) => (selectActiveBarn(barnIndex)),
                                  onClick: ($event: any) => (selectActiveBarn(barnIndex))
                                }, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["flex gap-x-3 items-center px-3.5 py-3.5 w-full cursor-pointer transition-colors", {
                                                'hover:bg-gray-100/70 focus:bg-gray-100/70': barnOptions.value[barnIndex]?.studyPeriods?.length && selectedActiveBarnIndex.value !== barnIndex,
                                                'bg-primary-300 bg-opacity-30': selectedActiveBarnIndex.value === barnIndex,
                                            }])
                                  }, [
                                    _createVNode(CustomCheckbox, {
                                      checked: barn.checked,
                                      indeterminate: barn.indeterminate,
                                      "disable-transition": "",
                                      class: "flex-shrink-0",
                                      "onUpdate:checked": 
                                                    () => {
                                                        hasMadeSelection.value = true;
                                                        toggleBarn({ barn });
                                                    }
                                                
                                    }, null, 8, ["checked", "indeterminate", "onUpdate:checked"]),
                                    _createElementVNode("div", _hoisted_20, [
                                      _createElementVNode("span", _hoisted_21, _toDisplayString(barn.name), 1),
                                      (barnOptions.value[barnIndex]?.studyPeriods?.length)
                                        ? (_openBlock(), _createBlock(_unref(ChevronRight), {
                                            key: 0,
                                            class: "flex-shrink-0 h-4 w-4 -mr-1"
                                          }))
                                        : _createCommentVNode("", true)
                                    ])
                                  ], 2)
                                ], 40, _hoisted_19))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_Transition, {
                        "enter-active-class": "transition duration-100 ease-out",
                        "enter-from-class": "opacity-0",
                        "enter-to-class": "opacity-100",
                        "leave-active-class": "transition duration-100 ease-in",
                        "leave-from-class": "opacity-0",
                        "leave-to-class": "opacity-0",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock("span", {
                            key: lastSectionText.value.key
                          }, _toDisplayString(lastSectionText.value.text), 1))
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_Transition, {
                      "enter-active-class": "transition duration-100 ease-out",
                      "enter-from-class": "opacity-0",
                      "enter-to-class": "opacity-100",
                      "leave-active-class": "transition duration-100 ease-in",
                      "leave-from-class": "opacity-0",
                      "leave-to-class": "opacity-0",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        (selectedActiveFarmIndex.value !== null && selectedActiveBarnIndex.value !== null && barnOptions.value[selectedActiveBarnIndex.value]?.studyPeriods?.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationOptions.value[selectedActiveFarmIndex.value]?.barns[selectedActiveBarnIndex.value]?.studyPeriods, (studyPeriod) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: studyPeriod.id,
                                  role: "treeitem",
                                  "aria-label": studyPeriod.name,
                                  "aria-level": "3",
                                  class: "flex items-center cursor-pointer",
                                  tabindex: "0"
                                }, [
                                  _createElementVNode("label", _hoisted_26, [
                                    _createVNode(CustomCheckbox, {
                                      checked: studyPeriod.checked,
                                      "disable-transition": "",
                                      class: "flex-shrink-0",
                                      "onUpdate:checked": 
                                                    () => {
                                                        hasMadeSelection.value = true;
                                                        toggleStudyPeriod(studyPeriod);
                                                    }
                                                
                                    }, null, 8, ["checked", "onUpdate:checked"]),
                                    _createElementVNode("div", _hoisted_27, [
                                      _createElementVNode("div", _hoisted_28, [
                                        _createElementVNode("div", _hoisted_29, _toDisplayString(studyPeriod.name), 1),
                                        (studyPeriod.is_favorite)
                                          ? (_openBlock(), _createElementBlock("svg", _hoisted_30, _cache[2] || (_cache[2] = [
                                              _createElementVNode("path", {
                                                "fill-rule": "evenodd",
                                                d: "M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z",
                                                "clip-rule": "evenodd"
                                              }, null, -1)
                                            ])))
                                          : _createCommentVNode("", true)
                                      ]),
                                      (studyPeriod.start && studyPeriod.end)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.$d(_unref(parseISO)(studyPeriod.start as string), 'short')) + _toDisplayString(studyPeriod.end ? ` - ${_ctx.$d(_unref(parseISO)(studyPeriod.end as string), 'short')}` : ''), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ], 8, _hoisted_25))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]),
        _createVNode(CustomMultiSelect, {
          value: (selectedStudyPeriodFavoriteIds.value as any),
          items: studyPeriodFavoriteOptions.value,
          "item-value": "value",
          "item-text": "label",
          class: "mt-4",
          "flat-values": "",
          "onUpdate:value": handleStudyPeriodsFavoritesChange
        }, {
          trigger: _withCtx(({ displayValue, expanded }) => [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('views.monitoring.teamComparison.fields.favoriteTeams.label')) + ": ", 1),
                _createElementVNode("span", _hoisted_35, _toDisplayString(displayValue), 1)
              ]),
              _createVNode(_unref(ChevronDown), {
                class: _normalizeClass(["flex-shrink-0 h-5 w-5 transition-transform", { 'rotate-180': expanded }]),
                "aria-hidden": "true"
              }, null, 8, ["class"])
            ])
          ]),
          item: _withCtx(({ item, text }) => [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createTextVNode(_toDisplayString(text) + " ", 1),
                (item.locationNameAugmented)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_38, "(" + _toDisplayString(item.locationNameAugmented) + ")", 1))
                  : _createCommentVNode("", true)
              ]),
              (item.start && item.end)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.$d(_unref(parseISO)(item.start as string), 'short')) + _toDisplayString(item.end ? ` - ${_ctx.$d(_unref(parseISO)(item.end as string), 'short')}` : ''), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["value", "items"]),
        _createElementVNode("div", _hoisted_40, [
          _createVNode(CustomButton, {
            class: "w-auto min-w-40 px-5",
            disabled: !selectedLocationIds.value.length || isLoading.value,
            onClick: generateDashboard
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.monitoring.teamComparison.buttons.generate')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_41, [
        _createVNode(_unref(Tabs), {
          teleport: "#team-comparison-tabs-target",
          "active-tab-class": "--active"
        }, {
          default: _withCtx(() => [
            _createElementVNode("nav", _hoisted_42, [
              _createElementVNode("div", _hoisted_43, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.monitoring.teamComparison.tabs.table'),
                  default: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(TableTab, {
                      "selected-study-period-evaluation-category-id": fetchState.value.selectedStudyPeriodEvaluationCategoryId,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "selected-favorite-study-period-ids": fetchState.value.selectedFavoriteStudyPeriodIds,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-study-period-evaluation-category-id", "selected-location-ids", "selected-favorite-study-period-ids", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_unref(Tab), {
                  name: _ctx.$t('views.monitoring.teamComparison.tabs.chart')
                }, {
                  default: _withCtx(() => [
                    _createVNode(ChartTab, {
                      "selected-study-period-evaluation-category-id": fetchState.value.selectedStudyPeriodEvaluationCategoryId,
                      "selected-location-ids": fetchState.value.selectedLocationIds,
                      "selected-favorite-study-period-ids": fetchState.value.selectedFavoriteStudyPeriodIds,
                      "fetch-index": fetchState.value.fetchIndex
                    }, null, 8, ["selected-study-period-evaluation-category-id", "selected-location-ids", "selected-favorite-study-period-ids", "fetch-index"])
                  ]),
                  _: 1
                }, 8, ["name"])
              ])
            ])
          ]),
          _: 1
        }),
        _cache[3] || (_cache[3] = _createElementVNode("div", {
          id: "team-comparison-tabs-target",
          class: "mt-6"
        }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}
}

})